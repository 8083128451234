import React, { useEffect, useState } from "react";
import { BASE_IMAGE_URL } from "../Utils/ApiServices";
import Footer from '../Components/Footer';
import { loginAPi } from "../Action/PostAction";
import { setAuthorizationToken } from "../Utils/ApiServices";
import { getCookies, setCookies } from "../Utils/Cookies";
import { useToasts } from 'react-toast-notifications';
import { toastComp } from '../Sections/Toast';
import { Link, useHistory } from "react-router-dom";
const AdminLogin = (props) => {
    const history = useHistory();
    const { addToast } = useToasts();
    const [token, setToken] = useState(false);
    const handleAdminLogin = async (event) => {
        event.preventDefault();
        const { email, password } = await event.target.elements;
        const data = { email: email.value, password: password.value };
        await loginAPi(data).then(response => {
            if (response.success) {
                setCookies('_gp_admin_token', response.data.token);
                setAuthorizationToken(response.data.token);
                window.open('/dashboard','_self')
            } else {
                toastComp(addToast, 'error', response.message);
            }
        })
    }
    useEffect(async () => {
        setToken(getCookies('_gp_admin_token'))
    }, []);
    return (
        <>
            {
                token ?
                    history.push('/dashboard')
                    :
                    <div className="nk-body bg-white npc-general pg-auth">
                        <div className="nk-app-root">
                            <div className="nk-main ">
                                <div className="nk-wrap nk-wrap-nosidebar">
                                    <div className="nk-content ">
                                        <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                                            <div className="brand-logo pb-4 text-center">
                                                <Link to={'/'} className="logo-link">
                                                    <img className="logo-light logo-img logo-img-lg" src={BASE_IMAGE_URL + 'webImages/Group+747.png'} alt="logo" />
                                                    <img className="logo-dark logo-img logo-img-lg" src={BASE_IMAGE_URL + 'webImages/Group+747.png'} alt="logo-dark" />
                                                </Link>
                                            </div>
                                            <div className="card card-bordered">
                                                <div className="card-inner card-inner-lg">
                                                    <div className="nk-block-head">
                                                        <div className="nk-block-head-content">
                                                            <h4 className="nk-block-title">Sign-In</h4>
                                                            <div className="nk-block-des">
                                                                <p>Access the Greetpool panel using your email and password.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <form onSubmit={handleAdminLogin}>
                                                        <div className="form-group">
                                                            <div className="form-label-group">
                                                                <label className="form-label" htmlFor="default-01">Email or Username</label>
                                                            </div>
                                                            <input type="email" className="form-control form-control-lg" id="email" name='email' placeholder="User Id/Email Id" required />
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="form-label-group">
                                                                <label className="form-label" htmlFor="password">Password</label>
                                                                {/* <a className="link link-primary link-sm" href="/admin-forgot-password">Forgot Password?</a> */}
                                                            </div>
                                                            <div className="form-control-wrap">
                                                                <a href="#" className="form-icon form-icon-right passcode-switch" data-target="password">
                                                                    <em className="passcode-icon icon-show icon ni ni-eye"></em>
                                                                    <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                                                                </a>
                                                                <input type="password" autoComplete='off' className="form-control form-control-lg" id="password" name='password' placeholder="Password" required />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <button className="btn btn-lg btn-primary btn-block">Sign in</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <Footer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}
export default AdminLogin;