import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";

import 'react-overlay-loader/styles.css';
import AdminEditCard from './AdminEditCard';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const AdminEditCard2 = () => {
    let query = useQuery();
    return (
        <>
            {
                query.get('card') ?
                    <AdminEditCard cardIdQuery={query.get('card') ? query.get('card') : false} isBack={true} />
                    : null
            }
        </>
    )
}
export default AdminEditCard2;