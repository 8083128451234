import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import { toastComp } from "../Sections/Toast";
import { useToasts } from 'react-toast-notifications';
import { addPlan } from '../Action/PostAction';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import ReactPaginate from 'react-paginate';
import { getPricingPlanDetails } from "../Action/GetAction";
import { dateFormatConverter, matrixify1 } from "../Logic/Logic";
import { format } from 'fecha';
import { editPlans } from '../Action/PutAction';
import { confirmAlert } from 'react-confirm-alert';
import { deletePlans } from "../Action/DeleteAction";

const PlanList = (props) => {
    const { addToast } = useToasts();
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(true);
    const [editData, setEditData] = useState(false);
    const [countProduct, setCountProduct] = useState(0);
    const [productList, setProductList] = useState([]);
    const [paginationIndex, setPageIndex] = useState(0);
    const [editId, setEditId] = useState(false);

    const [name, setName] = useState('');
    const [price, setPrice] = useState("");
    const [plan_type, setplan_type] = useState('');
    const [users, setusers] = useState('');
    const [cards, setcards] = useState('');
    const [Unlimitedcards, setUnlimitedcards] = useState(true);
    const [finalPrice, setFinalPrice] = useState('');
    const [cardprice, setcardprice] = useState('');
    const [userprice, setuserprice] = useState('');
    const [description, setDescription] = useState('');
    const [activeStatus, setActiveStatus] = useState('');
    const [multi_res, setmulti_res] = useState('');
    const [bulk_card, setbulk_card] = useState('');
    const [recommend, setRecommend] = useState('');
    const [showOnBanner, setshowOnBanner] = useState('')
    const [featurecount, setfeaturecount] = useState(1)
    const [features, setfeatures] = useState({})


    const removeFeature = (indexToRemove) => {
       
        const updatedFeatures = { ...features };
    
     
        delete updatedFeatures["features" + indexToRemove];
    
        
        setfeatures(updatedFeatures);
    
        setfeaturecount(featurecount - 1);
    };
    
    const removeKey = (keyToRemove) => {
        setfeatures(prevObject => {
            const updatedKeys = Object.keys(prevObject).filter(key => key !== keyToRemove);
            const updatedObject = updatedKeys.reduce((acc, key) => {
                acc[key] = prevObject[key];
                return acc;
            }, {});

            return updatedObject;
        });
    };

    const countTotalProduct = async (array) => {
        var count = 0;
        for (let li = 0; li < array.length; li++) {
            count = count + array[li].length
        }
        await setCountProduct(count);
    }
    const getListData = async (isNote, message) => {
        await getPricingPlanDetails('plans').then(async response => {
            if (response.success) {
                setLoader(false);
                if (response?.data?.length) {
                    onCloseModal();
                    const arrayList = await matrixify1(response.data, 10);
                    await setProductList([...productList, arrayList]);
                    await countTotalProduct(arrayList);
                    // await setParentCategory(response.data.categories);
                    if (isNote) {
                        toastComp(addToast, 'success', message);
                    }
                }
            }
        })
    }
    const handlePageClick = async (e) => {
        const selectedPage = e.selected;
        setPageIndex(selectedPage);
    };
    const onCloseModal = (props) => {
        setOpen(false);
        setEditData(false);
        setName('');
        setplan_type("");
        setmulti_res("");
        setbulk_card("");
        setPrice('');
        setusers('');
        setcards("");
        setFinalPrice('');
        setDescription('');
        setActiveStatus('');
        setRecommend('');
        setshowOnBanner('');
    }
    const editCategory = (data) => {
        let featuresdata = {}
        setOpen(true);
        setEditData(true);
        setEditId(data?._id);

        setplan_type(data?.planType);
        setName(data?.planName ? data?.planName : '');
        setDescription(data?.description ? data?.description : '');
        setPrice(data?.monthlyPrice ? data?.monthlyPrice : 0);
        setFinalPrice(data?.annualPrice ? data?.annualPrice : 0);
        setshowOnBanner(data?.showOnWebsite ? data?.showOnWebsite : false)
        setActiveStatus(data?.active ? data?.active : false);
        setRecommend(data?.recommendPlan ? data?.recommendPlan : false);
        setmulti_res(data?.multiRecipients ? data?.multiRecipients : false);
        setbulk_card(data?.bulkCards ? data?.bulkCards : false);
        setcards(data?.cards?.count ? data?.cards?.count : 0);
        setUnlimitedcards(data?.cards?.unlimited ? data?.cards?.unlimited : parseInt(data?.cards?.count) > 0 ? false : true);
        setcardprice(data?.cards?.price ? data?.cards?.price : 0)
        setusers(data?.users?.count ? data?.users?.count : 0);
        setuserprice(data?.users?.price ? data?.users?.price : 0)
        setfeaturecount(data?.featuresList.length > 0 ? data?.featuresList.length : 0)
        data?.featuresList.length > 0 ?
            data?.featuresList.map((e, index) => {
                featuresdata = ({ ...featuresdata, ["features" + index]: e?.featureText })
            })
            : setfeaturecount(data?.featuresList.length > 0 ? data?.featuresList.length : 0)
        setfeatures(featuresdata);
    }

    const handleAddProdcut = async () => {
        setLoader(true);
        if (name != '' && description != '' && price != "" && finalPrice != "" && (plan_type != "Solo" ? users != "" && userprice != "" : price != "")) {
            let data = {
                "planType": plan_type,
                "planName": name,
                "description": description,
                "monthlyPrice": price,
                "annualPrice": finalPrice,
                "showOnWebsite": showOnBanner != "" ? JSON.parse(showOnBanner) : showOnBanner,
                "active": activeStatus != "" ? JSON.parse(activeStatus) : activeStatus,
                "recommendPlan": recommend != "" ? JSON.parse(recommend) : recommend,
                "multiRecipients": multi_res != "" ? JSON.parse(multi_res) : multi_res,
                "bulkCards": bulk_card != "" ? JSON.parse(bulk_card) : false,
                "cards": {
                    "count": cards,
                    "price": cardprice,
                    "isVariable": true,
                    "unlimited": Unlimitedcards
                },

                "featuresList":
                    Object.values(features).map(e => {
                        return {
                            "featureText": e,
                            "accessType": true
                        }
                    })
            };
            if (plan_type != "Solo") {
                data = {
                    ...data, "users": {
                        "count": users,
                        "isVariable": true,
                        "price": userprice
                    },
                }
            }
            await addPlan(data).then(async response => {
                if (response.success) {
                    setOpen(false);
                    setTimeout(() => {
                        getListData(true, response.message);
                    }, 1000);
                } else {
                    setLoader(false);
                    toastComp(addToast, 'error', "Please fill the required field!");
                }
            })

        } else {
            setLoader(false);
            toastComp(addToast, 'error', 'Please fill the required field!');
        }
    }
    const handleEditProduct = async (id) => {
        setLoader(true);
        if (name != '' && id != '') {
            let data = {
                "planType": plan_type,
                "planName": name,
                "description": description,
                "monthlyPrice": price,
                "annualPrice": finalPrice,
                "showOnWebsite": showOnBanner != "" ? JSON.parse(showOnBanner) : showOnBanner,
                "active": activeStatus != "" ? JSON.parse(activeStatus) : activeStatus,
                "recommendPlan": recommend != "" ? JSON.parse(recommend) : recommend,
                "multiRecipients": multi_res != "" ? JSON.parse(multi_res) : multi_res,
                "bulkCards": bulk_card != "" ? JSON.parse(bulk_card) : bulk_card,
                "cards": {
                    "count": Unlimitedcards ? 0 : cards,
                    "isVariable": true,
                    "price": Unlimitedcards ? 0 : cardprice,
                    "unlimited": Unlimitedcards
                },

                "featuresList":
                    Object.values(features).map(e => {
                        return {
                            "featureText": e,
                            "accessType": true
                        }
                    })


            };
            if (plan_type != "Solo") {
                data = {
                    ...data, "users": {
                        "count": users,
                        "isVariable": true,
                        "price": userprice
                    }
                }
            }

            await editPlans(data, id).then(async response => {
                if (response.success) {
                    setOpen(false);
                    getListData(true, 'Successfully edited Product!');
                    setTimeout(() => {
                        // getListData(true, 'Successfully edited Product!');
                        window.location.reload()
                    }, 2000);
                } else {
                    setLoader(false);
                    toastComp(addToast, 'error', "Please fill the required field!");
                }
            })
        } else {
            setLoader(false);
            toastComp(addToast, 'error', 'Please fill the required field!');
        }
    }
    const popup = (id, index) => {
        confirmAlert({
            title: 'Confirm Action',
            message: 'Are you sure you want to delete this card?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteCategory(id, index)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const deleteCategory = async (id, index) => {
        setLoader(true);
        await deletePlans(id).then(response => {
            if (response.success || response.status) {
                setLoader(false);
                productList[0]?.[paginationIndex].splice(index, 1);
                toastComp(addToast, 'success', 'Plan deleted Successfully!');
                setTimeout(() => {
                    getListData(false, '');
                }, 1000);
            } else {
                setLoader(false);
                toastComp(addToast, 'error', 'Plan id incorrect!');
            }
        })
    };
    var divisor = (countProduct / 10) >> 0;
    var remainder = countProduct % 10;
    useEffect(async () => {
        await getListData(false, '');
    }, [])
    return (
        <>
            <div className="nk-content ">
                <div className="container-fluid">
                    <div className="nk-content-inner">
                        <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-between">
                                    <div className="nk-block-head-content">
                                        <h3 className="nk-block-title page-title">Plans</h3>
                                        <div className="nk-block-des text-soft">
                                            <p>You have total {countProduct === 1 ? countProduct + ' plan' : countProduct + ' plans'}.</p>
                                        </div>
                                    </div>
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                        <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                        <div className="toggle-expand-content" data-content="pageMenu">
                                            <ul className="nk-block-tools g-3">
                                                <li><a className="btn btn-white btn-outline-light" onClick={(e) => { setOpen(true) }}><em className="icon ni ni-plus"></em><span>Add Plan</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="nk-block">
                                <div className="card card-bordered card-stretch">
                                    <div className="card-inner-group">

                                        <div className="card-inner p-0" style={{ overflowX: "scroll" }}>
                                            <div className="nk-tb-list nk-tb-ulist" style={{ minWidth: "1735px" }}>
                                                <div className="nk-tb-item nk-tb-head">
                                                    <div className="nk-tb-col nk-tb-col-check" style={{ width: '0px', padding: '10px' }}>
                                                    </div>
                                                    <div className="nk-tb-col"><span className="sub-text">Plan Name</span></div>
                                                    <div className="nk-tb-col"><span className="sub-text">Plan Type</span></div>
                                                    <div className="nk-tb-col tb-col-mb"><span className="sub-text">Monthly Price</span></div>
                                                    <div className="nk-tb-col tb-col-mb"><span className="sub-text">Annual Price</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Plan Recommend</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Active</span></div>

                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Multi Recepients</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Bulk Card</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Cards</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Cards Price</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">User</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">User Price</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Description</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Created At</span></div>
                                                    <div className="nk-tb-col nk-tb-col-tools text-right"><span className="sub-text">More</span></div>
                                                </div>
                                                {
                                                    loader ?
                                                        <LoadingOverlay className='loaderDiv'>
                                                            <Loader loading={loader} />
                                                        </LoadingOverlay>
                                                        : null
                                                }

                                                {
                                                    productList[0]?.[paginationIndex].length > 0 ?
                                                        productList[0]?.[paginationIndex].map((data, index) =>
                                                            <div className="nk-tb-item" id={data._id} key={index}>
                                                                <div className="nk-tb-col nk-tb-col-check" style={{ width: '0px', padding: '10px' }}>
                                                                </div>
                                                                <div className="nk-tb-col">
                                                                    <div className="user-info">
                                                                        <span className="tb-lead">{data.planName}<span className="dot dot-success d-md-none ml-1"></span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="nk-tb-col">
                                                                    <div className="user-info">
                                                                        <span className="tb-lead">{data.planType}<span className="dot dot-success d-md-none ml-1"></span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-mb">
                                                                    <span className="tb-lead">{'$' + data?.monthlyPrice}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-mb">
                                                                    <span className="tb-lead">{'$' + data?.annualPrice}</span>
                                                                </div>

                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data.recommendPlan ? "Yes" : "No"}</span>
                                                                </div>

                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data?.active ? "Yes" : "No"}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data?.multiRecipients ? "Yes" : "No"}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data?.bulkCards ? "Yes" : "No"}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data?.cards?.count >= 0 ? data?.cards?.count : '--'}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data?.cards?.price >= 0 ? '$' + data?.cards?.price : '--'}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data?.users?.count >= 0 ? data?.users?.count : '--'}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data?.users?.price >= 0 ? '$' + data?.users?.price : '--'}</span>
                                                                </div>


                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead" style={{ maxWidth: "200px" }}>{data?.description ? data?.description : '--'}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{format(new Date((data?.createdAt)), 'Do MMM YYYY')}</span>
                                                                </div>
                                                                <div className="nk-tb-col nk-tb-col-tools">
                                                                    <ul className="nk-tb-actions gx-1">
                                                                        <li>
                                                                            <div className="drodown">
                                                                                <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                                    <ul className="link-list-opt no-bdr">
                                                                                        <li><a onClick={() => editCategory(data)}><em className="icon ni ni-edit"></em><span>Edit</span></a></li>
                                                                                        <li><a onClick={() => popup(data._id, index)}><em className="icon ni ni-trash"></em><span>Delete</span></a></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        )
                                                        :
                                                        <div style={{ width: '100%', position: 'absolute', left: '0px', backgroundColor: '#fff' }}>
                                                            <h1 style={{ width: '100%', textAlign: 'center', fontSize: '16px', lineHeight: '61px', color: '#eb5757', fontWeight: '600' }}>*Not Found</h1>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="card-inner">
                                            <ReactPaginate
                                                previousLabel={"Prev"}
                                                nextLabel={"Next"}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={remainder >= 1 ? divisor + 1 : divisor}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={(e) => handlePageClick(e)}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                activeClassName={"active"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} closeOnOverlayClick={false}>
                <div className='container-fluid' style={{ width: '100%' }}>
                    <div className='row justify-content-center'>
                        <div className='col-md-12 col-12'>
                            <h3 style={{ textAlign: 'left' }}>{editData ? 'Edit Pricing Plan' : 'Add Pricing Plan'}</h3>
                        </div>
                        <div className='col-md-12 col-12' style={{ marginTop: "15px" }}>
                            <div className='row justify-content-center'>
                                <div className="col-lg-12 col-12">
                                    <ul className="data-list is-compact">
                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Plan Name</div>
                                                <input
                                                    type="text"
                                                    className="data-value ip-field"
                                                    value={name}
                                                    placeholder="Plan name"
                                                    onChange={(e) => { setName(e.target.value) }}
                                                    required />
                                            </div>
                                        </li>


                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Plan Type</div>
                                                <select className='custom-select' value={plan_type} onChange={(e) => setplan_type(e.target.value)} required>
                                                    <option value=''>Select</option>
                                                    <option value='Solo'>Solo</option>
                                                    <option value='Team'>Team</option>
                                                    <option value='Enterprise'>Enterprise</option>
                                                </select>
                                            </div>
                                        </li>

                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Plan Recommend</div>
                                                <select className='custom-select' value={recommend} onChange={(e) => setRecommend(e.target.value)} required>
                                                    <option value=''>Select</option>
                                                    <option value='true'>Yes</option>
                                                    <option value='false'>No</option>
                                                </select>
                                            </div>
                                        </li>
                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Active</div>
                                                <select className='custom-select' value={activeStatus} onChange={(e) => setActiveStatus(e.target.value)} required>
                                                    <option value=''>Select</option>
                                                    <option value='true'>Yes</option>
                                                    <option value='false'>No</option>
                                                </select>
                                            </div>
                                        </li>
                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Monthly Price</div>
                                                <input
                                                    type="number"
                                                    className="data-value ip-field"
                                                    value={price}
                                                    placeholder="Price in CAD"
                                                    onChange={(e) => { setPrice(e.target.value >= 0 ? e.target.value : 0) }}
                                                    required />
                                            </div>
                                        </li>
                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Annual Price</div>
                                                <input
                                                    type="number"
                                                    className="data-value ip-field"
                                                    value={finalPrice}
                                                    placeholder="Annual Price"
                                                    onChange={(e) => { setFinalPrice(e.target.value >= 0 ? e.target.value : 0) }}
                                                />
                                            </div>
                                        </li>
                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Unlimited Cards</div>
                                                <input
                                                    style={{ width: "auto" }}
                                                    type="checkbox"
                                                    className="data-value ip-field"
                                                    checked={Unlimitedcards}
                                                    placeholder="Cards"
                                                    onChange={(e) => { setUnlimitedcards(e.target.checked) }}
                                                    required />
                                            </div>
                                        </li>
                                        {Unlimitedcards == true
                                            ? "" :
                                            <>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Cards</div>
                                                        <input
                                                            type="number"
                                                            className="data-value ip-field"
                                                            value={cards}
                                                            placeholder="Cards"
                                                            onChange={(e) => { setcards(e.target.value >= 0 ? e.target.value : 0); }}
                                                            required />
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Cards Price</div>
                                                        <input
                                                            type="number"
                                                            className="data-value ip-field"
                                                            value={cardprice}
                                                            placeholder="Cards Price"
                                                            onChange={(e) => { setcardprice(e.target.value >= 0 ? e.target.value : 0) }}
                                                        />
                                                    </div>
                                                </li>
                                            </>
                                        }
                                        {plan_type != "Solo" ?
                                            <>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Users</div>
                                                        <input
                                                            type="number"
                                                            className="data-value ip-field"
                                                            value={users}
                                                            placeholder="Users"
                                                            onChange={(e) => { setusers(e.target.value >= 0 ? e.target.value : 0); }}
                                                            required />
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Users Price</div>
                                                        <input
                                                            type="number"
                                                            className="data-value ip-field"
                                                            value={userprice}
                                                            placeholder="Users Price"
                                                            onChange={(e) => { setuserprice(e.target.value >= 0 ? e.target.value : 0) }}
                                                        />
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Bulk Card</div>
                                                        <select className='custom-select' value={bulk_card} onChange={(e) => setbulk_card(e.target.value)} required>
                                                            <option value=''>Select</option>
                                                            <option value='true'>Yes</option>
                                                            <option value='false'>No</option>
                                                        </select>
                                                    </div>
                                                </li>
                                            </>
                                            : ""}
                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Multi Recepients</div>
                                                <select className='custom-select' value={multi_res} onChange={(e) => setmulti_res(e.target.value)} required>
                                                    <option value=''>Select</option>
                                                    <option value='true'>Yes</option>
                                                    <option value='false'>No</option>
                                                </select>
                                            </div>
                                        </li>

                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Show On Website</div>
                                                <select className='custom-select' value={showOnBanner} onChange={(e) => setshowOnBanner(e.target.value)} required>
                                                    <option value=''>Select</option>
                                                    <option value='true'>Yes</option>
                                                    <option value='false'>No</option>
                                                </select>
                                            </div>
                                        </li>
                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Description</div>
                                                <input
                                                    type="text"
                                                    className="data-value ip-field"
                                                    value={description}
                                                    placeholder="Description"
                                                    onChange={(e) => { setDescription(e.target.value) }}
                                                    required />
                                            </div>
                                        </li>
                                        {[...Array(featurecount)].map((star, index) => {

                                            return <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">Features</div>
                                                    <input
                                                        type="text"
                                                        className="data-value ip-field"
                                                        value={features["features" + index] || ''}
                                                        placeholder="Features"
                                                        onChange={(e) => {
                                                            setfeatures({ ...features, ["features" + index]: e.target.value })
                                                            if (e.target.value.length == 0) {
                                                                removeKey("features" + index)
                                                            }
                                                        }
                                                        }
                                                        required />
                                                          {
                                                        (index => 1) ?
                                                         <button className="delete-button" onClick={() => removeFeature(index)}>Delete</button>:''
                                                          }
                                                         {
                                                        (index + 1) == featurecount ?
                                                            < button className="addbutton" onClick={e => setfeaturecount(featurecount + 1)}>Add</button> : ""
                                                    }
                                                </div>
                                            </li>
                                        })}

                                    </ul>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <div className="my-3 text-center">
                                        <div className="d-flex justify-content-center">
                                            <button
                                                className="btn btn-md btn-primary"
                                                onClick={() => { editData ? handleEditProduct(editId) : handleAddProdcut() }}
                                            >
                                                {editData ? "Edit Plan" : "Add Plan"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        </>
    )
}
export default PlanList;