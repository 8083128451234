import { postMethod } from '../Utils/ApiServices';
const loginAPi = async (data) => {
    const returnFlag = await postMethod('admin/login', data);
    return returnFlag;
};
const addCategoryApi = async (data) => {
    const returnFlag = await postMethod('category/add', data);
    return returnFlag;
}

const addProductApi = async (data) => {
    const returnFlag = await postMethod('product-type/add', data);
    return returnFlag;
}

const addTemplateApi = async (data) => {
    const returnFlag = await postMethod('template/add', data);
    return returnFlag;
}
const bulktabTemplateApi = async (data) => {
    const returnFlag = await postMethod('admin/getUpload', data);
    return returnFlag;
}

const searchTemplateApi = async (value) => {
    const returnFlag = await postMethod('template/search', { name: value.toLowerCase() });
    return returnFlag;
}

const addPricingPlan = async (data) => {
    const returnFlag = await postMethod('pricing/add', data);
    return returnFlag;
}
const addPlan = async (data) => {
    const returnFlag = await postMethod('plans', data);
    return returnFlag;
}

const Sendmemberinvites = async (dataDetails) => {
    const returnFlag = await postMethod('/invite/member' + "?ownerId=" + dataDetails.data.owner, dataDetails.data);
    return returnFlag;
}



const addCouponDetails = async (data) => {
    const returnFlag = await postMethod('coupon/addcoupon', data);
    return returnFlag;
}

const postUpdateProfileDetails = async (data) => {
    const returnFlag = await postMethod('user/profilepic', data);
    return returnFlag;
}

const verifyAdminEmail = async (data) => {
    const returnFlag = await postMethod('user/update/email', data);
    return returnFlag;
}
const addCardForShowCase = async (data) => {
    const returnFlag = await postMethod('showCase', data);
    return returnFlag;
}
const sendInvitationUsers = async (data) => {
    const returnFlag = await postMethod('invite', data);
    return returnFlag;
}

const resendInvitationuser = async (data) => {
    const returnFlag = await postMethod('invite/resend', data);
    return returnFlag;
}

const addUserCardCom = async (data) => {
    const returnFlag = await postMethod('admin/component/gif/edit', data);
    return returnFlag;
}
export { loginAPi, addCategoryApi, addUserCardCom, addProductApi, addTemplateApi, addPlan, Sendmemberinvites, resendInvitationuser, searchTemplateApi, addPricingPlan, addCouponDetails, postUpdateProfileDetails, verifyAdminEmail, addCardForShowCase, sendInvitationUsers,bulktabTemplateApi };