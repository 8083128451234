import React, { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import { useToasts } from 'react-toast-notifications';
import { toastComp } from "../Sections/Toast";
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { Modal } from "react-responsive-modal";
import 'react-overlay-loader/styles.css';
import "react-responsive-modal/styles.css";
import { resendInvitationuser, sendInvitationUsers } from "../Action/PostAction";
import { getInviteList, getInviteListSearch } from "../Action/GetAction";
import { dateFormatConverter } from "../Logic/Logic";
import { deleteMethod, WEB_URL } from "../Utils/ApiServices";
import { format } from 'fecha';
import { RiDeleteBin6Fill } from 'react-icons/ri'
import { confirmAlert } from 'react-confirm-alert';
import { ExportToExcel } from '../tools/ExportToExcel';
import { DateRangePicker } from 'react-date-range';
import { addDays, subDays } from 'date-fns';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const InviteUser = (props) => {
    const { addToast } = useToasts();
    const [loader, setLoader] = useState(true);
    const [open, setOpen] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [email, setEmail] = useState('');
    const [geneateLink, setGenerateInvitationLink] = useState(false);
    const [copyLink, setCopyLink] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [toggleSearchBar, setToggleSearchBar] = useState(true);
    const [paginationIndex, setPageIndex] = useState(0);
    const [countProduct, setCountProduct] = useState(0);
    const [search, setSearch] = useState("");
    const [excelData, setExcelData] = useState([]);
    const [searchPage, setSearchPage] = useState();
    const [searchst, setSearchst] = useState('');
    const [searchsub, setSearchsub] = useState('');
    const [filterToggle, setFilterToggle] = useState(false);
    const [pageChange, setPageNow] = useState(1);
    const [stateFilters, setDateFilters] = useState([
        {
            startDate: subDays(new Date(), 4),
            endDate: addDays(new Date(), 2),
            key: 'selection'
            //             startDate: 2022-02-12
            // endDate: 2022-02-19
        }
    ]);
    const [selectdate, setselectdate] = useState({})
    const filtersDate = async (data) => {
        var passData = false;
        if (data) {
            setDateFilters(data)
            passData = ({ startDate: JSON.stringify(new Date(data[0]?.startDate)).slice(1, 11), endDate: JSON.stringify(new Date(data[0]?.endDate)).slice(1, 11) })
            handleSerach(1, 10, search, searchst, searchsub, passData);
        }
        setselectdate(passData)
    }

    const onCloseModal = (props) => {
        setOpen(false);
        setGenerateInvitationLink(false)
        setCopyLink(false)
        setEmail('')
        setPromoCode('');
    }
    const getApiCall = async (page, limit) => {
        setLoader(true);
        await getInviteList(page, limit, false).then(response => {
            if (response.success) {
                setLoader(false)
                setCountProduct(response.total);
                setDataList(response.data.reverse());
                setPageIndex(Math.ceil(response.total / limit));
            } else {
                setLoader(false);
            }
        })
    }
    const handlePageClick = async (e) => {
        const selectedPage = parseInt(e.selected + 1);
        setLoader(true);
        if (search && searchst) {
            handleSerach(selectedPage, 10, search, searchst, searchsub, selectdate);
        } else {
            await getApiCall(selectedPage, 10);
        }

    };

    const handleSubmitSearch = async (e) => {
        e.preventDefault();
        const { search } = e.target.elements;
        setSearchPage(0);
        handleSerach(1, 10, search.value, searchst, searchsub, selectdate)
    }

    const handleSerach = async (page, limit, value, status, isopen, data) => {
        setLoader(true)
        try {
            const response = await getInviteListSearch(page, limit, value.toLowerCase(), status, isopen, data);
            if (response && response.success) {
                setLoader(false);
                setDataList(response.data);
                setCountProduct(response.total);
                setPageIndex(Math.ceil(response.total / limit));
            }
        } catch (error) {
            setLoader(false);
        }
    }
    const resendEmailUser = async (e, email) => {
        e.preventDefault();
        const data = { email: email };
        resendInvitationuser(data).then(response => {
            if (response.success) {
                toastComp(addToast, 'success', response.message);
                getApiCall()
            } else {
                toastComp(addToast, 'error', response.message);
            }
        })
    }
    const sendInviteLink = async (e) => {
        e.preventDefault();
        if (email) {
            let data = { email: email };
            if (promoCode) {
                data = Object.assign(data, { promoCode: promoCode })
            }
            await sendInvitationUsers(data).then(response => {
                if (response.success) {
                    setOpen(false);
                    setEmail('');
                    setPromoCode('')
                    getApiCall()
                    toastComp(addToast, 'success', response.message);
                } else {
                    toastComp(addToast, 'error', response.message);
                }
            })
        } else {
            toastComp(addToast, 'error', 'Please Enter The Email Id');
        }
    }
    const handleDeleteUser = async (id) => {
        await deleteMethod('invite/delete/' + id).then((response) => {
            if (response.success) {
                toastComp(addToast, 'success', response.message);
                getApiCall(1, 10);
            } else {
                toastComp(addToast, 'error', response.message);
                getApiCall(1, 10);
            }
        })
    }

    const popup = (id, index) => {
        confirmAlert({
            title: 'Confirm Action',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDeleteUser(id, index)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    const dataAllUser = async (page, lim) => {
        setExcelData([]);
        await getInviteList(page, lim, true).then(async response => {
            {
                if (response.success) {
                    let newData = (response.data)?.map((items, index) => ({
                        S_No: index + 1,
                        Email: items?.email ? items?.email : '---',
                        Invited_By: items?.inviteBy ? items?.inviteBy?.email + '(' + (items?.inviteBy?.role) + ')' : '---',
                        Mail_Open: items?.isOpen ? "yes" : "No",
                        Status: items?.isComplete ? "Joinde" : "Resend Mail",
                        Date: items?.createdAt ? format(new Date((items?.createdAt)), 'Do MMM YYYY') : "",
                        Promo_Code: items?.promoCode ? items?.promoCode : "---",

                    }))

                    setExcelData(newData)
                }
            }
        })
    }

    var divisor = (countProduct / 10) >> 0;
    var remainder = countProduct % 10;


    useEffect(() => {
        getApiCall(1, 10);
        dataAllUser(1, 10);
    }, [])
    return (
        <>
            <div className="nk-content ">
                <div className="container-fluid">
                    <div className="nk-content-inner">
                        <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-between">
                                    <div className="nk-block-head-content">
                                        <h3 className="nk-block-title page-title">Invite Users</h3>
                                        <div className="nk-block-des text-soft">
                                            <p>You have total {countProduct ? countProduct + ' user' : 0 + ' users'}.</p>
                                        </div>
                                    </div>
                                    <div className="nk-block-head-content">

                                        <div className={filterToggle ? 'row ctaActive' : 'row cta'} style={{ height: 'fit-content', position: 'fixed', right: '1%', zIndex: 9999, top: '10%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'start', flexWrap: 'wrap', paddingBottom: '15px' }}>
                                            <button className='togglebff' onClick={(e) => setFilterToggle(filterToggle ? false : true)} style={{ width: '50px', height: '50px', backgroundColor: '#526484', color: '#fff', border: 'solid #526484 1px', display: 'flex', alignItems: 'center', justifyContent: 'center', outline: 'none', textAlign: 'center', alignItems: 'center', fontSize: '16px' }}><em className="icon ni ni-filter"></em></button>
                                            <div className='row hideshow' style={{ width: '100%', margin: '0px', display: 'flex', flexDirection: 'column', backgroundColor: '#fff', padding: '15px' }}>
                                                <DateRangePicker
                                                    onChange={(item) => { setPageNow(1); filtersDate([item.selection]); setFilterToggle(filterToggle ? false : true) }}
                                                    ranges={stateFilters}
                                                    direction='horizontal'
                                                    showDateDisplay={true}
                                                    showPreview={true}
                                                    editableDateInputs={true}
                                                    dragSelectionEnabled={true}
                                                    showSelectionPreview={true}
                                                    showMonthAndYearPickers={true}
                                                />
                                                <button onClick={(e) => resetButton()} style={{ width: '100%', backgroundColor: '#526484', color: '#fff', border: 'solid #526484 1px', outline: 'none', textAlign: 'center', alignItems: 'center', fontSize: '14px', padding: '5px', borderRadius: '25px', marginTop: '15px' }}>Reset</button>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="toggle-wrap nk-block-tools-toggle">
                                        <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                        <div className="toggle-expand-content" data-content="pageMenu">
                                            <ul className="nk-block-tools g-3">
                                                <li>
                                                    {
                                                        excelData?.length ?
                                                            <ExportToExcel apiData={excelData} fileName={"greetpool-user-list-page" + Date.now()} />
                                                            : null
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                        <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                        <div className="toggle-expand-content" data-content="pageMenu">
                                            <ul className="nk-block-tools g-3">
                                                <li><a className="btn btn-white btn-outline-light" onClick={(e) => { setOpen(true); setGenerateInvitationLink(false) }}><em className="icon ni ni-plus"></em><span>Send Invite</span></a></li>
                                                <li><a className="btn btn-white btn-outline-light" onClick={(e) => { setOpen(true); setGenerateInvitationLink(true) }}><em className="icon ni ni-plus"></em><span>Generate Invitation Link</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="nk-block">
                                <div className="card card-bordered card-stretch">
                                    <div className="card-inner-group">
                                        <div className="card-inner position-relative card-tools-toggle">
                                            <div className={toggleSearchBar ? 'card-search search-wrap active' : 'card-search search-wrap active'} data-search="search">
                                                <div className="card-body">
                                                    <form className="search-content" onSubmit={(e) => { handleSubmitSearch(e) }} style={{ display: "flex", justifyContent: "start" }}>
                                                        <input type="text" onChange={(e) => setSearch(e.target.value)} name='search' id='search' className="form-control border-transparent form-focus-none" placeholder="Search by user name/email..." style={{ paddingLeft: '0px', textTransform: 'none', width: "calc(100% - 450px)" }} />
                                                        <select className="btn btn-white btn-outline-light" style={{
                                                            width: '150px', height: '100%', outline: 'none',
                                                            border: 'solid rgb(82, 100, 132) 1px', backgroundColor: 'rgb(82, 100, 132)',
                                                            color: '#fff', margin: '0px', marginTop: '0px', marginLeft: '10px'
                                                        }} onChange={(e) => setSearchsub(e.target.value)}>
                                                            <option value=''>Filter By Mails</option>
                                                            <option value={true}>Yes</option>
                                                            <option value={false}>No</option>
                                                        </select>

                                                        <select className="btn btn-white btn-outline-light" style={{
                                                            width: '180px', height: '100%', outline: 'none',
                                                            border: 'solid rgb(82, 100, 132) 1px', backgroundColor: 'rgb(82, 100, 132)',
                                                            color: '#fff', margin: '0px', marginTop: '0px', marginLeft: '10px'
                                                        }} onChange={(e) => setSearchst(e.target.value)}>
                                                            <option value=''>Filter By Status</option>
                                                            <option value={true}>Joined</option>
                                                            <option value={false}>Resend Mail</option>
                                                        </select>
                                                        <button className="search-submit btn btn-icon"><em className="icon ni ni-search"></em></button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-inner p-0">
                                            <div className="nk-tb-list nk-tb-ulist">
                                                <div className="nk-tb-item nk-tb-head">
                                                    <div className="nk-tb-col nk-tb-col-check" style={{ width: '0px', padding: '10px' }}>
                                                    </div>
                                                    <div className="nk-tb-col"><span className="sub-text">Email</span></div>
                                                    <div className="nk-tb-col"><span className="sub-text">Promo Code</span></div>
                                                    <div className="nk-tb-col"><span className="sub-text">Invited By</span></div>
                                                    <div className="nk-tb-col tb-col-mb"><span className="sub-text">Mail Open</span></div>
                                                    <div className="nk-tb-col tb-col-mb"><span className="sub-text">Date</span></div>
                                                    <div className="nk-tb-col tb-col-mb"><span className="sub-text">Status</span></div>
                                                    <div className="nk-tb-col nk-tb-col-tools text-right"><span className="sub-text">More</span></div>
                                                </div>
                                                {
                                                    loader ?
                                                        <LoadingOverlay className='loaderDiv'>
                                                            <Loader loading={loader} />
                                                        </LoadingOverlay>
                                                        : null
                                                }
                                                {
                                                    dataList?.length > 0 ?
                                                        dataList?.map((data, index) =>
                                                            <div className="nk-tb-item" id={data._id} key={index}>
                                                                <div className="nk-tb-col nk-tb-col-check" style={{ width: '0px', padding: '10px' }}>
                                                                </div>
                                                                <div className="nk-tb-col">
                                                                    <div className="user-info">
                                                                        <span className="tb-lead">
                                                                            {/* <Link to={'user-details?id=' + data?._id}  > */}
                                                                            {data?.email}<span className="dot dot-success d-md-none ml-1"></span>
                                                                            {/* </Link> */}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-mb">
                                                                    <span className="tb-lead">{data?.promoCode ? data?.promoCode : '---'}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-mb">
                                                                    <span className="tb-lead">{data?.inviteBy ? data?.inviteBy?.email + '(' + (data?.inviteBy?.role) + ')' : '---'}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-mb">
                                                                    <span className="tb-lead">{data?.isOpen ? 'Yes' : 'No'}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-mb">
                                                                    <span className="tb-lead">{format(new Date((data?.createdAt)), 'Do MMM YYYY')}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-mb">
                                                                    {
                                                                        data?.isComplete ?
                                                                            <span style={{ color: '#81dbcd' }}>Joined</span>
                                                                            :
                                                                            <button onClick={(e) => resendEmailUser(e, data?.email)} style={{ border: 'solid #203247 1px', backgroundColor: '#203247', fontSize: '11px', color: '#fff' }}>Resend Mail</button>
                                                                    }
                                                                </div>
                                                                <div className="nk-tb-col nk-tb-col-tools">
                                                                    <button onClick={(e) => popup(data?._id, index)} style={{ border: 'solid rgb(224,62,62) 1px', backgroundColor: 'rgb(224,62,62)', fontSize: '16px', color: '#fff', cursor: 'pointer' }}><RiDeleteBin6Fill /></button>
                                                                </div>
                                                            </div>
                                                        )
                                                        :
                                                        <LoadingOverlay className='loaderDiv'>
                                                            <Loader loading={loader} />
                                                            <h1 style={{ fontSize: '18px', padding: '15px', color: 'red', textAlign: 'center' }}>Not Found!</h1>
                                                        </LoadingOverlay>
                                                }
                                            </div>
                                        </div>
                                        {
                                            paginationIndex ?
                                                <div className="card-inner">
                                                    <ReactPaginate
                                                        forcePage={searchPage}
                                                        previousLabel={"Prev"}
                                                        nextLabel={"Next"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={remainder >= 1 ? divisor + 1 : divisor}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={(e) => handlePageClick(e)}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"} />
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} closeOnOverlayClick={false}>
                <div className='container-fluid' style={{ width: '100%' }}>
                    <div className='row justify-content-center'>
                        <div className='col-md-12 col-12'>
                            <h3 style={{ textAlign: 'left' }}>{geneateLink ? 'Generate Invitation Link' : 'Send Invitation'}</h3>
                        </div>
                        <div className='col-md-12 col-12' style={{ marginTop: "15px" }}>
                            <div className='row justify-content-center'>
                                <div className="col-lg-12 col-12">
                                    <ul className="data-list is-compact">
                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Enter Email</div>
                                                <input
                                                    type="email"
                                                    className="data-value ip-field"
                                                    value={email}
                                                    placeholder="Email Id"
                                                    onChange={(e) => {
                                                        if (geneateLink) {
                                                            setCopyLink(false)
                                                            setEmail(e.target.value)
                                                        } else {
                                                            setEmail(e.target.value)
                                                        }
                                                    }}
                                                    required />
                                            </div>
                                        </li>
                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Enter Promo Code(Optional)</div>
                                                <input
                                                    type="text"
                                                    className="data-value ip-field"
                                                    value={promoCode}
                                                    placeholder="Promo Code"
                                                    onChange={(e) => {
                                                        setPromoCode(e.target.value)
                                                    }}
                                                    required />
                                            </div>
                                        </li>
                                        {
                                            geneateLink ?
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Share Link</div>
                                                        <p id='copyUrl'>{promoCode ? (WEB_URL + 'invitation?email=' + email + '&promo-code=' + promoCode) : (WEB_URL + 'invitation?email=' + email)}</p>
                                                    </div>
                                                </li>
                                                : null
                                        }
                                    </ul>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <div className="my-3 text-center">
                                        <div className="d-flex justify-content-center">
                                            <button
                                                className="btn btn-md btn-primary"
                                                onClick={(e) => {
                                                    if (geneateLink) {
                                                        setCopyLink(true)
                                                        var copyText = document.getElementById("copyUrl");
                                                        navigator.clipboard.writeText(copyText.innerText);
                                                    } else {
                                                        sendInviteLink(e)
                                                    }
                                                }}
                                            >
                                                {geneateLink ? copyLink ? 'Copied Link' : 'Copy Link' : 'Send Invitation'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default InviteUser;