import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
    getSubscriptionDetails,
    getUserDetails,
    getUserOrderDetails,
    getteammembers,
    getuserselectplan,
} from '../Action/GetAction'
import { useToasts } from 'react-toast-notifications'
import { toastComp } from '../Sections/Toast'
import { LoadingOverlay, Loader } from 'react-overlay-loader'
import ReactPaginate from 'react-paginate'
import { dateFormatConverter, timeConvert } from '../Logic/Logic'
import { Modal } from 'react-responsive-modal'
import 'react-overlay-loader/styles.css'
import 'react-responsive-modal/styles.css'
import { Sendmemberinvites, addCardForShowCase } from '../Action/PostAction'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { getCookies } from '../Utils/Cookies'
import { addmemberrole } from '../Action/PutAction'
import { deletemember } from '../Action/DeleteAction'
function useQuery() {
    return new URLSearchParams(useLocation().search)
}
const TeamDetails = (props) => {
    let query = useQuery()
    const history = useHistory()
    const { addToast } = useToasts()
    const [profilePic, setProfilePic] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [dob, setDOB] = useState('')
    const [emailId, setEmailId] = useState('')
    const [active, setActive] = useState('')
    const [verify, setVerify] = useState('')
    const [password, setPassword] = useState('')
    const [wallets, setWalletsPoint] = useState(0)
    const [createdCount, setCreateCount] = useState(0)
    const [receivedCount, setReceivedCount] = useState(0)
    const [signedCount, setSignedCount] = useState(0)
    const [totalSpent, setTotalSpent] = useState(0)
    const [totalPlan, setTotalPlan] = useState(0)
    const [loader, setLoader] = useState(true)
    const [orderType, setOrderType] = useState('created')
    const [orderLoader, setOrderLoader] = useState(true)
    const [userOrderList, setOrderListDetails] = useState([])
    const [pageIndex, setPageIndex] = useState(0)
    const [cardTotal, setCardTotal] = useState(0)
    const [open, setOpen] = useState(false)
    const [showCaseId, setAddShowCaseId] = useState('')
    const [title, setTitle] = useState('')
    const [subTitle, setSubTitle] = useState('')
    const [description, setDescription] = useState('')
    const [walletPointSet, setWalletPointEdit] = useState(0)
    const [subscription, setsubscription] = useState(0);
    const [invitedata, setinvitedata] = useState({
        "email": "",
        "firstName": "",
        "lastname": "",
        "message": "",
        "role": "Admin"
    })

    const [subscriptionplan, setsubscriptionplan] = useState([]);
    const [loadToggle, setLoaderToggle] = useState(true);
    const [defaultPlans, setDefaultPlans] = useState(0);
    const [cardscount, setcardscount] = useState(0);
    const [usercounts, setusercounts] = useState(0);
    const [cardsprice, setcardsprice] = useState(0);
    const [userprice, setuserprice] = useState(0);
    const [Plans, setPlans] = useState({})
    const [memberlist, setmemberlist] = useState([]);
    const [memberlist2, setmemberlist2] = useState([]);
    const [level, setlevel] = useState({});

    useEffect(() => {

        if (query.get('id') != undefined) {
            getSubscriptionDetails().then((response2) => {
                setsubscriptionplan(response2.data);
                getuserselectplan(query.get('id')).then(
                    (response) => {
                        if (response.success != false) {
                            setLoaderToggle(false);
                            setPlans(response.data);
                            console.log(response.data);
                            setsubscription(response?.data?.billingType == "annually" ? 1 : 0)
                            response2?.data?.map((planget, index) => {
                                if (response?.data?.planDetails?.planName == planget?.planName) {
                                    setcardscount(response?.data?.selectedCardsCount);
                                    setusercounts(response?.data?.selectedUsersCount);
                                    response2?.data.map((data2, ind) => {
                                        if (data2.planType == "Team") {
                                            setcardsprice((data2?.cards?.count < response?.data?.selectedCardsCount ? (parseInt(response?.data?.selectedCardsCount) - data2?.cards?.count) * parseInt(data2?.cards?.price ? data2?.cards?.price : 0) : 0))
                                            setuserprice((data2?.users?.count < response?.data?.selectedUsersCount ? parseInt(parseInt(response?.data?.selectedUsersCount) - data2?.users?.count) * parseInt(data2?.users?.price) : 0))
                                        }
                                    });
                                } else {
                                    response2?.data.map((data2, ind) => {
                                        if (data2.planType == "Team") {
                                            setcardscount(data2?.cards?.count);
                                            setusercounts(data2?.users?.count);
                                        }
                                    })
                                }
                            })

                        } else {
                            response2?.data.map((data2, ind) => {
                                if (data2.planType == "Team") {
                                    setcardscount(data2?.cards?.count);
                                    setusercounts(data2?.users?.count);
                                }
                            })
                        }
                    },
                    (error) => {
                        setLoaderToggle(false)
                        history.push('/pricing');
                    }
                )
            },
                (error) => {
                    setLoaderToggle(false)
                    history.push('/pricing');
                }
            )

            getuserselectplan(query.get('id')).then(
                (response) => {
                    if (response?.success != false) {
                        getteammembers({
                            id: response?.data?._id,
                            ownerId: response?.data?.user
                        }).then(
                            (response2) => {
                                setOrderLoader(false)
                                setLoaderToggle(false);
                                setmemberlist(response2);
                                setmemberlist2(response2);
                            },
                            (error) => { setLoaderToggle(false) }
                        )
                    }
                }
            )
        }
        window.scrollTo(0, 0);
    }, []);




    async function getSingleUserDetails(id) {
        await getUserDetails(id).then((response) => {
            if (response.success) {
                setWalletsPoint(response?.data?.wallet ? response?.data?.wallet : 0)
                setCreateCount(
                    response?.data?.createdCards ? response?.data?.createdCards : 0,
                )
                setReceivedCount(
                    response?.data?.receivedCards ? response?.data?.receivedCards : 0,
                )
                setSignedCount(
                    response?.data?.signedCards ? response?.data?.signedCards : 0,
                )
                setTotalSpent(
                    response?.data?.totalspent ? response?.data?.totalspent : 0,
                )
                setTotalPlan(
                    response?.data?.userBundle ? response?.data?.userBundle : 0,
                )
                setWalletPointEdit(response?.data?.wallet ? response?.data?.wallet : 0)
                setLoader(false)
            } else {
                // history.push('/user-list')
            }
        })
    }
    // const userOrderRelatedDetails = async (id, page, limit, type) => {
    //     setOrderLoader(true)
    //     await getUserOrderDetails(id, page, limit, type).then((response) => {
    //         if (response.success) {
    //             if (
    //                 response.data.createdCards ||
    //                 response.data.receivedCards ||
    //                 response.data.signedCards ||
    //                 response.data.coupon
    //             ) {
    //                 setOrderLoader(false)
    //                 const arrayd =
    //                     response.data.createdCards ||
    //                     response.data.receivedCards ||
    //                     response.data.signedCards ||
    //                     response.data.coupon
    //                 setOrderListDetails([arrayd])
    //                 setCardTotal(response?.data?.total)
    //                 setPageIndex(Math.ceil(response.data.total / limit))
    //             }
    //         } else {
    //             setOrderLoader(true)
    //         }
    //     })
    // }




    // const onCloseModal = () => {
    //     setOpen(false)
    //     setAddShowCaseId('')
    //     setTitle('')
    //     setSubTitle('')
    //     setDescription('')
    // }

    // const AddCardForShowCaseAPI = async () => {
    //     if (title != '' && subTitle != '' && showCaseId != '') {
    //         const data = {
    //             title: title,
    //             minTitle: subTitle,
    //             description: description,
    //             canvasId: showCaseId,
    //         }
    //         await addCardForShowCase(data).then((response) => {
    //             if (response.success) {
    //                 toastComp(
    //                     addToast,
    //                     'success',
    //                     'Successfully Card Added For Showcase!',
    //                 )
    //                 onCloseModal()
    //             } else {
    //                 toastComp(addToast, 'error', response.message)
    //             }
    //         })
    //     } else {
    //         toastComp(addToast, 'error', 'Please fill the required input field!')
    //     }
    // }
    // const handlePageClick = async (e) => {
    //     const selectedPage = parseInt(e.selected + 1)
    //     setOrderLoader(true)
    //     await userOrderRelatedDetails(query.get('id'), selectedPage, 5, orderType)
    // }
    useEffect(async () => {
        if (query.get('id')) {
            await getSingleUserDetails(query.get('id'))
            // await userOrderRelatedDetails(query.get('id'), 1, 5, orderType)
        } else {
            // history.push('/user-list')
        }
    }, [])



    const Sendmemberinvite = (data2) => {
        Sendmemberinvites(
            data2 ? {
                data: data2
            } :
                {
                    data: {
                        "email": invitedata.email,
                        "firstName": invitedata.firstName,
                        "lastname": invitedata.lastname,
                        "message": invitedata.message,
                        "owner": query.get('id'),
                        "role": invitedata.role
                    }
                }).then((response) => {
                    if (response && response.message != "This User Already signed Up !") {
                        setLoaderToggle(false);
                        addToast('Request Sent!', {
                            appearance: 'success',
                            autoDismiss: true,
                        })
                        setinvitedata({
                            "email": "",
                            "firstName": "",
                            "lastname": "",
                            "message": "",
                            "role": "Admin"
                        })

                        if (query.get('id') != undefined) {
                            getuserselectplan(query.get('id')).then(
                                (response) => {
                                    if (response?.success != false) {
                                        getteammembers({
                                            id: response?.data?._id,
                                            ownerId: response?.data?.user
                                        }).then(
                                            (response2) => {
                                                setOrderLoader(false)
                                                setmemberlist(response2);
                                                setmemberlist2(response2);
                                            },
                                            (error) => { setLoaderToggle(false) }
                                        )
                                    }
                                }
                            )
                        }
                    } else {
                        setLoaderToggle(false);
                        addToast(response.message, {
                            appearance: 'error',
                            autoDismiss: true,
                        })
                        setinvitedata({
                            "email": "",
                            "firstName": "",
                            "lastname": "",
                            "message": ""
                        })
                    }
                },
                    (error) => {
                        setLoaderToggle(false);
                        addToast(error, {
                            appearance: 'error',
                            autoDismiss: true,
                        })
                        setinvitedata({
                            "email": "",
                            "firstName": "",
                            "lastname": "",
                            "message": "",
                            "role": "Admin"
                        })
                    }
                )
    }

    const handleSubmit = event => {
        event.preventDefault();
        if (Plans?.selectedUsersCount > memberlist?.members?.length) {
            Sendmemberinvite()
        } else {
            addToast('Sorry!,Not Send Because Your Users Limit Reached!', {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    };

    const dateFormatConverter2 = (arg) => {
        let date = new Date(arg);
        let datel = date.getUTCDate();
        let monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan']
        let returnDate = datel + ' ' + monthArray[(date.getUTCMonth())] + ' ' + date.getUTCFullYear();
        return returnDate;
    }


    const changememberrole = (rsdata) => {
        addmemberrole(rsdata).then((response) => {
            setlevel(response)
            setmemberlist([])
            if (query.get('id') != undefined) {
                getuserselectplan(query.get('id')).then(
                    (response) => {
                        if (response?.success != false) {
                            getteammembers({
                                id: response?.data?._id,
                                ownerId: response?.data?.user
                            }).then(
                                (response2) => {
                                    setOrderLoader(false)
                                    setmemberlist(response2);
                                    setmemberlist2(response2);
                                },
                                (error) => { setLoaderToggle(false) }
                            )
                        }
                    }
                )
            }
        })
    }
    const memberdelete = (rsdata, id) => {
        deletemember({
            "data": rsdata,
            "id": id
        }).then((response) => {
            if (response.success) {
                addToast('Deleted!', {
                    appearance: 'success',
                    autoDismiss: true,
                })
                setlevel(response)
                setmemberlist([])
                if (query.get('id') != undefined) {
                    getuserselectplan(query.get('id')).then(
                        (response) => {
                            if (response?.success != false) {
                                getteammembers({
                                    id: response?.data?._id,
                                    ownerId: response?.data?.user
                                }).then(
                                    (response2) => {
                                        setOrderLoader(false)
                                        setmemberlist(response2);
                                        setmemberlist2(response2);
                                    },
                                    (error) => { setLoaderToggle(false) }
                                )
                            }
                        }
                    )
                }
            }
        },
            (error) => {
                console.log(error);
            }
        )
    }
    const onShowAlert = (rsdata) => {
        confirmAlert({
            title: 'Confirm Action',
            message: 'Are you sure you want to change this Level?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => changememberrole(rsdata),
                },
                {
                    label: 'No',

                }
            ]
        });
    }



    const onShowAlert2 = (rsdata, editId) => {
        confirmAlert({
            title: 'Confirm Action',
            message: 'Are you sure you want to delete this member?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => memberdelete(rsdata, editId),
                },
                {
                    label: 'No',
                }
            ]
        });
    };
    const onShowAlert3 = (rsdata) => {
        confirmAlert({
            title: 'Confirm Action',
            message: 'Are you sure you want to resend invite?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Sendmemberinvite(rsdata),
                },
                {
                    label: 'No',

                }
            ]
        });
    };
    return (
        <>
            <div className="nk-content" style={{ marginBottom: "20px" }}>
                <div className="container-fluid pt-3 px-5">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm" style={{ display: "none" }}>
                            <div className="nk-block-between g-3">
                                <div className="nk-block-head-content">
                                    <h3 className="nk-block-title page-title countd">
                                        Selected Subscription Plans
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-12 text-center" style={{ marginTop: '', marginBottom: '25px', padding: '0 0 25px 0px', display: "none" }}>
                            {/* <h1 class="h1-vpe1" style={{ lineHeight: 'normal', margin: '0px', padding: '0px', display: 'flex', width: '100%', alignItems: 'flex-start', justifyContent: 'center', textAlign: 'center' }}>

                            <strong style={{ display: 'flex', alignContent: 'center', flexDirection: 'column', color: '#0C6980', textAlign: 'center', fontSize: '31px', marginBottom: '15px', fontWeight: "600", letterSpacing: "0.1px" }}>Purchase Subscription Plans</strong></h1> */}
                            <div class="select_btn_plan">
                                <div class="btns_2">
                                    <button className={subscription == 0 ? 'active' : ''} onClick={e => setsubscription(0)}>Monthly</button>
                                    <button className={subscription == 1 ? 'active' : ''} onClick={e => setsubscription(1)}>Yearly</button>
                                </div>
                            </div>
                            <div class="row justify-content-center" style={{ marginTop: "40px" }}>
                                <br />
                                {
                                    subscriptionplan?.map((data, index) => {

                                        if (data.planType == "Solo") {
                                            return (
                                                <div className={'col-md-3 col-12 flexSetPricing  subscription_detail' + (Plans?.planDetails?.planName != data?.planName ? "" : " Selected")} >
                                                    <h4>{data?.planName}</h4>
                                                    {subscription == 1 ? <h2><span className='discount'>${data?.monthlyPrice * 12}/yr</span> ${data?.annualPrice * 12}<span>/yr</span></h2> : <h2> ${data?.monthlyPrice}<span>/mo</span></h2>}
                                                    <h6>{data?.description}</h6>
                                                    {
                                                        data?.featuresList.length > 0 ?
                                                            <>
                                                                <p>Benefits</p>
                                                                <ul>
                                                                    {
                                                                        data?.featuresList.map(e => {
                                                                            return <li>
                                                                                <img alt='Basic Plan' title='basic plan' src={'./assets/icons/pro1.png'} />{e?.featureText} </li>
                                                                        })
                                                                    }
                                                                </ul>
                                                            </> : ""
                                                    }

                                                    {/* <button className='card-btn button--moema' onClick={e => {
                                                        confirmAlert({
                                                            title: 'Confirmation',
                                                            message: 'Your changes will be reflected in the next billing cycle if you have any questions please reach out to support.',
                                                            buttons: [
                                                                {
                                                                    label: 'Yes',
                                                                    onClick: () => {
                                                                        dispatch({
                                                                            type: Type.SUBSCRIPTION,
                                                                            payload: {
                                                                                creators: {
                                                                                    price: subscription == 1 ? data?.annualPrice : data?.monthlyPrice,
                                                                                    subscription_id: data?._id,
                                                                                    user_count: 1,
                                                                                    card_count: data?.cards?.count,
                                                                                    subscription_data: {
                                                                                        "planId": data?._id,
                                                                                        "selectedCardsCount": data?.cards?.count,
                                                                                        "selectedUsersCount": 1,
                                                                                        "billingType": subscription == 1 ? "annually" : "monthly"
                                                                                    }
                                                                                }
                                                                            },
                                                                        }),
                                                                            history.push('/plan-checkout?subscription=' + data?._id);
                                                                    }
                                                                },
                                                                {
                                                                    label: 'No',
                                                                }
                                                            ]
                                                        });

                                                    }}>
                                                        {Plans?.planDetails?.planName != data?.planName ? "Buy Now" : "Update"}
                                                    </button> */}
                                                </div>
                                            )
                                        } else if (data.planType == "Team") {
                                            return (
                                                <div className={'col-md-3 col-12 flexSetPricing  subscription_detail second' + (Plans?.planDetails?.planName != data?.planName ? "" : " Selected")} >
                                                    <h5>Most Popular</h5>
                                                    <h4>{data?.planName}</h4>
                                                    {subscription == 1 ? <h2><span className='discount'>${(data?.monthlyPrice * 12) + (userprice * 12) + (cardsprice * 12)}/yr</span> ${(data?.annualPrice * 12) + (userprice * 12) + (cardsprice * 12)}<span>/yr</span></h2>
                                                        :
                                                        <h2> ${data?.monthlyPrice + userprice + cardsprice}<span>/mo</span></h2>}
                                                    <h6>{data?.description}</h6>
                                                    <p>Benefits</p>
                                                    <ul>
                                                        {
                                                            data?.featuresList.length > 0 ?
                                                                <>
                                                                    {
                                                                        data?.featuresList.map(e => {
                                                                            return <li>
                                                                                <img alt='Basic Plan' title='basic plan' src={'./assets/icons/pro1.png'} />{e?.featureText} </li>
                                                                        })
                                                                    }

                                                                </> : ""
                                                        }
                                                        {
                                                            data?.cards.unlimited && data?.cards.unlimited == true ?
                                                                <li>
                                                                    <img alt='Basic Plan' title='basic plan' src={'./assets/icons/pre1.png'} />
                                                                    Unlimited Cards
                                                                </li>
                                                                :
                                                                <li>
                                                                    <img alt='Basic Plan' title='basic plan' src={'./assets/icons/pre1.png'} />
                                                                    <button disabled onClick={() => {
                                                                        setcardscount(data?.cards?.count < cardscount ? parseInt(cardscount) - 10 : cardscount)
                                                                        setcardsprice(data?.cards?.count < cardscount ? parseInt((parseInt(cardscount) - 10) - data?.cards?.count) * parseInt(data?.cards?.price) : 0)
                                                                    }}>-</button>
                                                                    <b>{cardscount ? "" : data?.cards?.count}</b>
                                                                    <button onClick={() => {
                                                                        setcardsprice(data?.cards?.count <= cardscount ? parseInt((parseInt(cardscount) + 10) - data?.cards?.count) * parseInt(data?.cards?.price) : 0)
                                                                        setcardscount(parseInt(cardscount) + 10)
                                                                    }}>+</button>  Cards
                                                                    <img alt='Basic Plan' title='basic plan' src={'./assets/icons/pre2.png'} />
                                                                    <span>Add 1 card price ${data?.cards?.price}</span>
                                                                </li>
                                                        }
                                                        <li>
                                                            <img alt='Basic Plan' title='basic plan' src={'./assets/icons/pre1.png'} />
                                                            <button disabled onClick={() => {
                                                                setusercounts(data?.users?.count < usercounts ? parseInt(usercounts) - 2 : usercounts)
                                                                setuserprice(data?.users?.count < usercounts ? parseInt((parseInt(usercounts) - 2) - data?.users?.count) * parseInt(data?.users?.price) : 0)
                                                            }}>-</button>
                                                            <b>{Plans?.planDetails?.planName != data?.planName ? usercounts : Plans?.selectedUsersCount}</b>
                                                            <button onClick={() => {
                                                                setusercounts(parseInt(usercounts) + 2)
                                                                setuserprice(data?.users?.count <= usercounts ? parseInt((parseInt(usercounts) + 2) - data?.users?.count) * parseInt(data?.users?.price) : 0)
                                                            }}>+</button>  Users
                                                            <img alt='Basic Plan' title='basic plan' src={'./assets/icons/pre2.png'} />
                                                            <span>Add 1 user price ${data?.users?.price}</span>
                                                        </li>
                                                    </ul>
                                                    {/* <button className='card-btn button--moema' onClick={e => {
                                                        confirmAlert({
                                                            title: 'Confirmation',
                                                            message: 'Your changes will be reflected in the next billing cycle if you have any questions please reach out to support.',
                                                            buttons: [
                                                                {
                                                                    label: 'Yes',
                                                                    onClick: () => {
                                                                        dispatch({
                                                                            type: Type.SUBSCRIPTION,
                                                                            payload: {
                                                                                creators: {
                                                                                    price: subscription == 1 ? data?.annualPrice + userprice + cardsprice : data?.monthlyPrice + userprice + cardsprice,
                                                                                    subscription_id: data?._id,
                                                                                    user_count: usercounts,
                                                                                    card_count: cardscount,
                                                                                    subscription_data: {
                                                                                        "planId": data?._id,
                                                                                        "selectedCardsCount": cardscount,
                                                                                        "selectedUsersCount": usercounts,
                                                                                        "billingType": subscription == 1 ? "annually" : "monthly"
                                                                                    }
                                                                                }
                                                                            }
                                                                        }),
                                                                            history.push('/plan-checkout?subscription=' + data?._id);
                                                                    }
                                                                },
                                                                {
                                                                    label: 'No',
                                                                }
                                                            ]
                                                        });

                                                    }}>
                                                        {Plans?.planDetails?.planName != data?.planName ? "Buy Now" : "Update"}
                                                    </button> */}
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className={'col-md-3 col-12 flexSetPricing  subscription_detail third'} >
                                                    <h4>{data?.planName}</h4>
                                                    <h6>{data?.description}</h6>
                                                    {/* <p>Benefits</p>
                                            <ul>
                                                <li>
                                                    <img alt='Basic Plan' title='basic plan' src={'./assets/icons/pro1.png'} />Unlimited Cards <img alt='Basic Plan' title='basic plan' src={'./assets/icons/pre2.png'} ></img></li>
                                                <li >
                                                    <img alt='Basic Plan' title='basic plan' src={'./assets/icons/pro1.png'} />UnlimitedLorem Ipsum <img alt='Basic Plan' title='basic plan' src={'./assets/icons/pre2.png'} ></img></li>
                                                <li >
                                                    <img alt='Basic Plan' title='basic plan' src={'./assets/icons/pro1.png'} />UnlimitedLorem Ipsum <img alt='Basic Plan' title='basic plan' src={'./assets/icons/pre2.png'} ></img></li>
                                                <li className=''>
                                                    <img alt='Basic Plan' title='basic plan' src={'./assets/icons/basic2.png'} />Lorem Ipsum <img alt='Basic Plan' title='basic plan' src={'./assets/icons/pre2.png'} ></img></li>
                                            </ul> */}
                                                    <button className='card-btn button--moema'>
                                                        <a href='/contact-us'> Contact us</a>
                                                    </button>
                                                </div>
                                            )
                                        }

                                    })

                                }
                            </div>
                        </div>
                        <div className="nk-block-head nk-block-head-sm" style={{ display: "none" }}>
                            <div className="nk-block-between g-3">
                                <div className="nk-block-head-content">
                                    <h3 className="nk-block-title page-title countd">
                                        Plan Details
                                    </h3>
                                </div>
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <a
                                        href="#"
                                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                        data-target="pageMenu"
                                    >
                                        <em className="icon ni ni-menu-alt-r"></em>
                                    </a>
                                    {/* <div
                                        className="toggle-expand-content"
                                        data-content="pageMenu"
                                    >
                                        <ul className="nk-block-tools g-3">
                                            <li>
                                                <a
                                                    className="btn btn-white btn-outline-light"
                                                    onClick={async (e) => {
                                                        var formData = new FormData()
                                                        formData.append('role', 'admin')
                                                        await updateProfclePic(formData)
                                                    }}
                                                >
                                                    <em className="icon ni ni-plus"></em>
                                                    <span>Make Admin</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="nk-block">
                            <div className="row gy-5 reducePadding1" style={{ display: "none" }}>
                                {loader ? (
                                    <div className="col-lg-12 col-12" style={{ height: '300px' }}>
                                        <LoadingOverlay className="loaderDiv">
                                            <Loader loading={loader} />
                                        </LoadingOverlay>
                                    </div>
                                ) : (
                                    <>
                                        <div className="col-lg-12 col-12">
                                            <div
                                                className="row justify-content-center"
                                                style={{ marginTop: '0', marginBottom: '15px' }}
                                            >
                                                <div
                                                    className="col-lg-3 col-12"
                                                    style={{ marginTop: '0', marginBottom: '15px' }}
                                                >
                                                    <div className="card card-bordered">
                                                        <div className="card-inner">
                                                            <div className="card-title-group align-start mb-2">
                                                                <div className="card-title">
                                                                    <h6 className="title">Plan Name</h6>
                                                                </div>
                                                            </div>
                                                            <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                                <div className="nk-sale-data">
                                                                    <span className="amount">
                                                                        {Plans?.planDetails?.planName}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-lg-3 col-12"
                                                    style={{ marginTop: '0', marginBottom: '15px' }}
                                                >
                                                    <div className="card card-bordered">
                                                        <div className="card-inner">
                                                            <div className="card-title-group align-start mb-2">
                                                                <div className="card-title">
                                                                    <h6 className="title">Plan Price</h6>
                                                                </div>
                                                            </div>
                                                            <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                                <div className="nk-sale-data">
                                                                    <span className="amount">
                                                                        {Plans?.planPricing}$
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-lg-3 col-12"
                                                    style={{ marginTop: '0', marginBottom: '15px' }}
                                                >
                                                    <div className="card card-bordered">
                                                        <div className="card-inner">
                                                            <div className="card-title-group align-start mb-2">
                                                                <div className="card-title">
                                                                    <h6 className="title">Type of Plan </h6>
                                                                </div>
                                                            </div>
                                                            <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                                <div className="nk-sale-data">
                                                                    <span className="amount">{Plans?.billingType}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-lg-3 col-12"
                                                    style={{ marginTop: '0', marginBottom: '15px' }}
                                                >
                                                    <div className="card card-bordered">
                                                        <div className="card-inner">
                                                            <div className="card-title-group align-start mb-2">
                                                                <div className="card-title">
                                                                    <h6 className="title">Payment Date</h6>
                                                                </div>
                                                            </div>
                                                            <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                                <div className="nk-sale-data">
                                                                    <span className="amount">
                                                                        {dateFormatConverter(Plans?.paymentDetails?.paymentDate)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-lg-3 col-12"
                                                    style={{ marginTop: '0', marginBottom: '15px' }}
                                                >
                                                    <div className="card card-bordered">
                                                        <div className="card-inner">
                                                            <div className="card-title-group align-start mb-2">
                                                                <div className="card-title">
                                                                    <h6 className="title">Due Date </h6>
                                                                </div>
                                                            </div>
                                                            <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                                <div className="nk-sale-data">
                                                                    <span className="amount">{dateFormatConverter2(Plans?.paymentDetails?.dueDate)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    className="col-lg-3 col-12"
                                                    style={{ marginTop: '0', marginBottom: '15px' }}
                                                >
                                                    <div className="card card-bordered">
                                                        <div className="card-inner">
                                                            <div className="card-title-group align-start mb-2">
                                                                <div className="card-title">
                                                                    <h6 className="title">Total Wallet</h6>
                                                                </div>
                                                            </div>
                                                            <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                                <div className="nk-sale-data">
                                                                    <span className="amount">{wallets}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-lg-3 col-12"
                                                    style={{ marginTop: '0', marginBottom: '15px' }}
                                                >
                                                    <div className="card card-bordered">
                                                        <div className="card-inner">
                                                            <div className="card-title-group align-start mb-2">
                                                                <div className="card-title">
                                                                    <h6 className="title">Total Created</h6>
                                                                </div>
                                                            </div>
                                                            <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                                <div className="nk-sale-data">
                                                                    <span className="amount">{createdCount}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-lg-3 col-12"
                                                    style={{ marginTop: '0', marginBottom: '15px' }}
                                                >
                                                    <div className="card card-bordered">
                                                        <div className="card-inner">
                                                            <div className="card-title-group align-start mb-2">
                                                                <div className="card-title">
                                                                    <h6 className="title">Total Received</h6>
                                                                </div>
                                                            </div>
                                                            <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                                <div className="nk-sale-data">
                                                                    <span className="amount">{receivedCount}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div
                                className="row justify-content-center"
                                style={{
                                    marginTop: '15px',
                                    marginBottom: '15px',
                                    height: '100%',
                                    display: "none"
                                }}
                            >
                                <div className="col-lg-12 col-12 text-left">
                                    <div className="nk-block-between">
                                        <div className="nk-block-head-content">
                                            <h3 className="nk-block-title page-title countd">
                                                Team Members
                                            </h3>
                                            <div className="nk-block-des text-soft">
                                                <p>
                                                    You have total{' '}
                                                    {memberlist?.members?.length > 0 ? memberlist?.members?.length + ' team member' : 0 + ' team member'}
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div
                                    className="col-md-12 col-12"
                                    style={{
                                        marginTop: '15px',
                                        marginBottom: '15px',
                                        height: '100%',
                                    }}
                                >
                                    <div
                                        className="row gy-10"
                                        style={{
                                            marginTop: '10px',
                                            backgroundColor: '#fff',
                                            height: '100%',
                                        }}
                                    >
                                        <div className="nk-tb-list nk-tb-ulist">
                                            <div className="nk-tb-item nk-tb-head">
                                                <div className="nk-tb-col nk-tb-col-check">
                                                    <span className="sub-text">S.No</span>
                                                </div>
                                                <div className="nk-tb-col">
                                                    <span className="sub-text">
                                                        Name
                                                    </span>
                                                </div>
                                                <div className="nk-tb-col tb-col-md">
                                                    <span className="sub-text">
                                                        Email
                                                    </span>
                                                </div>
                                                <div className="nk-tb-col tb-col-md">
                                                    <span className="sub-text">
                                                        Status
                                                    </span>
                                                </div>
                                                <div className="nk-tb-col tb-col-md">
                                                    <span className="sub-text">
                                                        Level
                                                    </span>
                                                </div>
                                                <div className="nk-tb-col tb-col-md">
                                                    <span className="sub-text">{'Joined'}</span>
                                                </div>
                                                <div className="nk-tb-col nk-tb-col-tools text-right">
                                                    <span className="sub-text">More</span>
                                                </div>
                                            </div>
                                            {orderLoader ? (
                                                <LoadingOverlay
                                                    className="loaderDiv"
                                                    style={{ left: '0px' }}
                                                >
                                                    <Loader loading={orderLoader} />
                                                </LoadingOverlay>
                                            ) : null}
                                            {memberlist?.members?.length > 0 ? (
                                                memberlist?.members?.map((data, index) => (
                                                    <div className="nk-tb-item" key={index}>
                                                        <div className="nk-tb-col nk-tb-col-check">
                                                            <div className="custom-control custom-control-sm custom-checkbox notext">
                                                                <span className="tb-lead">
                                                                    {parseInt(index + 1)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="nk-tb-col">
                                                            <div className="user-info">
                                                                <span className="tb-lead">
                                                                    {data.firstName
                                                                        ? data.firstName
                                                                        : '---'}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="nk-tb-col tb-col-md">
                                                            <span className="tb-lead">
                                                                {data.email
                                                                    ? data.email
                                                                    : '---'}
                                                            </span>
                                                        </div>
                                                        <div className="nk-tb-col tb-col-md">
                                                            <span className="tb-lead">
                                                                {data.verified == true ? "Accepted" : "Pending"}
                                                            </span>
                                                        </div>
                                                        <div className="nk-tb-col tb-col-md">
                                                            <span className="tb-lead">
                                                                {data.teamRole ? data.teamRole : data?.inviteType ? "creater" : "member"}
                                                            </span>
                                                        </div>
                                                        <div className="nk-tb-col tb-col-md">
                                                            <span className="tb-lead">
                                                                {data.updatedAt
                                                                    ? dateFormatConverter(data.updatedAt)
                                                                    : '---'}
                                                            </span>
                                                        </div>
                                                        <div className="nk-tb-col nk-tb-col-tools">
                                                            <ul className="nk-tb-actions gx-1">
                                                                <li>
                                                                    <div className="drodown">
                                                                        <a
                                                                            href="#"
                                                                            className="dropdown-toggle btn btn-icon btn-trigger"
                                                                            data-toggle="dropdown"
                                                                        >
                                                                            <em className="icon ni ni-more-h"></em>
                                                                        </a>
                                                                        {orderType === 'created' && data.teamRole != "owner" ? (
                                                                            <>
                                                                                <div className="dropdown-menu dropdown-menu-right" style={{ minWidth: "260px" }}>

                                                                                    <ul className="link-list-opt no-bdr teamdatalist">

                                                                                        {
                                                                                            data.teamRole != "owner" && data.teamRole != "Admin" && data.teamRole != "admin" && data.verified == true ?
                                                                                                <li
                                                                                                    onClick={e => {
                                                                                                        onShowAlert({
                                                                                                            id: data._id,
                                                                                                            role: "Admin",
                                                                                                            owner: query.get('id')
                                                                                                        })
                                                                                                    }}
                                                                                                >
                                                                                                    <a>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="15" width="15" viewBox="0 0 512 512" >
                                                                                                            <path d="M213.3,384c0-87,65.2-158.7,149.3-169.2c0-0.9,0-1.5,0-1.5c5.5-8,21.3-21.3,21.3-42.7s-21.3-42.7-21.3-53.3  C362.7,32,319.2,0,256,0c-60.5,0-106.7,32-106.7,117.3c0,10.7-21.3,32-21.3,53.3s15.2,35.4,21.3,42.7c0,0,0,21.3,10.7,53.3  c0,10.7,21.3,21.3,32,32c0,10.7,0,21.3-10.7,42.7L64,362.7C21.3,373.3,0,448,0,512h271.4C235.9,480.7,213.3,435,213.3,384z M384,256  c-70.7,0-128,57.3-128,128s57.3,128,128,128s128-57.3,128-128S454.7,256,384,256z M469.3,405.3h-64v64h-42.7v-64h-64v-42.7h64v-64  h42.7v64h64V405.3z" />
                                                                                                        </svg>
                                                                                                        Upgrade to admin
                                                                                                    </a>
                                                                                                </li>
                                                                                                : ""}

                                                                                        {data.teamRole != "owner" && data.verified == true && data.teamRole != "member" && data.teamRole != "Member" ?
                                                                                            <li
                                                                                                onClick={e => {
                                                                                                    onShowAlert({
                                                                                                        id: data._id,
                                                                                                        role: "Member",
                                                                                                        owner: query.get('id')
                                                                                                    })
                                                                                                }}
                                                                                            >
                                                                                                <a>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" >
                                                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.714 22 12C22 7.28595 22 4.92893 20.5355 3.46447C19.0711 2 16.714 2 12 2C7.28595 2 4.92893 2 3.46447 3.46447C2 4.92893 2 7.28595 2 12C2 16.714 2 19.0711 3.46447 20.5355ZM7.56507 13.0693C7.29271 12.7573 6.81893 12.7251 6.50685 12.9974C6.19477 13.2698 6.16257 13.7436 6.43493 14.0557L9.43493 17.4932C9.6405 17.7287 9.97062 17.812 10.2633 17.7022C10.5561 17.5925 10.75 17.3126 10.75 17V7C10.75 6.58579 10.4142 6.25 10 6.25C9.58579 6.25 9.25 6.58579 9.25 7V15L7.56507 13.0693ZM13.7367 6.29775C13.4439 6.40753 13.25 6.68737 13.25 7L13.25 17C13.25 17.4142 13.5858 17.75 14 17.75C14.4142 17.75 14.75 17.4142 14.75 17L14.75 9L16.4349 10.9306C16.7073 11.2427 17.1811 11.2749 17.4931 11.0026C17.8052 10.7302 17.8374 10.2564 17.5651 9.94435L14.5651 6.50685C14.3595 6.2713 14.0294 6.18798 13.7367 6.29775Z" />
                                                                                                    </svg>
                                                                                                    Make to Member
                                                                                                </a>
                                                                                            </li> : ""}

                                                                                        {data.verified != true ?
                                                                                            <li
                                                                                                onClick={e => {
                                                                                                    setTimeout(() => {
                                                                                                        onShowAlert3(
                                                                                                            {
                                                                                                                "email": data.email,
                                                                                                                "firstName": data.firstName,
                                                                                                                "lastname": data.lastName,
                                                                                                                "message": "",
                                                                                                                "role": data.teamRole,
                                                                                                                "owner": query.get('id')
                                                                                                            }
                                                                                                        )
                                                                                                    }, 1000);
                                                                                                }}>
                                                                                                <a>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 32 32" version="1.1">
                                                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd" type="MSPage">
                                                                                                            <g id="Icon-Set-Filled" type="MSLayerGroup" transform="translate(-570.000000, -257.000000)" >
                                                                                                                <path d="M580.407,278.75 C581.743,281.205 586,289 586,289 C586,289 601.75,258.5 602,258 L602.02,257.91 L580.407,278.75 L580.407,278.75 Z M570,272 C570,272 577.298,276.381 579.345,277.597 L601,257 C598.536,258.194 570,272 570,272 L570,272 Z" id="send-email" type="MSShapeGroup">
                                                                                                                </path>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </svg>
                                                                                                    Resend invite
                                                                                                </a>
                                                                                            </li>
                                                                                            : ""}
                                                                                        <li
                                                                                            onClick={e => {
                                                                                                onShowAlert2(data._id, query.get('id'))
                                                                                            }}
                                                                                        >
                                                                                            <a>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" >
                                                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.06935 5.25839C2 5.62595 2 6.06722 2 6.94975V14C2 17.7712 2 19.6569 3.17157 20.8284C4.34315 22 6.22876 22 10 22H14C17.7712 22 19.6569 22 20.8284 20.8284C22 19.6569 22 17.7712 22 14V11.7979C22 9.16554 22 7.84935 21.2305 6.99383C21.1598 6.91514 21.0849 6.84024 21.0062 6.76946C20.1506 6 18.8345 6 16.2021 6H15.8284C14.6747 6 14.0979 6 13.5604 5.84678C13.2651 5.7626 12.9804 5.64471 12.7121 5.49543C12.2237 5.22367 11.8158 4.81578 11 4L10.4497 3.44975C10.1763 3.17633 10.0396 3.03961 9.89594 2.92051C9.27652 2.40704 8.51665 2.09229 7.71557 2.01738C7.52976 2 7.33642 2 6.94975 2C6.06722 2 5.62595 2 5.25839 2.06935C3.64031 2.37464 2.37464 3.64031 2.06935 5.25839ZM9.25 13C9.25 12.5858 9.58579 12.25 10 12.25H14C14.4142 12.25 14.75 12.5858 14.75 13C14.75 13.4142 14.4142 13.75 14 13.75H10C9.58579 13.75 9.25 13.4142 9.25 13Z" />
                                                                                                </svg>
                                                                                                Remove from organization
                                                                                            </a>
                                                                                        </li>

                                                                                    </ul>

                                                                                </div>
                                                                            </>
                                                                        ) : ""}
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        position: 'absolute',
                                                        left: '0px',
                                                        backgroundColor: '#fff',
                                                    }}
                                                >
                                                    <h1
                                                        style={{
                                                            width: '100%',
                                                            textAlign: 'center',
                                                            fontSize: '16px',
                                                            lineHeight: '61px',
                                                            color: '#eb5757',
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        *Not Found
                                                    </h1>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className='row'>
                                <div className='member form_invite'>
                                    <div className="nk-block-head-content">
                                        <h3 className="nk-block-title page-title countd">
                                            Invite Other To Greetpool
                                        </h3>
                                    </div>
                                    <br />
                                    <br />
                                    <form className='invite_other' onSubmit={handleSubmit}>
                                        <div className='form_group'>
                                            <label>Name</label>
                                            <input type='text' placeholder='First Name here' onChange={e => {
                                                setinvitedata({ ...invitedata, firstName: e.target.value, lastname: e.target.value })
                                            }} required value={invitedata.firstName} />
                                        </div>

                                        <div className='form_group abc'>
                                            <label>Email address</label>
                                            <input type='email' placeholder='Email here' onChange={e => {
                                                setinvitedata({ ...invitedata, email: e.target.value })
                                            }} required value={invitedata.email} />
                                        </div>
                                        <div className='form_group '>
                                            <label>Level</label>
                                            <select onChange={e => {
                                                setinvitedata({ ...invitedata, role: e.target.value })
                                            }} required value={invitedata.lastname}>
                                                <option disabled>Select</option>
                                                <option value={"Admin"}>Admin</option>
                                                <option value={"Member"}>Member</option>
                                            </select>
                                        </div>

                                        <div className='form_group full_dis'>
                                            <label>Message (Optional)</label>
                                            <textarea placeholder='Type your message' rows={3} onChange={e => {
                                                setinvitedata({ ...invitedata, message: e.target.value })
                                            }} value={invitedata.message}></textarea>
                                        </div>
                                        <div className='invite_sub'>
                                            <button onClick={e => setinvitedata({
                                                "email": "",
                                                "firstName": "",
                                                "lastname": "",
                                                "message": "",
                                                "role": "Admin"
                                            })} className='card-btn button--moema'>Cancel</button>
                                            <button type='submit' className='card-btn button--moema new_color'>Invite Team Member</button>
                                        </div>
                                    </form>

                                </div>
                            </div >
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}
export default TeamDetails
