import React, { useEffect, useState } from "react";
import { getTemplateList, getDemoCardDetails } from '../Action/GetAction';
import { deleteComponentIdDemo } from '../Action/DeleteAction';
import { editDemoDetails } from '../Action/PutAction';
import { useToasts } from 'react-toast-notifications';
import { toastComp } from "../Sections/Toast";
import { Modal } from "react-responsive-modal";
import { BASE_IMAGE_URL } from "../Utils/ApiServices";
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Parser from 'html-react-parser';
import 'react-overlay-loader/styles.css';
import "react-responsive-modal/styles.css";
import { deleteMethod } from "../Utils/ApiServices";
const DemoCard = (props) => {
    const { addToast } = useToasts();
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(true);
    const [previousTemplates, setTemplates] = useState('');
    const [demoDate, setDemoDate] = useState('');
    const [templateList, setTemplatList] = useState([]);
    const [canavsDetails, getCanvasDetails] = useState(false);
    const [pages, setPages] = useState(1);
    const [totalPages, setTotalPages] = useState(2)
    const pickMonthArray = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const today = new Date();
    const minDate = today.getFullYear() + '-' + pickMonthArray[today.getMonth() + 1] + '-' + (today.getDate() < 10 ? pickMonthArray[today.getDate()] : today.getDate());
    const dateFormatConverter = (arg) => {
        let date = new Date(arg);
        return date.getUTCFullYear() + '-' + pickMonthArray[date.getUTCMonth() + 1] + '-' + (date.getUTCDate() < 10 ? pickMonthArray[date.getUTCDate()] : date.getUTCDate());
    }
    const onCloseModal = (props) => {
        setOpen(false);
    }
    const redaDemoCard = async () => {
        await getDemoCardDetails().then(async response => {
            if (response.status) {
                setLoader(false);
                await setDemoDate(dateFormatConverter(response?.data?.middle?.canvasDetails?.demoTime ? response?.data?.middle?.canvasDetails?.demoTime : ''));
                await setTemplates(response?.data?.middle?.canvasDetails?.templateId?._id ? response?.data?.middle?.canvasDetails?.templateId?._id : '');
                await getCanvasDetails(response.data)
                await setTotalPages(response?.data?.middle?.canvasContent?.length + 2)
            }
        })
    }
    const deleteComponent = async (id) => {
        setLoader(true);
        await deleteComponentIdDemo(id).then(async response => {
            if (response.success) {
                await redaDemoCard();
                setLoader(false);
                toastComp(addToast, 'success', 'Successfully deleted!');
            } else {
                setLoader(false);
                toastComp(addToast, 'error', 'Faield to delete components!');
            }
        })
    }
    const handleEmptyPageClear = async (e, id) => {
        setLoader(true);
        await deleteMethod('component/removepage?canvasId=' + id).then(async response => {
            if (response.status === true) {
                await redaDemoCard();
                setLoader(false);
                toastComp(addToast, 'success', 'Successfully deleted!');
            } else {
                setLoader(false);
                toastComp(addToast, 'error', response?.message);
            }
        })
    }
    const handleEditDemoData = async (e) => {
        setLoader(true);
        if (previousTemplates != '' && demoDate != '') {
            setOpen(false);
            const data = { templateId: previousTemplates, demoTime: demoDate };
            const params = {};
            await editDemoDetails(data, params).then(async (response) => {
                if (response.success) {
                    await redaDemoCard();
                    toastComp(addToast, 'success', 'Successfully Updated!');
                } else {
                    setLoader(false);
                    setOpen(true);
                    toastComp(addToast, 'error', 'Failed to update!');
                }
            })
        } else {
            setLoader(false);
            setOpen(true);
            toastComp(addToast, 'error', 'Please fill the empty input field!');
        }
    }
    useEffect(async () => {
        await redaDemoCard();
        await getTemplateList('template/templateslist').then(response => {
            if (response.success) {
                setTemplatList(response.template);
            }
        });

    }, [])
    return (
        <>
            <div className="nk-content">
                <div className="container-fluid pt-3 px-5">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between g-3">
                                <div className="nk-block-head-content">
                                    <h3 className="nk-block-title page-title">Demo Card</h3>
                                </div>
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                    <div className="toggle-expand-content" data-content="pageMenu">
                                        <ul className="nk-block-tools g-3">
                                            <li><a className="btn btn-white btn-outline-light" onClick={(e) => { setOpen(true) }}><em className="icon ni ni-plus"></em><span>Demo Card Setting</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                    <div className="toggle-expand-content" data-content="pageMenu">
                                        <ul className="nk-block-tools g-3">
                                            <li><a className="btn btn-white btn-outline-light" onClick={(e) => { handleEmptyPageClear(e, "608d295cf40f3c7082d22c32") }}><em className="icon ni ni-trash"></em><span>Clean Empty Pages</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nk-block">
                            <div className="row gy-12 row768">
                                <div className="col-lg-12 col-12 row768">
                                    <>
                                        {
                                            loader ?
                                                <LoadingOverlay className='loaderDiv'>
                                                    <Loader loading={loader} />

                                                    
                                                </LoadingOverlay>
                                                : null
                                        }
                                        <div className='row gy-6 justify-content-center'>
                                            <div className="col-lg-7 col-12 row768">
                                                <div className='row gy-6 row768 justify-content-center' style={{ width: '100%', margin: 0, padding: 0 }}>
                                                    <div className="col-lg-1 setHeight2 desktopHide">
                                                        <div className='row setHeight'></div>
                                                        <div className='row setHeight'>
                                                            <div className='arrowSet' onClick={() => { if (pages > 1) { setPages(pages - 1) } }} style={{ display: pages == 1 ? 'none' : 'block', cursor: 'pointer', backgroundImage: "url('assets/images/Group 385.png')", border: 'solid #81dbcd 2px', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                                                {/* <img src={process.env.PUBLIC_URL + 'assets/images/Group 385.png'} /> */}
                                                            </div>
                                                        </div>
                                                        <div className='row setHeight'></div>
                                                    </div>
                                                    <div className="col-lg-10 col-12 setHeight2 canvas_card displayCenter768 row768">
                                                        <ul className='mainPage_canvas' style={{ height: '550px', width: '410px' }}>
                                                            <li className='pagesContainer' style={{ display: pages == 1 ? 'block' : 'none' }} id={'front:' + canavsDetails?.middle?.canvasDetails?.templateId._id} data-type={'frontCover'}>
                                                                <img src={BASE_IMAGE_URL + canavsDetails?.middle?.canvasDetails?.templateId?.front} style={{ width: '100%', height: '100%' }} />
                                                            </li>
                                                            {
                                                                canavsDetails?.middle?.canvasContent?.map((readEleLists, mindex) =>
                                                                    <li className='pagesContainer' style={{ display: pages == mindex + 2 ? 'block' : 'none' }} key={mindex} id={readEleLists.pageDetails._id} data-type={readEleLists.pageDetails._id}>
                                                                        {
                                                                            readEleLists.pageContent.map((eleLists, index) =>
                                                                                <div className='elementsPreviousAdded textPreviewSim' data-type={eleLists.type} id={eleLists._id} key={index} style={{ border: 'solid #cecece 1px', width: parseFloat(eleLists.width), height: parseFloat(eleLists.height), position: 'absolute', transform: `translate(${parseFloat(eleLists.x)}px,${parseFloat(eleLists.y)}px) rotate(${parseFloat(eleLists.angle)}rad)` }}>
                                                                                    {/* <img className={eleLists.type === 'text' ? 'setProfile' : 'simple'} src={eleLists.type === 'text' ? eleLists.url : BASE_IMAGE_URL + eleLists.url} style={{ width: '100%', height: '100%' }} /> */}
                                                                                    {



                                                                                        eleLists?.type === 'text' ?
                                                                                            (eleLists?.url).substr(0, 4) === 'data' ?
                                                                                                <img alt={eleLists?.type} className={eleLists.type === 'text' ? 'setProfile' : 'simple'} src={eleLists.type == 'text' ? eleLists.url : (eleLists.url).substr(0, 4) == 'http' ? eleLists.url : (BASE_IMAGE_URL + eleLists.url)} style={{ width: '100%', height: '100%' }} />
                                                                                                :
                                                                                                Parser(eleLists?.url)

                                                                                            :
                                                                                            <img alt={eleLists?.type} className={eleLists.type === 'text' ? 'setProfile' : 'simple'} src={eleLists.type == 'text' ? eleLists.url : (eleLists.url).substr(0, 4) == 'http' ? eleLists.url : (BASE_IMAGE_URL + eleLists.url)} style={{ width: '100%', height: '100%' }} />
                                                                                    }
                                                                                    <div style={{ position: 'absolute', width: '25px', height: '25px', top: 0, right: '0px', cursor: 'pointer' }} onClick={() => deleteComponent(eleLists._id)}>
                                                                                        <em className="icon ni ni-trash" style={{ color: 'red', widht: '25px', height: '25px', fontSize: '20px' }}></em>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </li>
                                                                )
                                                            }
                                                            <li className='pagesContainer' style={{ display: pages == totalPages ? 'block' : 'none' }} id={'back:' + canavsDetails?.middle?.canvasDetails?.templateId._id} data-type={'backCover'}>
                                                                <img src={BASE_IMAGE_URL + canavsDetails?.middle?.canvasDetails?.templateId?.back} style={{ width: '100%', height: '100%' }} />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-1 setHeight2 desktopHide">
                                                        <div className='row setHeight'></div>
                                                        <div className='row setHeight'>
                                                            <div className='arrowSet' onClick={() => { if (pages < totalPages) { setPages(pages + 1) } }} id='nextPageClick' style={{ cursor: 'pointer', display: pages == totalPages ? 'none' : 'block', backgroundImage: "url('assets/images/Group 386.png')", border: 'solid #81dbcd 2px', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                                                {/* <img src={process.env.PUBLIC_URL + 'assets/images/Group 386.png'} /> */}
                                                            </div>
                                                        </div>
                                                        <div className='row setHeight'></div>
                                                    </div>
                                                    <div className='col-lg-12 col-12 mobileShow'>
                                                        <div className='row justify-content-center'>
                                                            <div className='arrowSet' onClick={() => { if (pages > 1) { setPages(pages - 1) } }} style={{ cursor: 'pointer', backgroundImage: "url('assets/images/Group 385.png')", border: 'solid #81dbcd 2px', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', width: '50px', height: '50px', margin: '5px' }}>
                                                                {/* <img src={process.env.PUBLIC_URL + 'assets/images/Group 385.png'} /> */}
                                                            </div>
                                                            <div className='arrowSet' onClick={() => { if (pages < totalPages) { setPages(pages + 1) } }} id='nextPageClick' style={{ cursor: 'pointer', backgroundImage: "url('assets/images/Group 386.png')", border: 'solid #81dbcd 2px', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', width: '50px', height: '50px', margin: '5px' }}>
                                                                {/* <img src={process.env.PUBLIC_URL + 'assets/images/Group 386.png'} /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} closeOnOverlayClick={false}>
                <div className='container-fluid' style={{ width: '100%' }}>
                    <div className='row justify-content-center'>
                        <div className='col-md-12 col-12'>
                            <h3 style={{ textAlign: 'left' }}>Edit Demo Card</h3>
                        </div>
                        <div className='col-md-12 col-12' style={{ marginTop: "15px" }}>
                            <div className='row justify-content-center'>
                                <div className="col-lg-12 col-12">
                                    <ul className="data-list is-compact">
                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Select Template</div>
                                                <select className='data-value ip-field disabled' name='notificationStatus' value={previousTemplates} onChange={(e) => setTemplates(e.target.value)}>
                                                    <option value=''>Select</option>
                                                    {
                                                        templateList?.map((item, index) =>
                                                            <option value={item._id} key={index}>{item.name}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </li>
                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Set Count-Down Time</div>
                                                <input type='date' min={minDate} value={demoDate} onChange={(e) => setDemoDate(e.target.value)}></input>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <div className="my-3 text-center">
                                        <div className="d-flex justify-content-center">
                                            <button
                                                className="btn btn-md btn-primary"
                                                onClick={(e) => {
                                                    handleEditDemoData(e);
                                                }}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default DemoCard;