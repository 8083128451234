import React from "react";
import { Link } from "react-router-dom";
import { BASE_IMAGE_URL } from '../Utils/ApiServices';
import { BiCategory } from 'react-icons/bi';
import { MdProductionQuantityLimits, MdEmojiObjects, MdContactSupport, MdOutlineAdminPanelSettings } from 'react-icons/md';
import { HiTemplate } from 'react-icons/hi';
import { GoPackage } from 'react-icons/go';
import { IoCardSharp } from 'react-icons/io5'
import { RiGalleryLine, RiCoupon2Line } from 'react-icons/ri';
import { VscPackage } from 'react-icons/vsc';
import { TiShoppingCart } from 'react-icons/ti';
import { FaUsers } from 'react-icons/fa';
const SideBar = (props) => {
    return (
        <div className="nk-sidebar nk-sidebar-fixed is-dark " data-content="sidebarMenu">
            <div className="nk-sidebar-element nk-sidebar-head" style={{ backgroundColor: '#fff', background: '#fff' }}>
                <div className="nk-sidebar-brand">
                    <Link to='/dashboard' className="logo-link nk-sidebar-logo">
                        <img className="logo-light logo-img" src={BASE_IMAGE_URL + 'webImages/Group+747.png'} alt="logo" />
                    </Link>
                </div>
                <div className="nk-menu-trigger mr-n2">
                    <Link to="#" className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left"></em></Link>
                </div>
            </div>
            <div className="nk-sidebar-element">
                <div className="nk-sidebar-content">
                    <div className="nk-sidebar-menu" data-simplebar>
                        <ul className="nk-menu">
                            <li className="nk-menu-heading">
                                <h6 className="overline-title text-primary-alt">Home Page Setting</h6>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle">
                                    <span className="nk-menu-icon"><BiCategory /></span>
                                    <span className="nk-menu-text">Category</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <Link to="/category-list" className="nk-menu-link"><span className="nk-menu-text">Category List</span></Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle">
                                    <span className="nk-menu-icon"><MdProductionQuantityLimits /></span>
                                    <span className="nk-menu-text">Product</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <Link to="/product-list" className="nk-menu-link"><span className="nk-menu-text">Product List</span></Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle">
                                    <span className="nk-menu-icon"><HiTemplate /></span>
                                    <span className="nk-menu-text">Template</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <Link to="/template-list" className="nk-menu-link"><span className="nk-menu-text">Template List</span></Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to="/bulktab-template" className="nk-menu-link"><span className="nk-menu-text">Bulktab Template</span></Link>
                                    </li>
                                </ul>
                            
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle">
                                    <span className="nk-menu-icon"><GoPackage /></span>
                                    <span className="nk-menu-text">Freebie's Packages</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <Link to="/freebie-packages" className="nk-menu-link"><span className="nk-menu-text">Packages</span></Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle">
                                    <span className="nk-menu-icon"><MdEmojiObjects /></span>
                                    <span className="nk-menu-text">Demo Card Setting</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <Link to="/demo-card" className="nk-menu-link"><span className="nk-menu-text">Setting</span></Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle">
                                    <span className="nk-menu-icon"><RiGalleryLine /></span>
                                    <span className="nk-menu-text">ShowCase CardSetting</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <Link to="/showcase-list" className="nk-menu-link"><span className="nk-menu-text">ShowCase Setting</span></Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle">
                                    <span className="nk-menu-icon"><IoCardSharp /></span>
                                    <span className="nk-menu-text">Group Cards</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <Link to="/group-cards" className="nk-menu-link"><span className="nk-menu-text">Group Cards Setting</span></Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nk-menu-heading">
                                <h6 className="overline-title text-primary-alt">Coupon & Pricing Plan</h6>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle">
                                    <span className="nk-menu-icon"><VscPackage /></span>
                                    <span className="nk-menu-text">Pricing Plan</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <Link to="/pricing-list" className="nk-menu-link"><span className="nk-menu-text">Pricing List</span></Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to="/plans" className="nk-menu-link"><span className="nk-menu-text">Plans</span></Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle">
                                    <span className="nk-menu-icon"><RiCoupon2Line /></span>
                                    <span className="nk-menu-text">Coupon</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <Link to="/coupon-list" className="nk-menu-link"><span className="nk-menu-text">Coupon List</span></Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nk-menu-heading">
                                <h6 className="overline-title text-primary-alt">Payment Details</h6>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle">
                                    <span className="nk-menu-icon"><TiShoppingCart /></span>
                                    <span className="nk-menu-text">All Orders</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <Link to="/orders" className="nk-menu-link"><span className="nk-menu-text">All Orders</span></Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to="/user-team" className="nk-menu-link"><span className="nk-menu-text">Users Team</span></Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to="/bundle-list" className="nk-menu-link"><span className="nk-menu-text">Bundles</span></Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nk-menu-heading">
                                <h6 className="overline-title text-primary-alt">User/Order Details</h6>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle">
                                    <span className="nk-menu-icon"><FaUsers /></span>
                                    <span className="nk-menu-text">Users</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <Link to="/user-list" className="nk-menu-link"><span className="nk-menu-text">Users List</span></Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to="/invite-user" className="nk-menu-link"><span className="nk-menu-text">Invite User</span></Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to="/subscribers" className="nk-menu-link"><span className="nk-menu-text">Subscribers</span></Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to="/testimonials" className="nk-menu-link"><span className="nk-menu-text">Testimonials</span></Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nk-menu-heading">
                                <h6 className="overline-title text-primary-alt">Support/Refund Setting</h6>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a to="#" className="nk-menu-link nk-menu-toggle">
                                    <span className="nk-menu-icon"><MdContactSupport /></span>
                                    <span className="nk-menu-text">Support</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <Link to="/support-list" className="nk-menu-link"><span className="nk-menu-text">Support List</span></Link>
                                    </li>

                                </ul>
                            </li>
                            <li className="nk-menu-heading">
                                <h6 className="overline-title text-primary-alt">Admin Account Setting</h6>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle">
                                    <span className="nk-menu-icon"><MdOutlineAdminPanelSettings /></span>
                                    <span className="nk-menu-text">Admin Account</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <Link to="/admin-account-setting" className="nk-menu-link"><span className="nk-menu-text">Admin Account Setting</span></Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SideBar;