const sortedList = async (array) => {
    array.sort(function (orderA, orderB) {
        return Number(new Date(orderB.createdAt)) - Number(new Date(orderA.createdAt));
    });
    return array;
}
function slugify(string) {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  }
const mergeParentChildrenCategory = async (array) => {
    var gatherArray = [];
    for (let li = 0; li < array.length; li++) {
        gatherArray.push(array[li])
        if (array[li].children.length > 0) {
            for (let lk = 0; lk < array[li].children.length; lk++) {
                gatherArray.push(array[li].children[lk]);
            }
        }
    }
    return gatherArray;
}

const matrixify = async (arr, size) => {
    arr = await sortedList(await mergeParentChildrenCategory(arr));
    const flag = await Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size)
    );
    return flag;
}
const matrixify1 = async (arr, size) => {
    // console.log("arr",arr);

    // if(arr.length === 0){
    //     let arr = [];
    //     return arr;
    // }
    arr = await sortedList(arr);
    const flag = await Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size)
    );
    return flag;
}

const matrixify2 = async (arr, size) => {
    const flag = await Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size)
    );
    return flag;
}
const dateFormatConverter = (arg) => {
    let date = new Date(arg);
    let datel = date.getUTCDate();
    let monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let returnDate = datel + ' ' + monthArray[(date.getUTCMonth())] + ' ' + date.getUTCFullYear()
    return returnDate;
}
const multiSelectTemp = async (value) => {
    var listCartegory = [];
    for (let li = 0; li < value.length; li++) {
        listCartegory.push({ value: value[li]._id, label: value[li].name })
        if (value[li].children.length > 0) {
            let addChildren = value[li].children;
            for (let lk = 0; lk < addChildren.length; lk++) {
                listCartegory.push({ value: addChildren[lk]._id, label: addChildren[lk].name })
            }
        }
    }
    return listCartegory;
}

const explitMultSelect = async (data) => {
    var list = [];
    for (let li = 0; li < data?.length; li++) {
        list.push({ value: data[li]._id, label: data[li].firstName });
    }
    return list;
}


const normalMulti = async (data) => {
    var list = [];
    for (let li = 0; li < data?.length; li++) {
        list.push({ value: data[li]._id, label: data[li].name });
    }
    return list;
}

const getOnlyValuefetch = async (arrayFetch) => {
    var arrayList = []
    for (let li = 0; li < arrayFetch.length; li++) {
        arrayList.push(arrayFetch[li].value)
    }
    return arrayList;
}

const fetcheditMultiData = async (exiArr) => {
    var list = [];
    for (let li = 0; li < exiArr.length; li++) {
        list.push({ value: exiArr[li] });
    }
    return list;
}
const timeConvert =(arg) => {
    let timeString = arg;
    let H = +timeString.substr(0, 2);
    let h = H % 12 || 12;
    let ampm = (H < 12 || H === 24) ? "AM" : "PM";
    let time = h + timeString.substr(2, 3) + ' ' + ampm;
    return time
}

const randomFont = () => {
    const fontCollection = [
        'Gloria Hallelujah', 'Homemade Apple','Knewave','Leckerli One','Righteous','Vampiro One','Sarina', 'Vibur','Nanum Pen Script', 'Patrick Hand', 'Calligraffitti', 'Cookie', 'Freehand', 'Handlee', 'Pacifico', 'Satisfy', 'Indie flower', 'Gochi Hand', 'Permanent Marker'
    ];
    return fontCollection[Math.floor(Math.random() * fontCollection.length)];
}
const randomColor = () => {
    const colourCollection = ['black', 'red', 'green', 'blue', 'pink', 'violet', 'magenta', 'sea', 'chocolate', 'purple', 'orange', 'tomato'];
    return colourCollection[Math.floor(Math.random() * colourCollection.length)];
}

export { matrixify, matrixify1,slugify, dateFormatConverter, multiSelectTemp, matrixify2, explitMultSelect, normalMulti, mergeParentChildrenCategory, getOnlyValuefetch, fetcheditMultiData, timeConvert, randomFont, randomColor };