import React, { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import { deletedCategoryApi } from "../Action/DeleteAction";
import { editCategoryApi } from '../Action/PutAction';
import { getCategoryList } from "../Action/GetAction";
import { addCategoryApi } from '../Action/PostAction';
import { matrixify, dateFormatConverter, slugify } from "../Logic/Logic";
import { useToasts } from 'react-toast-notifications';
import { toastComp } from "../Sections/Toast";
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { Modal } from "react-responsive-modal";
import { confirmAlert } from 'react-confirm-alert';
import { format } from 'fecha';
import { AiFillDelete } from 'react-icons/ai';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-overlay-loader/styles.css';
import "react-responsive-modal/styles.css";
import { postMethod, putMethod } from "../Utils/ApiServices";
import { ToggleButton } from "react-bootstrap";
const CategoryList = (props) => {
    const { addToast } = useToasts();
    const [toggleSearchBar, setToggleSearchBar] = useState(true);
    const [categoryList, setCategoryList] = useState([]);
    const [paginationIndex, setPageIndex] = useState(0);
    const [countCategory, setCountCategory] = useState(0);
    const [search, setSearch] = useState('');
    const [loader, setLoader] = useState(true);
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState(false);
    const [editId, setEditId] = useState(false);
    const [parentCategory, setParentCategory] = useState(false);
    const [name, setName] = useState('');
    const [parent, setParent] = useState('')
    const [showHomePage, setShowHomePage] = useState('');
    const [title, setTitle] = useState('');
    const [keywordSearch, setKeywordSearch] = useState('');
    const [slug, setSlug] = useState('');
    const [description, setDescription] = useState('');
    const [priority, setPriority] = useState(0)
    const [seoHeading, setSeoHeading] = useState('');
    const [isPrivate, setIsPrivate] = useState(false);
    const [seoDescription, setSeoDescription] = useState('');
    const [contentsList, setContentsList] = useState([]);
    const [howItWorksHeading, setHowItWorksHeading] = useState('');
    const [searchPage, setSearchPage] = useState();
    const priorityArray = new Array(101).fill(0);



    const updateData = async (id, data) => {
        const params = {
            "categoryId": id
        };
        await editCategoryApi(data, params).then(async response => {
            if (response.status) {
                // await getListData(true, 'Successfully edited category data!');
                // toastComp(addToast, 'success', 'Successfully edited category data!');
            } else {
                // setLoader(false);
                // setOpen(true);
                toastComp(addToast, 'error', response.message);
            }
        })
    }
    const onCloseModal = (props) => {
        setOpen(false);
        setEditData(false);
        setEditId(false)
        setName('');
        setParent('');
        setShowHomePage('');
        setTitle('');
        setKeywordSearch('');
        setSlug('');
        setPriority(0)
        setDescription('');
        setSeoHeading('');
        setIsPrivate(false);
        setSeoDescription('');
        setContentsList([]);
        setHowItWorksHeading('');
    }
    const handlePageClick = async (e) => {
        const selectedPage = e.selected;
        setPageIndex(selectedPage);
    };
    const countTotalCategory = async (array) => {
        var count = 0;
        for (let li = 0; li < array.length; li++) {
            count = count + array[li].length
        }
        await setCountCategory(count);
    }
    const handleSerach = async (value) => {
        setLoader(true);
        // setSearchPage(0);
        await getCategoryList('category?name=' + value).then(async response => {
            setLoader(false);
            const arrayList = await matrixify(response.data.categories, 10);
            await setCategoryList([arrayList]);
            await countTotalCategory(arrayList)
        });
    }
    const handleEditCategory = async (id) => {
        setLoader(true);
        var data = {}
        if (name != '' && title != '' && slug != '' && description != '') {
            setOpen(false);
            const params = {
                "categoryId": id
            };
            data = { name: name, parent: parent, seoHeading: seoHeading, private: isPrivate, howItHeading: howItWorksHeading, seoDescription: seoDescription, keywordSearch: keywordSearch, showOnHomepage: showHomePage ? showHomePage : false, title: title, slug: slug, description: description, priority: priority, pointers: contentsList?.length ? ((contentsList?.map((n) => n)).filter((n) => n)) : [] };
            // if (parent) {
            //     data = Object.assign(data, {
            //         "parent": parent,
            //     });
            // }
            await editCategoryApi(data, params).then(async response => {
                if (response.status) {
                    await getListData(true, 'Successfully edited category data!');
                    setLoader(false);
                    // toastComp(addToast, 'success', 'Successfully edited category data!');
                } else {
                    setLoader(false);
                    setOpen(true);
                    toastComp(addToast, 'error', 'Failed to edit category data!');
                }
            })
        } else {
            setLoader(false);
            toastComp(addToast, 'error', 'Please fill the required field!');
        }
    }
    const handleAddCategory = async () => {
        setLoader(true);
        var data = {}
        if (name != '' && title != '' && slug != '' && description != '') {
            setOpen(false);
            data = { name: name, showOnHomepage: showHomePage ? showHomePage : false, private: isPrivate,howItHeading: howItWorksHeading, title: title, keywordSearch: keywordSearch, slug: slug, description: description, priority: priority, pointers: contentsList?.length ? ((contentsList?.map((n) => n)).filter((n) => n)) : [] };
            if (parent) {
                data = Object.assign(data, {
                    "parent": parent,
                });
            }
            if (seoHeading != '') {
                data = Object.assign(data, {
                    seoHeading: seoHeading
                })
            }
            if (seoDescription != '') {
                data = Object.assign(data, {
                    seoDescription: seoDescription
                })
            }
            await addCategoryApi(data).then(async response => {
                if (response.success) {
                    await getListData(true, response.message);
                    // toastComp(addToast, 'success', response.message);
                } else {
                    setLoader(false);
                    toastComp(addToast, 'error', response.message);
                }
            })
        } else {
            setLoader(false);
            toastComp(addToast, 'error', 'Please fill the required field!');
        }
    }
    const editCategory = (data) => {
        setOpen(true);
        setEditData(true);
        setEditId(data?._id);
        setName(data?.name ? data?.name : '');
        setParent(data?.parent ? data?.parent : '');
        setShowHomePage(data?.showOnHomepage ? data?.showOnHomepage : false);
        setTitle(data?.title ? data?.title : '');
        setKeywordSearch(data?.keywordSearch ? data?.keywordSearch : '')
        setSlug(data?.slug ? data?.slug : '');
        setPriority(data?.priority ? data?.priority : 0);
        setContentsList(data?.pointers?.length ? data?.pointers : [])
        setDescription(data?.description ? data?.description : '');
        setSeoHeading(data?.seoHeading ? data?.seoHeading : '');
        setIsPrivate(data?.private ? data?.private : false);
        setHowItWorksHeading(data?.howItHeading ? data?.howItHeading : '')
        setSeoDescription(data?.seoDescription ? data?.seoDescription : '');
    }
    const deleteCategory = async (id, index) => {
        setLoader(true);
        await deletedCategoryApi(id).then(response => {
            if (response.success || response.status) {
                setLoader(false);
                categoryList[0]?.[paginationIndex].splice(index, 1);
                toastComp(addToast, 'success', 'Category deleted Successfully!');
            } else {
                setLoader(false);
                toastComp(addToast, 'error', 'Category id incorrect!');
            }
        })
    };
    const popup = (id, index) => {
        confirmAlert({
            title: 'Confirm Action',
            message: 'Are you sure you want to delete this card?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteCategory(id, index)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const handleUpdatePointer = (name, value, index) => {
        const updateIndex = [...contentsList];

        // if(name === 'sectionPointers')
        // {

        //     updateIndex[index] = {
        //         ...updateIndex[index],
        //         [name]: value
        //     };
        // }else{
        updateIndex[index] = {
            ...updateIndex[index],
            [name]: value
        };
        // }
        // console.log(updateIndex)
        setContentsList([...updateIndex]);

    }
    const handleUpdatePointerSub = (array, name, value, index, subIndex) => {
        const updateArray = [...array];
        // console.log("before updateArray", updateArray)
        updateArray.map((ele, index) => {
            ele.isImageMetaPreview = false;
        })
        // console.log("after updateArray", updateArray)
        updateArray[subIndex] = {
            ...updateArray[subIndex],
            [name]: value
        }



        const updateIndex = [...contentsList];
        updateIndex[index] = {
            ...updateIndex[index],
            ['sectionPointers']: updateArray
        };
        setContentsList([...updateIndex]);
    }
    const getListData = async (isNote, message) => {
        await getCategoryList('category').then(async response => {
            if (response.success) {

                onCloseModal();
                const arrayList = await matrixify(response.data.categories, 10);
                setCategoryList([...categoryList, arrayList]);
                await countTotalCategory(arrayList);
                setParentCategory(response.data.categories);
                setLoader(false);
                if (isNote) {
                    toastComp(addToast, 'success', message);
                }
            }
        })
    }

    var divisor = (countCategory / 10) >> 0;
    var remainder = countCategory % 10;
    useEffect(async () => {
        await getListData(false, '');
    }, [])
    return (
        <>
            <div className="nk-content ">
                <div className="container-fluid">
                    <div className="nk-content-inner">
                        <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-between">
                                    <div className="nk-block-head-content">
                                        <h3 className="nk-block-title page-title">Category List</h3>
                                        <div className="nk-block-des text-soft">
                                            <p>You have total {countCategory === 1 ? countCategory + ' category' : countCategory + ' categories'}.</p>
                                        </div>
                                    </div>
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                        <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                        <div className="toggle-expand-content" data-content="pageMenu">
                                            <ul className="nk-block-tools g-3">
                                                <li><a className="btn btn-white btn-outline-light" onClick={(e) => { setOpen(true) }}><em className="icon ni ni-plus"></em><span>Add Category</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="nk-block">
                                <div className="card card-bordered card-stretch">
                                    <div className="card-inner-group">
                                        <div className="card-inner position-relative card-tools-toggle">
                                            <div className={toggleSearchBar ? 'card-search search-wrap active' : 'card-search search-wrap active'} data-search="search">
                                                <div className="card-body">
                                                    <div className="search-content">
                                                        <input type="text" className="form-control border-transparent form-focus-none" value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={(e) => { e.keyCode === 13 ? handleSerach(search) : "" }} placeholder="Search by category name..." style={{ paddingLeft: '0px' }} />
                                                        <button onClick={() => handleSerach(search)} className="search-submit btn btn-icon"><em className="icon ni ni-search"></em></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-inner p-0">
                                            <div className="nk-tb-list nk-tb-ulist">
                                                <div className="nk-tb-item nk-tb-head">
                                                    <div className="nk-tb-col nk-tb-col-check" style={{ width: '0px', padding: '10px' }}>
                                                    </div>
                                                    <div className="nk-tb-col"><span className="sub-text text-center">Category Name / Title/ Slug</span></div>
                                                    <div className="nk-tb-col tb-col-mb"><span className="sub-text text-center">Show on Homepage / Priority / Choose Parent Category</span></div>
                                                    {/* <div className="nk-tb-col tb-col-mb"><span className="sub-text text-center">Choose Parent Category</span></div> */}
                                                    {/* <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">Slug</span></div> */}
                                                    {/* <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">Priority</span></div> */}
                                                    {/* <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">Giphy Search</span></div> */}
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">Description/Giphy Search Keyword/Meta Title</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">Created At</span></div>
                                                    <div className="nk-tb-col nk-tb-col-tools text-right"><span className="sub-text">More</span></div>
                                                </div>
                                                {
                                                    loader ?
                                                        <LoadingOverlay className='loaderDiv'>
                                                            <Loader loading={loader} />
                                                        </LoadingOverlay>
                                                        : null
                                                }
                                                {
                                                    categoryList[0]?.length > 0 ?
                                                        categoryList[0]?.[paginationIndex]?.map((data, index) =>
                                                            <div className="nk-tb-item" id={data._id} key={index}>
                                                                <div className="nk-tb-col nk-tb-col-check" style={{ width: '0px', padding: '10px' }}>

                                                                </div>
                                                                <div className="nk-tb-col">
                                                                    <div className="user-info">
                                                                        <div style={{ width: '100%', padding: '15px' }}>
                                                                            <textarea className="tb-lead text-center" style={{ width: '100%', height: '100%', border: 'solid #cecece 1px', padding: '15px 10px', borderRadius: '15px' }} placeholder="Enter Category Name"
                                                                                value={data?.name}
                                                                                onChange={(e) => {
                                                                                    let updateLsit = categoryList[0]?.[paginationIndex];
                                                                                    var update = [...updateLsit];
                                                                                    update[index] = {
                                                                                        ...update[index],
                                                                                        ['name']: e.target.value
                                                                                    };
                                                                                    let arrayUpdate = categoryList[0];
                                                                                    let updateArrayList = [...arrayUpdate];
                                                                                    updateArrayList[paginationIndex] = update,
                                                                                        setCategoryList([updateArrayList])
                                                                                }}
                                                                                onKeyPress={async (e) => {
                                                                                    await updateData(data?._id, ({ name: e.target.value }))
                                                                                }}
                                                                            >
                                                                            </textarea>
                                                                        </div>
                                                                        <div style={{ width: '100%', padding: '15px' }}>
                                                                            <textarea className="tb-lead text-center" style={{ width: '100%', height: '100%', border: 'solid #cecece 1px', padding: '15px 10px', borderRadius: '15px' }} placeholder="Enter Title" value={data?.title}
                                                                                onChange={(e) => {
                                                                                    let updateLsit = categoryList[0]?.[paginationIndex];
                                                                                    var update = [...updateLsit];
                                                                                    update[index] = {
                                                                                        ...update[index],
                                                                                        ['title']: e.target.value
                                                                                    };
                                                                                    let arrayUpdate = categoryList[0];
                                                                                    let updateArrayList = [...arrayUpdate];
                                                                                    updateArrayList[paginationIndex] = update,
                                                                                        setCategoryList([updateArrayList])
                                                                                }}
                                                                                onKeyPress={async (e) => {
                                                                                    await updateData(data?._id, ({ title: e.target.value }))
                                                                                }}
                                                                            >

                                                                            </textarea>
                                                                        </div>
                                                                        <div style={{ width: '100%', padding: '15px' }}>
                                                                            <textarea className="tb-lead text-center" style={{ width: '100%', height: '100%', border: 'solid #cecece 1px', padding: '15px 10px', borderRadius: '15px' }} placeholder="Enter Slug" value={data?.slug}
                                                                                onChange={(e) => {
                                                                                    let updateLsit = categoryList[0]?.[paginationIndex];
                                                                                    var update = [...updateLsit];
                                                                                    update[index] = {
                                                                                        ...update[index],
                                                                                        ['slug']: e.target.value
                                                                                    };
                                                                                    let arrayUpdate = categoryList[0];
                                                                                    let updateArrayList = [...arrayUpdate];
                                                                                    updateArrayList[paginationIndex] = update,
                                                                                        setCategoryList([updateArrayList])
                                                                                }}
                                                                                onKeyPress={async (e) => {
                                                                                    await updateData(data?._id, ({ slug: e.target.value }))
                                                                                }}
                                                                            >

                                                                            </textarea>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-mb">
                                                                    <div style={{ width: '100%', padding: '15px' }}>
                                                                        <select className="custom-select uiGP" value={data?.showOnHomepage}
                                                                            onChange={async (e) => {
                                                                                let updateLsit = categoryList[0]?.[paginationIndex];
                                                                                var update = [...updateLsit];
                                                                                update[index] = {
                                                                                    ...update[index],
                                                                                    ['showOnHomepage']: e.target.value
                                                                                };
                                                                                let arrayUpdate = categoryList[0];
                                                                                let updateArrayList = [...arrayUpdate];
                                                                                updateArrayList[paginationIndex] = update,
                                                                                    setCategoryList([updateArrayList])
                                                                                await updateData(data?._id, ({ showOnHomepage: e.target.value }))
                                                                            }}

                                                                        >
                                                                            <option value={''}>Select</option>
                                                                            <option value={true}>Yes</option>
                                                                            <option value={false}>No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div style={{ width: '100%', padding: '15px' }}>
                                                                        <select className="custom-select uiGP" value={data?.priority}
                                                                            onChange={async (e) => {
                                                                                let updateLsit = categoryList[0]?.[paginationIndex];
                                                                                var update = [...updateLsit];
                                                                                update[index] = {
                                                                                    ...update[index],
                                                                                    ['priority']: e.target.value
                                                                                };
                                                                                let arrayUpdate = categoryList[0];
                                                                                let updateArrayList = [...arrayUpdate];
                                                                                updateArrayList[paginationIndex] = update,
                                                                                    setCategoryList([updateArrayList])
                                                                                await updateData(data?._id, ({ priority: e.target.value }))
                                                                            }}
                                                                        >
                                                                            <option value={''}>Select Priority</option>
                                                                            {priorityArray.map((_, i) => {
                                                                                return <option value={i}>{i}</option>
                                                                            })}
                                                                            {/* <option value={0}>0</option>
                                                                            <option value={1}>1</option>
                                                                            <option value={2}>2</option>
                                                                            <option value={3}>3</option>
                                                                            <option value={4}>4</option>
                                                                            <option value={5}>5</option>
                                                                            <option value={6}>6</option>
                                                                            <option value={7}>7</option>
                                                                            <option value={8}>8</option>
                                                                            <option value={9}>9</option>
                                                                            <option value={10}>10</option> */}
                                                                        </select>
                                                                    </div>
                                                                    <div style={{ width: '100%', padding: '15px' }}>
                                                                        <select className="custom-select uiGP" value={data?.parent}
                                                                            onChange={async (e) => {
                                                                                let updateLsit = categoryList[0]?.[paginationIndex];
                                                                                var update = [...updateLsit];
                                                                                update[index] = {
                                                                                    ...update[index],
                                                                                    ['parent']: e.target.value
                                                                                };
                                                                                let arrayUpdate = categoryList[0];
                                                                                let updateArrayList = [...arrayUpdate];
                                                                                updateArrayList[paginationIndex] = update,
                                                                                    setCategoryList([updateArrayList])
                                                                                await updateData(data?._id, ({ parent: e.target.value }))
                                                                            }}
                                                                        >
                                                                            <option value={''}>Select Parent</option>
                                                                            {
                                                                                parentCategory ?
                                                                                    parentCategory?.map((val, index) =>
                                                                                        <option value={val._id} key={index}>{val.name}</option>
                                                                                    )
                                                                                    : null
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className="nk-tb-col tb-col-md">
                                                                    <div style={{ width: '100%', padding: '15px' }}>
                                                                        <textarea className="tb-lead text-center" style={{ width: '100%', height: '100%', border: 'solid #cecece 1px', padding: '15px 10px', borderRadius: '15px' }} placeholder="Enter Description" value={data?.description}
                                                                            onChange={(e) => {
                                                                                let updateLsit = categoryList[0]?.[paginationIndex];
                                                                                var update = [...updateLsit];
                                                                                update[index] = {
                                                                                    ...update[index],
                                                                                    ['description']: e.target.value
                                                                                };
                                                                                let arrayUpdate = categoryList[0];
                                                                                let updateArrayList = [...arrayUpdate];
                                                                                updateArrayList[paginationIndex] = update,
                                                                                    setCategoryList([updateArrayList])
                                                                            }}
                                                                            onKeyPress={async (e) => {
                                                                                await updateData(data?._id, ({ description: e.target.value }))
                                                                            }}
                                                                        >

                                                                        </textarea>
                                                                    </div>

                                                                    <div style={{ width: '100%', padding: '15px' }}>
                                                                        <textarea className="tb-lead text-center" style={{ width: '100%', height: '100%', border: 'solid #cecece 1px', padding: '15px 10px', borderRadius: '15px' }} placeholder="Enter Giphy Search Keywords" value={data?.keywordSearch}
                                                                            onChange={(e) => {
                                                                                let updateLsit = categoryList[0]?.[paginationIndex];
                                                                                var update = [...updateLsit];
                                                                                update[index] = {
                                                                                    ...update[index],
                                                                                    ['keywordSearch']: e.target.value
                                                                                };
                                                                                let arrayUpdate = categoryList[0];
                                                                                let updateArrayList = [...arrayUpdate];
                                                                                updateArrayList[paginationIndex] = update,
                                                                                    setCategoryList([updateArrayList])
                                                                            }}
                                                                            onKeyPress={async (e) => {
                                                                                await updateData(data?._id, ({ keywordSearch: e.target.value }))
                                                                            }}
                                                                        >

                                                                        </textarea>
                                                                    </div>
                                                                    <div style={{ width: '100%', padding: '15px' }}>
                                                                        <textarea className="tb-lead text-center" style={{ width: '100%', height: '100%', border: 'solid #cecece 1px', padding: '15px 10px', borderRadius: '15px' }} placeholder="Enter Meta Title" value={data?.seoHeading}
                                                                            onChange={(e) => {
                                                                                let updateLsit = categoryList[0]?.[paginationIndex];
                                                                                var update = [...updateLsit];
                                                                                update[index] = {
                                                                                    ...update[index],
                                                                                    ['seoHeading']: e.target.value
                                                                                };
                                                                                let arrayUpdate = categoryList[0];
                                                                                let updateArrayList = [...arrayUpdate];
                                                                                updateArrayList[paginationIndex] = update,
                                                                                    setCategoryList([updateArrayList])
                                                                            }}
                                                                            onKeyPress={async (e) => {
                                                                                await updateData(data?._id, ({ seoHeading: e.target.value }))
                                                                            }}
                                                                        >

                                                                        </textarea>
                                                                    </div>
                                                                    <div style={{ width: '100%', padding: '15px' }}>


                                                                        <div class="form-check">

                                                                            <input type='checkbox' name="private" className='form-check-input' checked={data?.private} onChange={async (e) => {
                                                                                let updateLsit = categoryList[0]?.[paginationIndex];
                                                                                var update = [...updateLsit];
                                                                                update[index] = {
                                                                                    ...update[index],
                                                                                    ['private']: e.target.checked
                                                                                };
                                                                                let arrayUpdate = categoryList[0];
                                                                                let updateArrayList = [...arrayUpdate];
                                                                                updateArrayList[paginationIndex] = update,
                                                                                    setCategoryList([updateArrayList])
                                                                                await updateData(data?._id, ({ private: e.target.checked }))
                                                                            }} />
                                                                            <label class="form-check-label" for="private">
                                                                                Private
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    {/*
                                                                    <div style={{ width: '100%', padding: '15px' }}>
                                                                        <textarea className="tb-lead text-center" style={{ width: '100%', height: '100%', border: 'solid #cecece 1px', padding: '15px 10px', borderRadius: '15px' }} placeholder="Enter SEO Description" value={data?.seoDescription}
                                                                            onChange={(e) => {
                                                                                let updateLsit = categoryList[0]?.[paginationIndex];
                                                                                var update = [...updateLsit];
                                                                                update[index] = {
                                                                                    ...update[index],
                                                                                    ['seoDescription']: e.target.value
                                                                                };
                                                                                let arrayUpdate = categoryList[0];
                                                                                let updateArrayList = [...arrayUpdate];
                                                                                updateArrayList[paginationIndex] = update,
                                                                                    setCategoryList([updateArrayList])
                                                                            }}
                                                                            onKeyPress={async (e) => {
                                                                                await updateData(data?._id, ({ seoDescription: e.target.value }))
                                                                            }}
                                                                        >
                                                                        </textarea>
                                                                    </div> */}
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead text-center">{format(new Date((data?.createdAt)), 'Do MMM YYYY')}</span>
                                                                </div>
                                                                <div className="nk-tb-col nk-tb-col-tools">
                                                                    <ul className="nk-tb-actions gx-1">
                                                                        <li>
                                                                            <div className="drodown">
                                                                                <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                                    <ul className="link-list-opt no-bdr">
                                                                                        <li><a onClick={() => editCategory(data)}><em className="icon ni ni-edit"></em><span>Edit</span></a></li>
                                                                                        <li><a onClick={() => popup(data._id, index)}><em className="icon ni ni-trash"></em><span>Delete</span></a></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        )
                                                        :
                                                        <LoadingOverlay className='loaderDiv'>
                                                            <Loader loading={loader} />
                                                        </LoadingOverlay>
                                                }
                                            </div>
                                        </div>
                                        <div className="card-inner">
                                            {categoryList[0]?.length ?
                                                <ReactPaginate
                                                    forcePage={searchPage}
                                                    previousLabel={"Prev"}
                                                    nextLabel={"Next"}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={remainder >= 1 ? divisor + 1 : divisor}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={10}
                                                    onPageChange={(e) => handlePageClick(e)}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"} />
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} closeOnOverlayClick={false}>
                <div className='container-fluid' style={{ width: '100%' }}>
                    <div className='row justify-content-center'>
                        <div className='col-md-12 col-12'>
                            <h3 style={{ textAlign: 'left' }}>{editData ? 'Edit Category' : 'Add Category'}</h3>
                        </div>
                        <div className='col-md-12 col-12' style={{ marginTop: "15px" }}>
                            <div className='row justify-content-center'>
                                <div className="col-lg-12 col-12">
                                    <div className="card card-bordered">
                                        <ul className="data-list is-compact">
                                            <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">Name*</div>
                                                    <input
                                                        type="text"
                                                        className="data-value ip-field"
                                                        value={name}
                                                        name="name"
                                                        placeholder="Category Name"
                                                        onChange={(e) => {
                                                            setName(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </li>
                                            <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">Parent Category</div>
                                                    <select className="custom-select" value={parent} name="parent"
                                                        onChange={(e) => {
                                                            setParent(e.target.value)
                                                        }}>
                                                        <option value=''>Select</option>
                                                        {
                                                            parentCategory ?
                                                                parentCategory?.map((val, index) =>
                                                                    <option value={val._id} key={index}>{val.name}</option>
                                                                )
                                                                : null
                                                        }
                                                    </select>
                                                </div>
                                            </li>
                                            <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">Show on Homepage</div>
                                                    <select className="custom-select" value={showHomePage} name="showOnHomePage"
                                                        onChange={(e) => {
                                                            setShowHomePage(e.target.value == '' ? false : e.target.value == 'false' ? false : e.target.value == 'true' ? true : false)
                                                        }}>
                                                        <option value=''>Select</option>
                                                        <option value={true}>Yes</option>
                                                        <option value={false}>No</option>
                                                    </select>
                                                </div>
                                            </li>
                                            <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">Set Priority</div>
                                                    <select className="custom-select" value={priority} name="showOnHomePage"
                                                        onChange={(e) => {
                                                            setPriority(e.target.value)
                                                        }}>
                                                        <option value={0}>Select</option>
                                                        {priorityArray.map((_, i) => {
                                                            return <option value={i}>{i}</option>
                                                        })}
                                                        {/* {
                                                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((items, index) =>
                                                                <option value={items} key={index}>{items}</option>
                                                            )
                                                        } */}
                                                    </select>
                                                </div>
                                            </li>
                                            <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">How It Work Heading</div>
                                                    <input
                                                        type="text"
                                                        className="data-value ip-field"
                                                        value={howItWorksHeading}
                                                        name="howItworks"
                                                        placeholder="How It Work Heading"
                                                        onChange={(e) => {
                                                            setHowItWorksHeading(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </li>
                                            <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">Giphy Search*</div>
                                                    <input
                                                        type="text"
                                                        className="data-value ip-field"
                                                        value={keywordSearch}
                                                        name="keywordSearch"
                                                        style={{ textTransform: 'capitalize' }}
                                                        placeholder="Keyword Giphy Search.."
                                                        onChange={(e) => {
                                                            setKeywordSearch(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </li>
                                            <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">Title*</div>
                                                    <input
                                                        type="text"
                                                        className="data-value ip-field"
                                                        value={title}
                                                        name="title"
                                                        style={{ textTransform: 'capitalize' }}
                                                        placeholder="Title"
                                                        onChange={(e) => {
                                                            setTitle(e.target.value)
                                                            //  setSlug(slugify(e.target.value))
                                                        }}
                                                    />
                                                </div>
                                            </li>
                                            <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">Slug*</div>
                                                    <input
                                                        type="text"
                                                        className="data-value ip-field"
                                                        value={slug}
                                                        name="slug"
                                                        placeholder="Slug"
                                                        style={{ textTransform: 'lowercase' }}
                                                        onChange={(e) => {
                                                            setSlug((e.target.value).toLowerCase())
                                                        }}
                                                    />
                                                </div>
                                            </li>
                                            <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">Description*</div>
                                                    <textarea className="data-value ip-field" autoComplete='false' placeholder='Description..' name='description' value={description} onChange={(e) => setDescription(e.target.value)} style={{ textTransform: 'none' }}>

                                                    </textarea>
                                                </div>
                                            </li>
                                            <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">Meta Title</div>
                                                    <input
                                                        type="text"
                                                        className="data-value ip-field"
                                                        value={seoHeading}
                                                        name="title"
                                                        style={{ textTransform: 'capitalize' }}
                                                        placeholder="Meta Title"
                                                        onChange={(e) => {
                                                            setSeoHeading(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </li>
                                            {/* <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">SEO Description</div>
                                                    <textarea className="data-value ip-field" autoComplete='false' placeholder='Description..' name='description' value={seoDescription} onChange={(e) => setSeoDescription(e.target.value)} style={{ textTransform: 'none' }}>

                                                    </textarea>
                                                </div>   
                                            </li> */}

                                            <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">Private</div>
                                                    <input
                                                        type="checkbox"
                                                        className="data-value ip-field"
                                                        value={isPrivate}
                                                        name="private"
                                                        style={{ textTransform: 'capitalize' }}
                                                        placeholder="Private"
                                                        onChange={(e) => {
                                                            setIsPrivate(e.target.checked)
                                                        }}
                                                        checked={isPrivate}
                                                    />
                                                </div>
                                            </li>
                                            <li className="data-item" style={{ flexDirection: 'column' }}>
                                                <div className="data-col" style={{ width: '100%' }}>
                                                    <div className="data-label">Dynamic Sections</div>
                                                    <button onClick={(e) => {
                                                        setContentsList([...contentsList, ({
                                                            sectionHeading: '',
                                                            sectionDescription: '',
                                                            viewSample: '',
                                                            isShow: false,
                                                            sectionPointers: []
                                                        })])
                                                    }} className="data-value ip-field" style={{ textTransform: 'none' }}>
                                                        ADD
                                                    </button>
                                                </div>
                                                <ul className="data-list is-compact" style={{ width: '100%' }}>
                                                    {
                                                        contentsList?.length ?
                                                            contentsList?.map((items, index) =>
                                                                <div key={index} style={{ border: 'solid #e6e6e6 1px', margin: '15px 0px' }}>
                                                                    <h2 style={{ padding: '15px', fontSize: '0.8rem', display: 'flex', alignItems: 'center' }}><button onClick={(e) => {
                                                                        contentsList.splice(index, 1);
                                                                        setContentsList([...contentsList]);
                                                                    }} style={{ width: '35px', height: '35px', outline: 'none', border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'transparent' }}><AiFillDelete style={{ width: '25px', height: '25px' }} /></button>{'Dynamic Section: ' + (index + 1)}</h2>




                                                                    <li className="data-item">
                                                                        <div className="data-col">
                                                                            <div className="data-label">Section Heading</div>
                                                                            <input
                                                                                type="text"
                                                                                className="data-value ip-field"
                                                                                name="sectionHeading"
                                                                                style={{ textTransform: 'capitalize' }}
                                                                                placeholder="Section Heading..."
                                                                                value={items?.sectionHeading ? items?.sectionHeading : ''}
                                                                                onChange={(e) => { handleUpdatePointer('sectionHeading', e.target.value, index) }} />
                                                                        </div>
                                                                    </li>
                                                                    <li className="data-item">
                                                                        <div className="data-col">
                                                                            <div className="data-label">Section Description</div>
                                                                            <textarea
                                                                                type="text"
                                                                                className="data-value ip-field"
                                                                                name="sectionDescription"

                                                                                style={{ textTransform: 'capitalize' }}
                                                                                placeholder="Section Description"
                                                                                value={items.sectionDescription ? items?.sectionDescription : ''}
                                                                                onChange={(e) => { handleUpdatePointer('sectionDescription', e.target.value, index) }}></textarea>
                                                                        </div>
                                                                    </li>
                                                                    <li className="data-item">
                                                                        <div className="data-col">
                                                                            <div className="data-label">Sample View Url</div>
                                                                            <input
                                                                                type="text"
                                                                                className="data-value ip-field"
                                                                                name="viewSample"
                                                                                style={{ textTransform: 'capitalize' }}
                                                                                placeholder="Url"
                                                                                value={items?.viewSample ? items?.viewSample : ''}
                                                                                onChange={(e) => { handleUpdatePointer('viewSample', e.target.value, index) }} />
                                                                        </div>
                                                                    </li>
                                                                    <li className="data-item">
                                                                        <div className="data-col">
                                                                            <div className="data-label">Section Show</div>
                                                                            <select
                                                                                type="text"
                                                                                className="data-value ip-field"
                                                                                name="heading"
                                                                                style={{ textTransform: 'capitalize' }}

                                                                                value={items?.isShow ? items?.isShow : false}
                                                                                onChange={(e) => { handleUpdatePointer('isShow', e.target.value, index) }} >

                                                                                <option value={true}>Yes</option>
                                                                                <option value={false}>No</option>
                                                                            </select>
                                                                        </div>
                                                                    </li>
                                                                    <li className="data-item">
                                                                        <div className="data-col">
                                                                            <div className="data-label">Add Sub-Section</div>
                                                                            <input
                                                                                type="button"
                                                                                className="btn btn-md btn-primary"
                                                                                name="sectionPointers"
                                                                                style={{ textTransform: 'capitalize' }}
                                                                                placeholder="Url"
                                                                                value='Add Sub-Section'
                                                                                onClick={(e) => {

                                                                                    handleUpdatePointer('sectionPointers', [...(items?.sectionPointers), (
                                                                                        {
                                                                                            title: '',
                                                                                            description: '',
                                                                                            image: '',
                                                                                            isShow: false,
                                                                                            imgAlt: '',
                                                                                        }
                                                                                    )], index)
                                                                                }} />
                                                                        </div>
                                                                    </li>
                                                                    <li className="data-item">
                                                                        <div className="data-col" style={{ width: '100%', margin: '0px', padding: '15px', display: 'flex', flexDirection: 'column' }}>
                                                                            {
                                                                                items?.sectionPointers?.length ?
                                                                                    items?.sectionPointers?.map((subPointers, subIndex) =>
                                                                                        <div className="row justify-content-center" style={{ width: '100%', margin: '15px 0px' }} key={subIndex}>
                                                                                            <h2 style={{ padding: '15px', fontSize: '0.8rem', display: 'flex', alignItems: 'center' }}><button onClick={(e) => {
                                                                                                items?.sectionPointers.splice(subIndex, 1);
                                                                                                handleUpdatePointer('sectionPointers', [...items?.sectionPointers], index);
                                                                                            }} style={{ width: '35px', height: '35px', outline: 'none', border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'transparent' }}><AiFillDelete style={{ width: '25px', height: '25px' }} /></button>{'Dynamic Sub Section: ' + (subIndex + 1)}</h2>
                                                                                            <li className="data-item">
                                                                                                <div className="data-col">
                                                                                                    <div className="data-label">Section Show</div>
                                                                                                    <select
                                                                                                        type="text"
                                                                                                        className="data-value ip-field"
                                                                                                        name="heading"
                                                                                                        style={{ textTransform: 'capitalize' }}

                                                                                                        value={subPointers?.isShow ? subPointers?.isShow : false}
                                                                                                        onChange={(e) => { handleUpdatePointerSub(items?.sectionPointers, 'isShow', e.target.value, index, subIndex) }} >

                                                                                                        <option value={true}>Yes</option>
                                                                                                        <option value={false}>No</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className="data-item">
                                                                                                <div className="data-col">
                                                                                                    <div className="data-label">Heading</div>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        className="data-value ip-field"
                                                                                                        name="heading"
                                                                                                        style={{ textTransform: 'capitalize' }}
                                                                                                        placeholder="Heading..."
                                                                                                        value={subPointers?.title ? subPointers?.title : ''}
                                                                                                        onChange={(e) => { handleUpdatePointerSub(items?.sectionPointers, 'title', e.target.value, index, subIndex) }} />
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className="data-item">
                                                                                                <div className="data-col">
                                                                                                    <div className="data-label">Image Alt</div>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        className="data-value ip-field"
                                                                                                        name="imageAlt"
                                                                                                        style={{ textTransform: 'capitalize' }}
                                                                                                        placeholder="Alt..."
                                                                                                        value={subPointers?.imgAlt ? subPointers?.imgAlt : ''}
                                                                                                        onChange={(e) => { handleUpdatePointerSub(items?.sectionPointers, 'imgAlt', e.target.value, index, subIndex) }} />
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className="data-item">
                                                                                                <div className="data-col">
                                                                                                    <div className="data-label">Description</div>
                                                                                                    <textarea
                                                                                                        type="text"
                                                                                                        className="data-value ip-field"
                                                                                                        name="description"

                                                                                                        style={{ textTransform: 'capitalize' }}
                                                                                                        placeholder="Description"
                                                                                                        value={subPointers?.description ? subPointers?.description : ''}
                                                                                                        onChange={(e) => { handleUpdatePointerSub(items?.sectionPointers, 'description', e.target.value, index, subIndex) }}></textarea>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className="data-item">
                                                                                                <div className="data-col">
                                                                                                    <div className="data-label">Image</div>
                                                                                                    {
                                                                                                        subPointers?.image ?
                                                                                                            <div className="row" style={{ width: '100%', textAlign: 'center' }}>
                                                                                                                <img src={process.env.REACT_APP_BASE_IMAGE_URL + subPointers?.image} className='img-fluid' style={{ width: '100%' }} />
                                                                                                                <button className="btn btn-md btn-secondary" onClick={(e) => { handleUpdatePointerSub(items?.sectionPointers, 'image', '', index, subIndex) }} style={{ margin: '15px 0px', textAlign: 'center' }}>Remove Image</button>
                                                                                                            </div>
                                                                                                            : null
                                                                                                    }
                                                                                                    <input
                                                                                                        type="file"
                                                                                                        className="data-value ip-field"
                                                                                                        name="files"
                                                                                                        style={{ textTransform: 'capitalize' }}


                                                                                                        onChange={async (e) => {
                                                                                                            var file = e.target.files[0];
                                                                                                            const formData = new FormData();
                                                                                                            formData.append('file', file, file.name);
                                                                                                            setLoader(true);

                                                                                                            await putMethod('index/aws-assets', formData).then((response) => {
                                                                                                                if (response.success) {
                                                                                                                    handleUpdatePointerSub(items?.sectionPointers, 'image', response.data.key, index, subIndex)
                                                                                                                    setLoader(false);
                                                                                                                    toastComp(addToast, 'success', response.message);
                                                                                                                } else {
                                                                                                                    setLoader(false);
                                                                                                                    toastComp(addToast, 'error', response.message);
                                                                                                                }

                                                                                                            })
                                                                                                        }} />
                                                                                                </div>
                                                                                            </li>
                                                                                            {subPointers?.image ?
                                                                                                <li className="data-item">
                                                                                                    <div className="data-col">
                                                                                                        <div className="data-label">Use image for page preview</div>
                                                                                                        {/* <button className="btn btn-md btn-primary" 
                                                                                                            onClick={(e) => { handleUpdatePointerSub(items?.sectionPointers, 'isImageMetaPreview', !(subPointers?.isImageMetaPreview) , index, subIndex) }}
                                                                                                        >{subPointers?.isImageMetaPreview ? "Remove Preview" : "Make Preview"}</button> */}

                                                                                                        <ToggleButton
                                                                                                            type="radio"
                                                                                                            onChange={(e) => { handleUpdatePointerSub(items?.sectionPointers, 'isImageMetaPreview', !(subPointers?.isImageMetaPreview), index, subIndex) }}
                                                                                                            variant={subPointers?.isImageMetaPreview ? "danger" : "primary"}
                                                                                                            className="previewToggle"
                                                                                                        >{subPointers?.isImageMetaPreview ? "NO" : "YES"}</ToggleButton>
                                                                                                    </div>
                                                                                                </li> : ""
                                                                                            }


                                                                                        </div>

                                                                                    )
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </li>







                                                                </div>

                                                            )
                                                            : null
                                                    }
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <div className="my-3 text-center">
                                        <div className="d-flex justify-content-center">
                                            <button
                                                className="btn btn-md btn-primary"
                                                onClick={() => { editData ? handleEditCategory(editId) : handleAddCategory() }}
                                            >
                                                {editData ? "Edit Category" : "Add Category"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default CategoryList;