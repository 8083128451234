import Axios from 'axios';
import { getCookies } from './Cookies';
const WEB_URL = process.env.REACT_APP_WEB_URL;
const MAIN_API_URL = process.env.REACT_APP_MAIN_API_URL;
const BASE_IMAGE_URL = process.env.REACT_APP_BASE_IMAGE_URL;
const BaseGIPHY_APPID = process.env.REACT_APP_BASE_GIFHY_KEY;
const BaseSticker_APPID = process.env.REACT_APP_MAIN_BASE_STICKER_KEY;
const Base_EditorId = process.env.REACT_APP_EDITOR_ID;
const AxiosInstance = Axios.create({
  baseURL: MAIN_API_URL,
  timeout: 30000,
  headers: {},
});
const setAuthorizationToken = (token) => {
  getCookies('_gp_admin_token') ?
    AxiosInstance.defaults.headers.common.Authorization = token ? `Bearer ${token}` : `Bearer ${getCookies('_gp_admin_token')}`
    :
    AxiosInstance.defaults.headers.common.Authorization = ""
};
const postMethod = async (endpoint, data) => {
  return new Promise(resolve => {
    var config = {
      method: 'post',
      url: endpoint,
      data: data
    };
    AxiosInstance(config).then(response => {
      resolve(response.data);
    }, error => {
      resolve(error.response.data);
    })
  });
}
const getMethod = async (path, params = {}) => {
  return new Promise(resolve => {
    var config = {
      method: 'get',
      url: path,
      params: params
    };
    AxiosInstance(config).then(response => {
      resolve(response.data)
    }, error => {
      if (!error.response || error.code === 'ECONNABORTED') {
        resolve({ status: false, message: 'error!' })
      } else {
        resolve(error.response.data)
      }
    })
  })
}

async function deleteMethod(path) {
  return new Promise(resolve => {
    var config = {
      method: 'delete',
      url: path,
    };
    AxiosInstance(config).then(response => {
      resolve(response.data)
    }, error => {
      resolve(error.response.data)
    })
  })
}
async function putMethod(endpoint, data, params = {}) {
  return new Promise(resolve => {
    var config = {
      method: 'put',
      url: endpoint,
      data: data,
      params: params
    };
    //console.log('Data L ' + JSON.stringify(config))
    AxiosInstance(config).then(response => {
      resolve(response.data)
    }, error => {
      resolve(error.response.data)
    })
  })
}
async function putMethod2(endpoint, data, params = {}) {
  return new Promise(resolve => {
    var config = {
      method: 'put',
      url: endpoint,
      data: data,
      // params: params
    };
    //console.log('Data L ' + JSON.stringify(config))
    AxiosInstance(config).then(response => {
      resolve(response.data)
    }, error => {
      resolve(error.response.data)
    })
  })
}

async function gifyGetApi(searchValue) {
  let searchValueNow = searchValue?.search ? searchValue?.search : '';
  return new Promise(resolve => {
    var config = {
      method: 'get',
      url: BaseGIPHY_APPID + '&q=' + searchValueNow + '&limit=' + searchValue.limit + '&offset=0&rating=g&lang=en',
      headers: {}
    };
    Axios(config).then(response => {
      if (response.data) {
        resolve(response.data);
      }
    }, error => {
      //console.log(error.response)
    });
  });
}
async function stickerGetApi(searchValue) {
  let searchValueNow = searchValue?.search ? searchValue?.search : '';
  return new Promise(resolve => {
    var config = {
      method: 'get',
      url: BaseSticker_APPID + '&q=' + searchValueNow + '&limit=' + searchValue.limit + '&offset=0&rating=g&lang=en',
      headers: {}
    };
    Axios(config).then(response => {
      if (response.data) {
        resolve(response.data);
      }
    }, error => {
      //console.log(error.response)
    });
  });
}
export { WEB_URL, Base_EditorId, MAIN_API_URL, BASE_IMAGE_URL, gifyGetApi,putMethod2, stickerGetApi, setAuthorizationToken, postMethod, getMethod, deleteMethod, putMethod };