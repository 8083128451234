
import React, { useEffect, useState,useRef } from "react";
import { bulktabTemplateApi } from "../Action/PostAction";
// admin/getUpload


// post ki api 
const Bulktabtemplate = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState(null);
    const [excelfile, setexcelFile] = useState(null);
    const [excelfileerror, setexcelFileerror] = useState(null);
    const fileInputRef = useRef(null);
    const fileType = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
    const handlechange = (e) => {
      
        let selectedFiles;

        if (e.target.files) {
            selectedFiles = e.target.files[0];
            setSelectedFile(selectedFiles)
            console.log(selectedFiles)
        } else if (e.dataTransfer.items) {
            // Check if it's a file dropped event
            for (let i = 0; i < e.dataTransfer.items.length; i++) {
                const item = e.dataTransfer.items[i];
                // console.log(item,'dropped item')

                selectedFiles = item.getAsFile();
                setSelectedFile(selectedFiles)
                break;

            }
        }
   
        if (selectedFiles) {
            setSelectedFileName(selectedFiles.name);
        }

        if (selectedFiles && fileType.includes(selectedFiles.type)) {
            let reader = new FileReader();
            reader.readAsArrayBuffer(selectedFiles);
            reader.onload = (e) => {
                setexcelFileerror(null);
                setexcelFile(e.target.result);
            };
        } else {
            setexcelFileerror('Please select an Excel file');
        }


        // setTimeout(() => {

        //     document.querySelector('.bulkform button').click();
          
        // }, 1000);
    }
    const handleDrop = (e) => {
        e.preventDefault();
        handlechange(e);
        if (fileInputRef.current) {
            fileInputRef.current.files = e.dataTransfer.files;
        }
    };
    console.log(excelfile)

    const upload=async ()=>{
        console.log(selectedFile)
        const formData = new FormData()
        formData.append('xlsxfile',selectedFile)
        await bulktabTemplateApi(formData).then(async (response) => {
            if (response.success) {
        
              toastComp(addToast, 'success', 'Template Added Successfully!')
            } else {
              setLoader(false)
              toastComp(addToast, 'error', response.message)
            }
          })

    }
    return (
        <>
            <div
                className="nk-content "

            >
                <div className="container-fluid pt-3 px-5">
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between g-3">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">UPlOAD DEMO BULKTAB TEMPLATE</h3>
                            </div>
                        
                         
                        </div>
                    </div>
                    <div className="nk-block" style={{paddingTop:'20px'}}          onDragOver={(e) => {
                                            e.preventDefault();
                                            //   console.log('drageed file')
                                            handlechange(e);
                                            if (fileInputRef.current) {
                                                fileInputRef.current.files = e.dataTransfer.files;
                                            }
                                        }}
                                        onDrop={handleDrop}>
                    <div class="row gy-5" style={{ border: '3px dashed #6c757d', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '10px', paadingtop: '20px', paddingBottom: '20px', }}>
                        <form className='bulkform' style={{ marginTop: '-10px' }}>
                            <label htmlFor="f" style={{ marginTop: '40px', textAlign: 'center', cursor: 'pointer',width:'100%' }}>
                                <span className="material-symbols-outlined icon" id="icon" >
                                {excelfile ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="50" height="60" fill="#6576ff">
                                                            <path d="M15 0H3a2.006 2.006 0 0 0-2 2v20a2.006 2.006 0 0 0 2 2h18a2.006 2.006 0 0 0 2-2V5l-7-5zm-1.414 1L16 4.414V1.586zM18 20H4V2h9v5h5v13zm-7-9h2v2h-2zm4 0h2v2h-2zm-4 3h2v2h-2zm4 0h2v2h-2z" />
                                                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="50" height="60" fill="#6576ff">
                                                            <path d="M544 864V672h128L512 480 352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.808 239.808 0 0 1 512 192a239.872 239.872 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6H544z" />
                                                        </svg>}
                                </span>
                                <span style={{ lineHeight: '30px', paadingtop: '2px' }}> <h5>
                                    {excelfile ? selectedFileName + '  File is uploaded' : 'Drag file here or browse for file upload import'}

                                </h5></span>
                            </label>
                            {/* onChange={handlechange} ref={fileInputRef}  */}
                            <input type="file" id="f" onChange={handlechange} ref={fileInputRef}  style={{ display: 'none' }} accept='.xlsx' />
                       
                        </form>
                   
                    </div>
                    <div class="my-3"><div class="d-flex justify-content-center" onClick={upload} style={{justifyContent:'center !important',paddingTop:"20px"}}><button class="btn btn-md btn-primary">Save</button></div></div>
                    </div>
                </div>





            </div>

        </>
    );
};
export default Bulktabtemplate;