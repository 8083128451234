import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { getAllOrder, getdashboardTop, latestUserFilter } from '../Action/GetAction';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { dateFormatConverter } from "../Logic/Logic";
import { DefinedRange } from 'react-date-range';
import { format } from 'fecha';
import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { BASE_IMAGE_URL } from "../Utils/ApiServices";
import { getCookies } from "../Utils/Cookies";
const Dashboard = () => {
    const history = useHistory();
    const [token, setToken]  = useState('');
    const [filterToggle, setFilterToggle] = useState(false);
    const [stateFilters, setDateFilters] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 1),
            key: 'selection'
        }
    ]);
    const [loader1, setLoader1] = useState(true);
    const [latestUserArray, setLatestUserArray] = useState([]);
    const [orderLoader, setOrderLoader] = useState(true);
    const [orderList, orderGetList] = useState([]);
    const [ordersToggleStatus, setOrderToggleStatus] = useState(0);
    const [dashboardOverview, setOverview] = useState(false);

    const orderDetailsPreview = async (finalData) => {
        setOrderLoader(true);
        orderGetList([])
        var pageData = { page: 0, limit: 20, startDate: finalData?.startDate, endDate: finalData?.endDate };
        await getAllOrder('admin/order?page=0&limit=50&startDate=' + finalData?.startDate + '&endDate=' + finalData?.endDate).then(response => {
            if (response?.success) {
                setOrderLoader(false);
                orderGetList(response?.data);
            } else {
                setOrderLoader(false)
            }
        })
    }
    async function callAPI(value) {
        setLoader1(true)
        const finalData = ({ startDate: value?.startDate, endDate: value?.endDate })
        const userDetails = await latestUserFilter(finalData)
        if (userDetails?.success && userDetails?.data?.length) {
            setLoader1(false)
            setLatestUserArray(userDetails?.data)
        } else {
            setLoader1(false)
            setLatestUserArray([])
        }
        orderDetailsPreview(finalData)
    }
    const filtersDate = async (data) => {
        setDateFilters(data);
        const passData = ({ startDate: JSON.stringify(new Date(data[0]?.startDate)).slice(1, 11), endDate: JSON.stringify(new Date(data[0]?.endDate)).slice(1, 11) })
        callAPI(passData)
    }
    useEffect(async () => {
        await getdashboardTop().then(async response => {
            if (response.success) {
                await setOverview(response.data)
            }
        })

        filtersDate(stateFilters)

        setToken(getCookies('_gp_admin_token'));
    }, [])
    return (
        <div className="nk-content">
            <div className="container-fluid">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between">
                                <div className="nk-block-head-content">
                                    <h3 className="nk-block-title page-title">Dashboard</h3>
                                </div>
                                <div className="nk-block-head-content">
                                    <div className={filterToggle ? 'row ctaActive' : 'row cta'} style={{ border: 'solid transparent 1px', position: 'fixed', right: '0px', zIndex: 99, top: '10%', display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'start', flexWrap: 'nowrap' }}>
                                        <button className='togglebff' onClick={(e) => setFilterToggle(filterToggle ? false : true)} style={{ width: '50px', height: '50px', backgroundColor: '#526484', color: '#fff', border: 'solid #526484 1px', outline: 'none', textAlign: 'center', alignItems: 'center', fontSize: '18px' }}><em className="icon ni ni-filter"></em></button>
                                        <DefinedRange
                                            date={new Date()}
                                            onChange={(item) => { filtersDate([item.selection]); }}
                                            ranges={stateFilters}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nk-block">
                            <div className="row g-gs">
                                <div className="col-xxl-5 fullWidth">
                                    <div className="row g-gs" style={{ width: '100%', margin: '0px' }}>
                                        <div className="col-xxl-3 col-lg-3 col-md-6 col-12">
                                            <div className="card card-bordered">
                                                <div className="card-inner">
                                                    <div className="card-title-group align-start mb-2">
                                                        <div className="card-title">
                                                            <h6 className="title">Total Earnings</h6>
                                                        </div>
                                                    </div>
                                                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                        <div className="nk-sale-data">
                                                            <span className="amount">{dashboardOverview ? 'CAD ' + dashboardOverview.sales : 'CAD ' + 0}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-lg-3 col-md-6 col-12">
                                            <div className="card card-bordered">
                                                <div className="card-inner">
                                                    <div className="card-title-group align-start mb-2">
                                                        <div className="card-title">
                                                            <h6 className="title">Total Orders</h6>
                                                        </div>
                                                    </div>
                                                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                        <div className="nk-sale-data">
                                                            <span className="amount">{dashboardOverview ? dashboardOverview.orders : 0}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-lg-3 col-md-6 col-12">
                                            <div className="card card-bordered">
                                                <div className="card-inner">
                                                    <div className="card-title-group align-start mb-2">
                                                        <div className="card-title">
                                                            <h6 className="title">Total Users</h6>
                                                        </div>
                                                    </div>
                                                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                        <div className="nk-sale-data">
                                                            <span className="amount">{dashboardOverview ? dashboardOverview.users : 0}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-lg-3 col-md-6 col-12">
                                            <div className="card card-bordered">
                                                <div className="card-inner">
                                                    <div className="card-title-group align-start mb-2">
                                                        <div className="card-title">
                                                            <h6 className="title">Total Templates</h6>
                                                        </div>
                                                    </div>
                                                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                        <div className="nk-sale-data">
                                                            <span className="amount">{dashboardOverview ? dashboardOverview.cards : 0}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row g-gs' style={{ width: '100%', margin: '0px' }}>
                                        <div className="col-xxl-12">
                                            <div className="card card-bordered card-full">
                                                <div className="card-inner">
                                                    <div className="card-title-group">
                                                        <div className="card-title">
                                                            <h6 className="title"><span className="mr-2">Latest Orders ({orderList?.length ? orderList?.length : 0})</span> <a href="/orders" className="link d-none d-sm-inline">See History</a></h6>
                                                        </div>
                                                        <div className="card-tools">
                                                            <ul className="card-tools-nav">
                                                                <li className={ordersToggleStatus === 2 ? 'active' : ''}><a onClick={() => setOrderToggleStatus(2)}><span>Paid</span></a></li>
                                                                <li className={ordersToggleStatus === 1 ? 'active' : ''}><a onClick={() => setOrderToggleStatus(1)}><span>Pending</span></a></li>
                                                                <li className={ordersToggleStatus === 0 ? 'active' : ''}><a onClick={() => setOrderToggleStatus(0)}><span>All</span></a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-inner p-0 border-top" style={{ overflowY: 'scroll', height: '350px' }}>
                                                    <div className="nk-tb-list nk-tb-orders">
                                                        <div className="nk-tb-item nk-tb-head">
                                                            <div className="nk-tb-col"><span>Order No.</span></div>
                                                            <div className="nk-tb-col"><span>Sender Name</span></div>
                                                            <div className="nk-tb-col tb-col-md"><span>Created Date</span></div>
                                                            <div className="nk-tb-col tb-col-md"><span>Delivered Date</span></div>
                                                            <div className="nk-tb-col tb-col-lg"><span>Recipient Name</span></div>
                                                            <div className="nk-tb-col tb-col-lg"><span>Recipient Email</span></div>
                                                            <div className="nk-tb-col tb-col-lg"><span>Amount</span></div>
                                                            <div className="nk-tb-col tb-col-lg"><span className="d-none d-sm-inline">Status</span></div>
                                                            <div className="nk-tb-col nk-tb-col-tools text-right"><span>More</span></div>
                                                        </div>
                                                        {
                                                            orderLoader ?
                                                                <LoadingOverlay className='loaderDiv'>
                                                                    <Loader loading={orderLoader} />
                                                                </LoadingOverlay>
                                                                : null
                                                        }
                                                        {
                                                            orderList?.length > 0 ?
                                                                orderList?.map((orders, index) =>
                                                                    ordersToggleStatus === 0 ?
                                                                        <div className="nk-tb-item" key={index}>
                                                                            <div className="nk-tb-col">
                                                                                <span className="tb-lead"><Link to={'/view-order?order=' + orders?.canvasId}>{orders?._id ? '#' + (orders?.canvasId).substr(parseInt(orders?.canvasId.length - 5), orders?.canvasId.length) : ''}</Link></span>
                                                                            </div>
                                                                            <div className="nk-tb-col">
                                                                                <div className="user-card">
                                                                                    <div className="user-name">
                                                                                        <span className="tb-lead">{orders?.sendersName ? orders.sendersName : '--'}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="nk-tb-col tb-col-md">
                                                                                <span className="tb-sub">{format(new Date((orders?.createdAt)), 'Do MMM YYYY, HH:mm A')}</span>
                                                                            </div>
                                                                            <div className="nk-tb-col tb-col-md">
                                                                                <span className="tb-sub">{orders?.deliveryDate ? format(new Date((orders?.deliveryDate)), 'Do MMM YYYY, ') + orders?.deliveryTime : '----'}</span>
                                                                            </div>
                                                                            <div className="nk-tb-col tb-col-lg">
                                                                                <span className="tb-sub text-primary" style={{ textTransform: 'capitalize' }}>{orders?.recipient ? orders?.recipient.name : '---'}</span>
                                                                            </div>
                                                                            <div className="nk-tb-col tb-col-lg">
                                                                                <span className="tb-sub text-primary">{orders?.recipient ? orders?.recipient.email : '---'}</span>
                                                                            </div>
                                                                            <div className="nk-tb-col tb-col-lg">
                                                                                <span className="tb-sub tb-amount">{orders?.productType ? orders?.productType.price  : "NA"} {orders?.productType.price ? <span> CAD</span>:""}</span>
                                                                            </div>
                                                                            <div className="nk-tb-col tb-col-lg">
                                                                                {
                                                                                    orders.status === 'start' || orders.status === 'recipientassigned' || orders.status === 'pending' ?
                                                                                        <span className="badge badge-dot badge-dot-xs badge-danger" style={{ textTransform: 'capitalize' }}>{orders.status == 'start' ? 'Template Selected' : orders.status == 'recipientassigned' ? 'Payment Pending' : orders.status == 'pending' ? 'Schedule Pending' : orders.status == 'paid' ? 'Paid' : orders.status == 'scheduled' ? 'Card Scheduled' : orders.status == 'complete' ? 'Completed' : orders?.status}</span>
                                                                                        :
                                                                                        <span className="badge badge-dot badge-dot-xs badge-success" style={{ textTransform: 'capitalize' }}>{orders.status == 'start' ? 'Template Selected' : orders.status == 'recipientassigned' ? 'Payment Pending' : orders.status == 'pending' ? 'Schedule Pending' : orders.status == 'paid' ? 'Paid' : orders.status == 'scheduled' ? 'Card Scheduled' : orders.status == 'complete' ? 'Completed' : orders?.status}</span>
                                                                                }
                                                                            </div>
                                                                            <div className="nk-tb-col nk-tb-col-action">
                                                                                <div className="dropdown">
                                                                                    <a className="text-soft dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                                                                                        <ul className="link-list-plain">
                                                                                            <li><Link to={'/view-order?order=' + orders?.canvasId}>View Order</Link></li>
                                                                                            {/* <li><a href={process.env.REACT_APP_WEB_URL+'admin-edit?token='+token+'&card=' + orders?.canvasId} target={'_blank'}>Edit Card</a></li> */}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        :
                                                                        ordersToggleStatus === 1 ?   //pending
                                                                            orders.status === 'start' || orders.status === 'recipientassigned' || orders.status === 'pending' ?
                                                                                <div className="nk-tb-item" key={index}>
                                                                                    <div className="nk-tb-col">
                                                                                        <span className="tb-lead"><Link to={'/view-order?order=' + orders?.canvasId}>{orders?._id ? '#' + (orders?.canvasId).substr(parseInt(orders?.canvasId.length - 5), orders?.canvasId.length) : ''}</Link></span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col">
                                                                                        <div className="user-card">
                                                                                            <div className="user-name">
                                                                                                <span className="tb-lead">{orders?.sendersName ? orders.sendersName : '--'}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="nk-tb-col tb-col-md">
                                                                                        <span className="tb-sub">{format(new Date((orders?.createdAt)), 'Do MMM YYYY, HH:mm A')}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col tb-col-md">
                                                                                        <span className="tb-sub">{orders?.deliveryDate ? format(new Date((orders?.deliveryDate)), 'Do MMM YYYY, ') + orders?.deliveryTime : '----'}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="tb-sub text-primary" style={{ textTransform: 'capitalize' }}>{orders?.recipient ? orders?.recipient.name : '---'}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="tb-sub text-primary">{orders?.recipient ? orders?.recipient.email : '---'}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="tb-sub tb-amount">{orders?.payment ? orders?.payment.amount  : "NA"} {orders?.payment.amount ? <span> CAD</span>:""}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        {
                                                                                            orders.status === 'start' || orders.status === 'recipientassigned' || orders.status === 'pending' ?
                                                                                                <span className="badge badge-dot badge-dot-xs badge-danger" style={{ textTransform: 'capitalize' }}>{orders.status == 'start' ? 'Template Selected' : orders.status == 'recipientassigned' ? 'Payment Pending' : orders.status == 'pending' ? 'Schedule Pending' : orders.status == 'paid' ? 'Paid' : orders.status == 'scheduled' ? 'Card Scheduled' : orders.status == 'complete' ? 'Completed' : orders?.status}</span>
                                                                                                :
                                                                                                <span className="badge badge-dot badge-dot-xs badge-success" style={{ textTransform: 'capitalize' }}>{orders.status == 'start' ? 'Template Selected' : orders.status == 'recipientassigned' ? 'Payment Pending' : orders.status == 'pending' ? 'Schedule Pending' : orders.status == 'paid' ? 'Paid' : orders.status == 'scheduled' ? 'Card Scheduled' : orders.status == 'complete' ? 'Completed' : orders?.status}</span>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="nk-tb-col nk-tb-col-action">
                                                                                        <div className="dropdown">
                                                                                            <a className="text-soft dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                                                                                                <ul className="link-list-plain">
                                                                                                    <li><Link to={'/view-order?order=' + orders?.canvasId}>View Order</Link></li>
                                                                                                    {/* <li><a href={process.env.REACT_APP_WEB_URL+'admin-edit?token='+token+'&card=' + orders?.canvasId} target={'_blank'}>Edit Card</a></li> */}
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                : null
                                                                            :
                                                                            ordersToggleStatus === 2 ?  // paid
                                                                                orders.status === 'paid' || orders.status === 'scheduled' || orders.status === 'complete' ?
                                                                                    <div className="nk-tb-item" key={index}>
                                                                                        <div className="nk-tb-col">
                                                                                            <span className="tb-lead"><Link to={'/view-order?order=' + orders?.canvasId}>{orders?._id ? '#' + (orders?.canvasId).substr(parseInt(orders?.canvasId.length - 5), orders?.canvasId.length) : ''}</Link></span>
                                                                                        </div>
                                                                                        <div className="nk-tb-col">
                                                                                            <div className="user-card">
                                                                                                <div className="user-name">
                                                                                                    <span className="tb-lead">{orders?.sendersName ? orders.sendersName : '--'}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="nk-tb-col tb-col-md">
                                                                                            <span className="tb-sub">{format(new Date((orders?.createdAt)), 'Do MMM YYYY, HH:mm A')}</span>
                                                                                        </div>
                                                                                        <div className="nk-tb-col tb-col-md">
                                                                                            <span className="tb-sub">{orders?.deliveryDate ? format(new Date((orders?.deliveryDate)), 'Do MMM YYYY, ') + orders?.deliveryTime : '----'}</span>
                                                                                        </div>
                                                                                        <div className="nk-tb-col tb-col-lg">
                                                                                            <span className="tb-sub text-primary" style={{ textTransform: 'capitalize' }}>{orders?.recipient ? orders?.recipient.name : '---'}</span>
                                                                                        </div>
                                                                                        <div className="nk-tb-col tb-col-lg">
                                                                                            <span className="tb-sub text-primary">{orders?.recipient ? orders?.recipient.email : '---'}</span>
                                                                                        </div>
                                                                                        <div className="nk-tb-col tb-col-lg">
                                                                                            <span className="tb-sub tb-amount">{orders?.payment ? orders?.payment.amount  : "NA"} {orders?.payment.amount ? <span> CAD</span>:""}</span>
                                                                                        </div>
                                                                                        <div className="nk-tb-col tb-col-lg">
                                                                                            {
                                                                                                orders.status === 'start' || orders.status === 'recipientassigned' || orders.status === 'pending' ?
                                                                                                    <span className="badge badge-dot badge-dot-xs badge-danger" style={{ textTransform: 'capitalize' }}>{orders.status == 'start' ? 'Template Selected' : orders.status == 'recipientassigned' ? 'Payment Pending' : orders.status == 'pending' ? 'Schedule Pending' : orders.status == 'paid' ? 'Paid' : orders.status == 'scheduled' ? 'Card Scheduled' : orders.status == 'complete' ? 'Completed' : orders?.status}</span>
                                                                                                    :
                                                                                                    <span className="badge badge-dot badge-dot-xs badge-success" style={{ textTransform: 'capitalize' }}>{orders.status == 'start' ? 'Template Selected' : orders.status == 'recipientassigned' ? 'Payment Pending' : orders.status == 'pending' ? 'Schedule Pending' : orders.status == 'paid' ? 'Paid' : orders.status == 'scheduled' ? 'Card Scheduled' : orders.status == 'complete' ? 'Completed' : orders?.status}</span>
                                                                                            }
                                                                                        </div>
                                                                                        <div className="nk-tb-col nk-tb-col-action">
                                                                                            <div className="dropdown">
                                                                                                <a className="text-soft dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                                                                                                    <ul className="link-list-plain">
                                                                                                        <li><Link to={'/view-order?order=' + orders?.canvasId}>View Order</Link></li>
                                                                                                        {/* <li><a href={process.env.REACT_APP_WEB_URL+'admin-edit?token='+token+'&card=' + orders?.canvasId} target={'_blank'}>Edit Card</a></li> */}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                                : null
                                                                )
                                                                :
                                                                <div className="card-inner card-inner-md" style={{ transform: 'translate(150%,0%)' }}>
                                                                    <div className="user-card" style={{ textAlign: 'center', justifyContent: 'center' }}>
                                                                        <div className="user-info">
                                                                            <span className="lead-text">Nothing Found!</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="card-inner-sm border-top text-center d-sm-none">
                                                    <a href="/admin-orders" className="btn btn-link btn-block">See History</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row g-gs' style={{ width: '100%', margin: '0px' }}>
                                        <div className="col-md-6 col-xxl-4">
                                            <div className="card card-bordered card-full" style={{ height: 'auto' }}>
                                                <div className="card-inner-group">
                                                    <div className="card-inner">
                                                        <div className="card-title-group">
                                                            <div className="card-title">
                                                                <h6 className="title">Latest Users({latestUserArray?.length ? latestUserArray?.length : 0})</h6>
                                                            </div>
                                                            {
                                                                latestUserArray?.length ?
                                                                    <div className="card-tools">
                                                                        <a href="/user-list" className="link">View All</a>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-inner-group" style={{ height: '500px', overflowY: 'auto' }}>
                                                    {
                                                        loader1 ?
                                                            <LoadingOverlay className='loaderDiv'>
                                                                <Loader loading={loader1} />
                                                            </LoadingOverlay>
                                                            : null
                                                    }
                                                    {
                                                        latestUserArray?.length > 0 ?
                                                            latestUserArray?.map((users, index) =>
                                                                10 > index ?
                                                                    <Link to={'/user-details?id=' + users?._id} key={index}>
                                                                        <div className="card-inner card-inner-md" id={users?._id}>
                                                                            <div className="user-card">
                                                                                <div className="user-avatar bg-success-dim">
                                                                                    {
                                                                                        users?.profilePicture ?
                                                                                            <img src={BASE_IMAGE_URL + users?.profilePicture} />
                                                                                            :
                                                                                            <span style={{ textTransform: 'uppercase' }}>{users?.firstName ? users?.firstName[0] + '' + users?.lastName[0] : 'UU'}</span>
                                                                                    }
                                                                                </div>
                                                                                <div className="user-info">
                                                                                    <span className="lead-text" style={{ textTransform: 'capitalize' }}>{users?.firstName + ' ' + users?.lastName}</span>
                                                                                    <span className="sub-text">{users?.email}</span>
                                                                                </div>
                                                                                <div className="user-action">
                                                                                    <div className="nk-tb-col">
                                                                                        {
                                                                                            users?.active ?
                                                                                                <span className="badge badge-dot badge-dot-xs badge-success">Active</span>
                                                                                                :
                                                                                                <span className="badge badge-dot badge-dot-xs badge-danger">Deactive</span>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                    : null
                                                            )
                                                            :
                                                            <div className="card-inner card-inner-md">
                                                                <div className="user-card" style={{ textAlign: 'center', justifyContent: 'center' }}>
                                                                    <div className="user-info">
                                                                        <span className="lead-text">Nothing Found!</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Dashboard;