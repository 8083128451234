import React, { useEffect, useState } from "react";
import { useToasts } from 'react-toast-notifications';
import { toastComp } from "../Sections/Toast";
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { Modal } from "react-responsive-modal";
import { confirmAlert } from 'react-confirm-alert';
import ReactPaginate from 'react-paginate';
import { dateFormatConverter } from '../Logic/Logic';
import { format } from 'fecha';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-overlay-loader/styles.css';
import "react-responsive-modal/styles.css";
import { BASE_IMAGE_URL, deleteMethod, getMethod, postMethod, putMethod} from "../Utils/ApiServices";
const Testimonials = () => {
    const { addToast } = useToasts();
    const [toggleSearchBar, setToggleSearchBar] = useState(true);
    const [total, setTotal] = useState(0);
    const [listVar, setListVar] = useState([]);
    const [loader, setLoader] = useState(true);
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editId, setEditId] = useState(false);
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [profile, setProfile] = useState('');
    const [rate, setRate] = useState(0);
    const [designation, setdesignation] = useState('');
    const getTestinomial = async () => {
        await getMethod('testnomail').then(response => {
            if (response.success) {
                setLoader(false);
                onCloseModal();
                setListVar([response.data]);
                setTotal(response.data.length);
            } else {
                setLoader(false);
            }
        });
    }
    const deleteAPI=async(id, index)=>{
        setLoader(true);
        await deleteMethod('testnomail/'+id).then(response=>{
            if(response.success || response.status === true)
            {
                getTestinomial();
                toastComp(addToast, 'success', response.message);
            }else{
                setLoader(false);
                toastComp(addToast, 'success', response.message);
            }
        })
    }
    const onCloseModal=()=>{
        setOpen(false);
        setEdit(false);
        setEditId(false);
        setName('');
        setMessage('');
        setProfile('');
        setRate(0);
        setdesignation('');
    }
    const editControler=(data)=>{
        setOpen(true);
        setEdit(true);
        setEditId(data?._id);
        setName(data?.name?data?.name:'');
        setMessage(data?.message?data?.message:'');
        setProfile(data?.profile?data?.profile:'');
        setRate(data?.rate?data?.rate:0);
        setdesignation(data?.designation?data?.designation:'');
    }
    const handleAddTestinomials=async(e)=>{
        setLoader(true)
        e.preventDefault();
        const {name, message, profile, rate, designation} = e.target.elements;
        const formData = new FormData();
        formData.append("name", name.value);
        formData.append("message", message.value);
        formData.append("rate",rate.value);
        formData.append("designation", designation.value);
        if(profile.files[0])
        {
            formData.append('profile', profile.files[0]);
        }
        if(edit)
        {
           await putMethod('testnomail/'+editId, formData).then(response=>{
               if(response.success)
               {
                onCloseModal();
                document.getElementById('formTest').reset('');
                getTestinomial();
                toastComp(addToast, 'success', response.message);
               }else{
                setLoader(false);
                    toastComp(addToast, 'error', response.message);
               }
           });
        }else{
            await postMethod('testnomail',formData).then(response=>{
                if(response.success)
                {
                    onCloseModal();
                    document.getElementById('formTest').reset('');
                    getTestinomial();
                    toastComp(addToast, 'success', response.message);
                }else{
                    setLoader(false);
                    toastComp(addToast, 'error', response.message);
                }
            }); 
        }
    }
    const popup=(id, index)=>{
        confirmAlert({
            title: 'Confirm Action',
            message: 'Are you sure you want to delete?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => deleteAPI(id, index)
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });
    }
    useEffect(() => {
        getTestinomial();
    }, [])
    return (
        <>
            <div className="nk-content ">
                <div className="container-fluid">
                    <div className="nk-content-inner">
                        <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-between">
                                    <div className="nk-block-head-content">
                                        <h3 className="nk-block-title page-title">Testimonials</h3>
                                        <div className="nk-block-des text-soft">
                                            <p>You have total {total === 1 ? total + ' testimonial' : total + ' testimonials'}.</p>
                                        </div>
                                    </div>
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                        <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                        <div className="toggle-expand-content" data-content="pageMenu">
                                            <ul className="nk-block-tools g-3">
                                                <li><a className="btn btn-white btn-outline-light" onClick={(e) => { setOpen(true) }}><em className="icon ni ni-plus"></em><span>Add Testimonial</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="nk-block">
                                <div className="card card-bordered card-stretch">
                                    <div className="card-inner-group">
                                        {/* <div className="card-inner position-relative card-tools-toggle">
                                        <div className={toggleSearchBar ? 'card-search search-wrap active' : 'card-search search-wrap active'} data-search="search">
                                            <div className="card-body">
                                                <div className="search-content">
                                                    <input type="text" className="form-control border-transparent form-focus-none" value={search} onChange={(e) => handleSerach(e.target.value)} placeholder="Search by name..." style={{ paddingLeft: '0px' }} />
                                                    <button className="search-submit btn btn-icon"><em className="icon ni ni-search"></em></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                        <div className="card-inner p-0">
                                            <div className="nk-tb-list nk-tb-ulist">
                                                <div className="nk-tb-item nk-tb-head">
                                                    <div className="nk-tb-col nk-tb-col-check" style={{ width: '0px', padding: '10px' }}>
                                                    </div>
                                                    <div className="nk-tb-col"><span className="sub-text">Name</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Message</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Created At</span></div>
                                                    <div className="nk-tb-col nk-tb-col-tools text-right"><span className="sub-text">More</span></div>
                                                </div>
                                                {
                                                    loader ?
                                                        <LoadingOverlay className='loaderDiv'>
                                                            <Loader loading={loader} />
                                                        </LoadingOverlay>
                                                        : null
                                                }
                                                {
                                                    listVar[0]?.length > 0 ?
                                                        listVar[0]?.map((data, index) =>
                                                            <div className="nk-tb-item" key={index}>
                                                                <div className="nk-tb-col nk-tb-col-check" style={{ width: '0px', padding: '10px' }}>
                                                                    <div class="user-avatar bg-success-dim">
                                                                          <img className="img-dmns" src={BASE_IMAGE_URL + data?.profile} alt="profile" style={{width:'100%',height:'100%'}}/>
                                                                    </div>
                                                                </div>
                                                                <div className="nk-tb-col">
                                                                    <div className="user-info">
                                                                        <span className="tb-lead">{data?.name+', '+data?.designation}<span className="dot dot-success d-md-none ml-1"></span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data?.message}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{format(new Date((data?.createdAt)), 'Do MMM YYYY')}</span>
                                                                </div>
                                                                <div className="nk-tb-col nk-tb-col-tools">
                                                                    <ul className="nk-tb-actions gx-1">
                                                                        <li>
                                                                            <div className="drodown">
                                                                                <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                                    <ul className="link-list-opt no-bdr">
                                                                                        <li><a onClick={() => editControler(data)}><em className="icon ni ni-edit"></em><span>Edit</span></a></li>
                                                                                        <li><a onClick={() => popup(data?._id, index)}><em className="icon ni ni-trash"></em><span>Delete</span></a></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        )
                                                        :
                                                        <LoadingOverlay className='loaderDiv'>
                                                            <Loader loading={loader} />
                                                        </LoadingOverlay>
                                                }
                                            </div>
                                        </div>
                                        {/* {
                                            Math.round(total / 10) > 1 ?
                                                <div className="card-inner">
                                                    <ReactPaginate
                                                        previousLabel={"Prev"}
                                                        nextLabel={"Next"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={Math.round(total / 5)}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={async (e) => console.log('sdasdsd')}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"} />
                                                </div>
                                                : null
                                        } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} closeOnOverlayClick={false}>
            <div className='container-fluid' style={{ width: '100%' }}>
                <div className='row justify-content-center'>
                    <div className='col-md-12 col-12'>
                        <h3 style={{ textAlign: 'left' }}>{edit ? 'Edit Testimonial' : 'Add Testimonial'}</h3>
                    </div>
                    <div className='col-md-12 col-12' style={{ marginTop: "15px" }}>
                        <div className='row justify-content-center'>
                            <form className="col-lg-12 col-12" id='formTest' onSubmit={(e) => { handleAddTestinomials(e) }}>
                                <ul className="data-list is-compact">
                                    <li className="data-item">
                                        <div className="data-col">
                                            <div className="data-label">Name</div>
                                            <input
                                                type="text"
                                                className="data-value ip-field"
                                                value={name}
                                                id='name'
                                                name='name'
                                                placeholder="Name"
                                                onChange={(e) => { setName(e.target.value) }}
                                                required
                                            />
                                        </div>
                                    </li>
                                    <li className="data-item">
                                        <div className="data-col">
                                            <div className="data-label">Designation</div>
                                            <input
                                                type="text"
                                                className="data-value ip-field"
                                                value={designation}
                                                id='designation'
                                                name='designation'
                                                placeholder="Designation"
                                                onChange={(e) => { setdesignation(e.target.value) }}
                                                required
                                            />
                                        </div>
                                    </li>
                                    <li className="data-item">
                                        <div className="data-col">
                                            <div className="data-label">Message</div>
                                            <textarea
                                                className="data-value ip-field"
                                                value={message}
                                                id='message'
                                                name='message'
                                                placeholder="Message..."
                                                onChange={(e) => { setMessage(e.target.value) }}
                                                required
                                            >
                                            </textarea>
                                        </div>
                                    </li>
                                    <li className="data-item">
                                        <div className="data-col">
                                            <div className="data-label">Profile</div>
                                            {edit ?
                                                <div className="d-flex align-items-center">
                                                    <div className="data-value">
                                                        <img
                                                            src={BASE_IMAGE_URL + profile}
                                                            alt="img"
                                                            className="img-dmns"
                                                        />
                                                    </div>
                                                    <input
                                                        className="ml-3"
                                                        type="file"
                                                        placeholder="Choose..."
                                                        id="profile"
                                                        name='profile'
                                                    />
                                                </div>
                                                :
                                                <input
                                                    type="file"
                                                    className="data-value ip-field"
                                                    placeholder="Choose..."
                                                    name='profile'
                                                    id="profile"
                                                    required
                                                />
                                            }
                                        </div>
                                    </li>
                                    <li className="data-item">
                                        <div className="data-col">
                                            <div className="data-label">Rate</div>
                                            <input
                                                type="number"
                                                className="data-value ip-field"
                                                value={rate}
                                                id='rate'
                                                name='rate'
                                                placeholder="0"
                                                onChange={(e) => { setRate(e.target.value) }}
                                            />
                                        </div>
                                    </li>
                                </ul>
                                <div className="col-lg-12 col-12">
                                <div className="my-3 text-center">
                                    <div className="d-flex justify-content-center">
                                        <button
                                            type='submit'
                                            className="btn btn-md btn-primary"
                                        >
                                            {edit ? "Edit" : "Add"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        </>
    )
}
export default Testimonials;