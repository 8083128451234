
import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export const ExportToExcel = ({ apiData, fileName }) => {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    return (
        <button onClick={(e) => exportToCSV(apiData, fileName)} 
        style={{ //pass other props, like styles
            boxShadow: "inset 0px 1px 0px 0px #6576ff",
            background: "linear-gradient(to bottom, #6576ff 5%, #6576ff 100%)",
            backgroundColor: "#6576ff",
            borderRadius: "6px",
            border: "1px solid #6576ff",
            display: "inline-block",
            cursor: "pointer",
            "color": "#ffffff",
            fontSize: "15px",
            fontWeight: "bold",
            padding: "6px 24px",
            textDecoration: "none",
            textShadow: "0px 1px 0px #6576ff"
        }}
        ><em className="icon ni ni-download"> </em>Export</button>
      );
}