import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { logOut } from '../Action/Logout';
import { BASE_IMAGE_URL} from "../Utils/ApiServices";
import { userDetails } from '../Action/GetAction';
const TopBar = (props) => {
    const history = useHistory();
    const [loginAdminDetails, fetchAuth] = useState(false);
    useEffect(async () => {
        await userDetails().then(async response => {
            await fetchAuth({ user: response.data });
        })
    }, [])
    return (
        <div className="nk-header nk-header-fixed is-light">
            <div className="container-fluid">
                <div className="nk-header-wrap">
                    <div className="nk-menu-trigger d-xl-none ml-n1">
                        <a href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em className="icon ni ni-menu"></em></a>
                    </div>
                    <div className="nk-header-brand d-xl-none">
                        <Link to='/dashboard' className="logo-link">
                            <img className="logo-light logo-img" src={BASE_IMAGE_URL + 'webImages/Group+747.png'} alt="logo" />
                            <img className="logo-dark logo-img" src={BASE_IMAGE_URL + 'webImages/Group+747.png'} alt="logo-dark" />
                        </Link>
                    </div>
                    <div className="nk-header-tools">
                        <ul className="nk-quick-nav">
                            <li className="dropdown user-dropdown">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                    <div className="user-toggle">
                                        <div className="user-avatar sm">
                                            {
                                                loginAdminDetails?.user?.profilePicture ?
                                                    <img src={BASE_IMAGE_URL + loginAdminDetails.user.profilePicture} />
                                                    :
                                                    <em className="icon ni ni-user-alt"></em>
                                            }
                                        </div>
                                        <div className="user-info d-none d-md-block">
                                            <div className="user-status">{loginAdminDetails?.user ? loginAdminDetails.user.role == 'admin' ? 'Admin' : loginAdminDetails.user.role : ''}</div>
                                            <div className="user-name dropdown-indicator">{loginAdminDetails?.user ? loginAdminDetails.user.firstName + ' ' + loginAdminDetails.user.lastName : 'Admin'}</div>
                                        </div>
                                    </div>
                                </a>
                                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1">
                                    {
                                        loginAdminDetails?.user ?
                                            <>
                                                <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block" style={{paddingLeft:'5px'}}> 
                                                    <div className="user-card">
                                                        <div className="user-avatar">
                                                            <span>{loginAdminDetails?.user ? loginAdminDetails.user.firstName[0] + loginAdminDetails.user.lastName[0] : 'AA'}</span>
                                                        </div>
                                                        <div className="user-info">
                                                            <span className="lead-text">{loginAdminDetails?.user ? loginAdminDetails.user.firstName + ' ' + loginAdminDetails.user.lastName : 'Admin'}</span>
                                                            <span className="sub-text">{loginAdminDetails?.user ? loginAdminDetails.user.email : 'admin@example.com'}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dropdown-inner">
                                                    <ul className="link-list">
                                                        <li><a onClick={() => { logOut(); history.push('/') }} style={{cursor:'pointer'}}><em className="icon ni ni-signout"></em><span>Sign out</span></a></li>
                                                    </ul>
                                                </div>
                                            </>
                                            :
                                            null
                                    }
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TopBar;