import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import { GoPlus } from 'react-icons/go';
import { getOrderReceipts, userDetails } from '../Action/GetAction';
import { format } from 'fecha';
import { FaGlobeAmericas } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md';
import { LoadingOverlay, Loader } from 'react-overlay-loader';


const Receipts = () => {

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState({});
  const [paymentHistory, setPaymentHistory] = useState('');
  // console.log("data===>", data);

  const getOrderReceiptsDetails = async (id) => {
    await getOrderReceipts(id).then((response) => {
      if (response.data) {
        setLoader(false);
        setData(response.data);
        setPaymentHistory(response?.paymentHistoryCard ? response?.paymentHistoryCard : "")
      } else {
        setLoader(false);
      }
    })
  }

  useEffect(() => {
    getOrderReceiptsDetails(query.get('invoiceId'));
  }, [])

  return (

    <div className="nk-content">
      <div className="container-fluid ">
        <div className="nk-content-body">
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between g-3">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  View Order Receipts
                </h3>
              </div>
            </div>
          </div>
          {!loader ? (
            <div>
              <div className='bgcolor py-5 px-3 row '>
                <div className='col-md-4 col-sm-12 px-3 '>
                  <p className='Textsm'>From</p>
                  {/* <p className='Textb'>{data?.order?.sendersName}</p>
                  <p className='Textnr'>{data?.user?.email}</p> */}
                  <p className='Textb'>Greetpool</p>
                  <p className='Textnr'>hello@greetpool.com</p>
                  <p className='Textnr'>29 Pritchard Rd, Brampton , ONL7A0Z7</p>
                </div>
                <div className='col-md-4 col-sm-12 px-3 '>
                  <p className='Textsm'>To</p>
                  <p className='Textb'>{data?.user ? data?.user?.firstName + " " + data?.user?.lastName : ""}</p>
                  <p className='Textnr'>{data?.user ? data?.user?.email : ""}</p>
                  {

                    paymentHistory?.cardLastDigit ?

                      <p className='Textnr'><b>Card Number:</b> {paymentHistory?.cardLastDigit ? "**** **** **** " + paymentHistory?.cardLastDigit : ""}</p>

                      : null

                  }

                  {

                    paymentHistory?.cardLastDigit ?

                      <p className='Textnr'><b>Card Type:</b> {paymentHistory?.cardType ? paymentHistory?.cardType : ""}</p>

                      : null

                  }
                </div>
                <div className='col-md-4 col-sm-12 px-3 text-end'>
                  <p className='Textsm'>Invoce Number</p>
                  <p className='Textb'>{data?._id}</p>
                  <p className='Textb'>{data?.createdAt ? format(new Date((data?.createdAt)), 'Do MMM YYYY') : ""}</p>
                </div>
              </div>
              <div className='my-5 px-3 row'>
                <div className='col-sm-12 col-md-3 Textnr'>
                  <p>S. No.</p>
                  <p>1</p>
                </div>
                <div className='col-sm-12 col-md-3 Textnr'>
                  <p>Product Name</p>
                  <p>{data?.product?.productType?.name}</p>
                </div>
                <div className='col-sm-12 col-md-3 Textnr'>
                  <p>Payment Type</p>
                  <p>{data.paymentMethod}</p>
                </div>
                <div className='col-sm-12 col-md-3 Textnr'>
                  <p>Price</p>
                  <p>$ {data.amount}</p>
                </div>
              </div>
              <div className='bgcolor row'>
                <div className='col-sm-12 col-md-8 py-3'>
                  <div className='row px-2'>
                    <div className='col-sm-5 col-md-3'>
                      <p className='Textsm'>Sub Total</p>
                      <p className='Textb'>$ {data.amount}</p>
                    </div>
                    <div className='col-sm-2 col-md-2'>
                      <GoPlus></GoPlus>
                    </div>
                    <div className='col-sm-5 col-md-3'>
                      <p className='Textsm'>Tax(0%)</p>
                      <p className='Textb'>0</p>
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-4 bg-dark p-2'>
                  <p className='text-white'>Total</p>
                  <p className='text-white text-end fw-normal fs-4'> $ {data.amount + 0}</p>
                </div>
              </div>
              <div className='mt-5 border-bottom border-dark'></div>
              <div className='mt-5 text-center'>
                <p className='mb-0'>THANK YOU!</p>
                <p className='mb-0'><span className='fs-5'><FaGlobeAmericas></FaGlobeAmericas></span> www.greetpool.com  <span className='fs-5'><MdEmail></MdEmail></span> hello@greetpool.com</p>
              </div>
            </div>

          ) : (
            <LoadingOverlay className='loaderDiv'>
              <Loader loading={loader} />
            </LoadingOverlay>
          )}

        </div>
      </div>
    </div>

  )
}

export default Receipts;
