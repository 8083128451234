import React, { useEffect, useState } from 'react';
import AdminLogin from './AdminLogin';
import Terms from '../Components/Terms';
import PrivacySection from '../Components/PrivacySection';
import SideBar from "../Components/SideBar";
import { BASE_IMAGE_URL } from "../Utils/ApiServices";
import TopBar from '../Components/TopBar';
import Footer from '../Components/Footer';
import CategoryList from "./CategoryList";
import { getCookies } from "../Utils/Cookies";
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { setAuthorizationToken } from '../Utils/ApiServices';
import ProductList from './ProductList';
import TemplateList from './TemplateList';
import FreebiePackages from './FreebiePackages';
import Dashboard from './Dashboard';
import DemoCard from './DemoCard.js'
import PricingPlanList from './PricingPlanList';
import CouponList from './CouponList';
import UserList from './UserList';
import SupportList from './SupportList';
import AccountProfile from './AccountProfile';
import UserDetails from './UserDetails';
import ViewOrderDetails from './ViewOrderDetails';
import ShowCasePage from './ShowCasePage';
import Testimonials from './Testimonials';
import InviteUser from './InviteUser';
import OrderAll from './OrderAll';
import GroupCard from './GroupCard';
import Newsletter from './Newsletter';
import AdminEditCard2 from './AdminEditCard2';
import Receipts from './Receipts';
import PlanList from './PlanList';
import UserTeam from './Userteam';
import TeamDetails from './TeamDetail';
import Bulktabtemplate from './Bulktabtemplate.js';
import Bundlelist from './Bundlelist.js';
const AdminDashboard = (props) => {
    const history = useHistory();
    const [token, setToken] = useState(false);
    const PublicRoute = ({ children, component, ...rest }) => {
        let Component = component;
        let isAuth = getCookies('_gp_admin_token') ? getCookies('_gp_admin_token') : false;
        return (
            <Route
                {...rest}
                render={(props) =>
                    isAuth ? <Redirect to="/dashboard" /> : <Component {...props} />
                }
            />
        );
    }
    const XAdminRoutes = ({ children, component, ...rest }) => {
        let Component = component;
        let isAuth = getCookies('_gp_admin_token') ? getCookies('_gp_admin_token') : false;
        if (isAuth) {
            return (
                <Route
                    {...rest}
                    render={(props) =>
                        <Component {...props} />
                    }
                />
            );
        } else {
            return (
                <Route
                    {...rest}
                    render={(props) =>
                        <Redirect to="/" />
                    }
                />
            );
        }
    }

    useEffect(() => {
        setToken(getCookies('_gp_admin_token'));
        return () => {
        }
    }, [])
    return (
        <Switch>
            <PublicRoute path='/' exact={true} component={AdminLogin} />
            <Route path='/terms' exact={true} component={Terms} />
            <Route path='/privacy' exact={true} component={PrivacySection} />
            <div className="nk-body bg-lighter npc-general has-sidebar">
                <div className="nk-app-root">
                    <div className="nk-main">
                        {
                            setAuthorizationToken(token)
                        }
                        <SideBar />
                        <div className="nk-wrap">
                            <TopBar />
                            <XAdminRoutes path='/dashboard' exact={true} component={Dashboard} />
                            <XAdminRoutes path='/category-list' exact={true} component={CategoryList} />
                            <XAdminRoutes path='/product-list' exact={true} component={ProductList} />
                            <XAdminRoutes path='/template-list' exact={true} component={TemplateList} />
                            <XAdminRoutes path='/freebie-packages' exact={true} component={FreebiePackages} />
                            <XAdminRoutes path='/demo-card' exact={true} component={DemoCard} />
                            <XAdminRoutes path='/showcase-list' exact={true} component={ShowCasePage} />
                            <XAdminRoutes path='/pricing-list' exact={true} component={PricingPlanList} />
                            <XAdminRoutes path='/coupon-list' exact={true} component={CouponList} />
                            <XAdminRoutes path='/user-list' exact={true} component={UserList} />
                            <XAdminRoutes path='/user-team' exact={true} component={UserTeam} />
                            <XAdminRoutes path='/invite-user' exact={true} component={InviteUser} />
                            <XAdminRoutes path='/user-details' exact={true} component={UserDetails} />
                            <XAdminRoutes path='/team-details' exact={true} component={TeamDetails} />
                            <XAdminRoutes path='/plans' exact={true} component={PlanList} />
                            <XAdminRoutes path='/view-order' exact={true} component={ViewOrderDetails} />
                            <XAdminRoutes path='/receipts-preview' exact={true} component={Receipts} />
                            <XAdminRoutes path='/support-list' exact={true} component={SupportList} />
                            <XAdminRoutes path='/admin-account-setting' exact={true} component={AccountProfile} />
                            <XAdminRoutes path='/testimonials' exact={true} component={Testimonials} />
                            <XAdminRoutes path='/orders' exact={true} component={OrderAll} />
                            <XAdminRoutes path='/group-cards' exact={true} component={GroupCard} />
                            <XAdminRoutes path='/subscribers' exact={true} component={Newsletter} />
                            <XAdminRoutes path='/bulktab-template' exact={true} component={Bulktabtemplate} />
                            <XAdminRoutes path='/bundle-list' exact={true} component={Bundlelist} />
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </Switch>
    )
}
export default AdminDashboard;