import React, { useEffect, useState , useCallback} from "react";
import { getCategoryList} from "../Action/GetAction";
import { dateFormatConverter} from "../Logic/Logic";
import { useToasts } from 'react-toast-notifications';
import { toastComp } from "../Sections/Toast";
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { Modal } from "react-responsive-modal";
import { confirmAlert } from 'react-confirm-alert';
import {useDropzone} from 'react-dropzone';
import { format } from 'fecha';
import { BASE_IMAGE_URL, deleteMethod, getMethod, postMethod, putMethod } from "../Utils/ApiServices";
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-overlay-loader/styles.css';
import "react-responsive-modal/styles.css";


const FreebiePackages = (props) => {
    const { addToast } = useToasts();
    const [productList, setProductList] = useState([]);
    const [categorylist, setCategoryList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [open, setOpen] = useState(false);
    const [editId, setEditId] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [featured, setFeatureImages] = useState('');
    const [mockupImages, setMockupImages] = useState([]);
    const [prevBanner, setBannerImages] = useState(false);
    const onDrop = useCallback(acceptedFiles => {
        setMockupImages(acceptedFiles)
        // Do something with the files
      }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
    const getListData = async () => {
        await getMethod('package').then(async response => {
            if (response.success) {
                setLoader(false);
                setProductList(response.data)
            }
        })
    }
    const handleAddProduct=async(e)=>{
        if(title != '' && category != '' && featured != '' && mockupImages?.length && description != '')
        {
            setLoader(true)
            const formData = new FormData();
            formData.append('name', title);
            formData.append('categoryId', category);
            formData.append('bannerImage', featured); 
            console.log( mockupImages);
            for (var x = 0; x < mockupImages?.length; x++) {
                console.log( mockupImages[x]);
                formData.append('images', mockupImages[x]);
            }
            formData.append('description', description);
            await postMethod('package', formData).then(response=>{
                if(response.success)
                {
                    setTitle('')
                    setCategory('');
                    setFeatureImages('');
                    setMockupImages([]);
                    setDescription('');
                    setOpen(false);
                    setLoader(false)
                    getListData();
                    toastComp(addToast, 'success', response.message);
                }else{
                    setLoader(false)
                    toastComp(addToast, 'error', response.message);
                }
            })
        }else{
            toastComp(addToast, 'error', 'Please fill the required input field!');
        }
    }
    const handleEditProduct=async(e)=>{
        if(title != '' && category != '' && description != '')
        {
            setLoader(true)
            const formData = new FormData();
            formData.append('name', title);
            formData.append('categoryId', category);
            formData.append('description', description);

            if(featured != '')
            {
                formData.append('bannerImage', featured); 
            }
            if(mockupImages?.length)
            {
                for (var x = 0; x < mockupImages?.length; x++) {
                    formData.append('images', mockupImages[x]);
                }
            }
        
            await putMethod('package/'+editId, formData).then(response=>{
                if(response.success)
                {
                    setTitle('')
                    setCategory('');
                    setFeatureImages('');
                    setEditId(false);
                    setMockupImages([]);
                    setDescription('');
                    setBannerImages('')
                    setOpen(false);
                    setLoader(false)
                    getListData();
                    toastComp(addToast, 'success', response.message);
                }else{
                    setLoader(false)
                    toastComp(addToast, 'error', response.message);
                }
            })
        }else{
            toastComp(addToast, 'error', 'Please fill the required input field!');
        }
    }
    const onCloseModal = (props) => {
        setOpen(false);
        setEditId(false);
        setTitle
    }

    const editCategory = (data) => {
        setOpen(true);
        setEditId(data?._id);
        setTitle(data?.name?data?.name:'');
        setDescription(data?.description?data?.description:'');
        setCategory(data?.categoryId?data?.categoryId:'');
        setBannerImages(data?.bannerImage?data?.bannerImage:'');
    }
    const deleteCategory = async (id, index) => {
        setLoader(true);
        await deleteMethod('package/'+id).then(response => {
            if (response.success || response.status) {
                setLoader(false);
                productList.splice(index, 1);
                getListData();
                toastComp(addToast, 'success', 'Showcase Card deleted Successfully!');
            } else {
                setLoader(false);
                toastComp(addToast, 'error', 'Showcase Card id incorrect!');
            }
        })
    };
    const popup=(id, index)=>{
        confirmAlert({
            title: 'Confirm Action',
            message: 'Are you sure you want to delete this card?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => deleteCategory(id, index)
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });
    }
    useEffect(async () => {
        getListData();
        getCategoryList('category').then(response=>{
            if(response.success)
            {
                setCategoryList(response?.data?.categories);
            }
        });
    }, [])
    return (
        <>
            <div className="nk-content ">
                <div className="container-fluid">
                    <div className="nk-content-inner">
                        <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-between">
                                    <div className="nk-block-head-content">
                                        <h3 className="nk-block-title page-title">Packages</h3>
                                        <div className="nk-block-des text-soft">
                                            <p>You have total {productList?.length === 1 ? productList?.length + ' package' : productList?.length + ' packages'}.</p>
                                        </div>
                                    </div>
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                        <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                        <div className="toggle-expand-content" data-content="pageMenu">
                                            <ul className="nk-block-tools g-3">
                                                <li><a className="btn btn-white btn-outline-light" onClick={(e) => { setOpen(true) }}><em className="icon ni ni-plus"></em><span>Add Packages</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="nk-block">
                                <div className="card card-bordered card-stretch">
                                    <div className="card-inner-group">
                                        <div className="card-inner p-0">
                                            <div className="nk-tb-list nk-tb-ulist">
                                                <div className="nk-tb-item nk-tb-head">
                                                    <div className="nk-tb-col nk-tb-col-check" style={{ width: '0px', padding: '10px' }}>
                                                    </div>
                                                    <div className="nk-tb-col"><span className="sub-text">Featured Images</span></div>
                                                    <div className="nk-tb-col tb-col-mb"><span className="sub-text">Name</span></div>
                                                    <div className="nk-tb-col tb-col-mb"><span className="sub-text">Description</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Created At</span></div>
                                                    <div className="nk-tb-col nk-tb-col-tools text-right"><span className="sub-text">More</span></div>
                                                </div>
                                                {
                                                    loader ?
                                                        <LoadingOverlay className='loaderDiv'>
                                                            <Loader loading={loader} />
                                                        </LoadingOverlay>
                                                        : null
                                                }
                                                {
                                                    productList?.length > 0 ?
                                                        productList?.map((data, index) =>
                                                            <div className="nk-tb-item" id={data._id} key={index}>
                                                                <div className="nk-tb-col nk-tb-col-check" style={{ width: '0px', padding: '10px' }}>
                                                                </div>
                                                                <div className="nk-tb-col">
                                                                    <div className="user-info">
                                                                        <img src={BASE_IMAGE_URL+data?.bannerImage} className='img-fluid' style={{width:'50px',height:'70px'}}/>
                                                                    </div>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-mb">
                                                                    <span className="tb-lead">{data?.name}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-mb">
                                                                    <span className="tb-lead">{data?.description ? data?.description : '---'}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{format(new Date((data?.createdAt)), 'Do MMM YYYY')}</span>
                                                                </div>
                                                                <div className="nk-tb-col nk-tb-col-tools">
                                                                    <ul className="nk-tb-actions gx-1">
                                                                        <li>
                                                                            <div className="drodown">
                                                                                <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                                    <ul className="link-list-opt no-bdr">
                                                                                        <li><a onClick={() => editCategory(data)}><em className="icon ni ni-edit"></em><span>Edit Packages</span></a></li>
                                                                                        <li><a onClick={() => popup(data._id, index)}><em className="icon ni ni-trash"></em><span>Delete</span></a></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        )
                                                        :
                                                        <div style={{ width: '100%', position: 'absolute', left: '0px', backgroundColor: '#fff' }}>
                                                            <h1 style={{ width: '100%', textAlign: 'center', fontSize: '16px', lineHeight: '61px', color: '#eb5757', fontWeight: '600' }}>*Not Found</h1>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} closeOnOverlayClick={false}>
                <div className='container-fluid' style={{ width: '100%' }}>
                    <div className='row justify-content-center'>
                        <div className='col-md-12 col-12'>
                            <h3 style={{ textAlign: 'left' }}>{editId?'Edit Packages':'Add Packages'}</h3>
                        </div>
                        <div className='col-md-12 col-12' style={{ marginTop: "15px" }}>
                            <div className='row justify-content-center'>
                                <div className="col-lg-12 col-12">
                                    <ul className="data-list is-compact">
                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Name*</div>
                                                <input
                                                    type="text"
                                                    className="data-value ip-field"
                                                    value={title}
                                                    name="title"
                                                    placeholder="Name"
                                                    onChange={(e) => {
                                                        setTitle(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </li>
                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Description</div>
                                                <textarea
                                                    type="text"
                                                    className="data-value ip-field"
                                                    value={description}
                                                    name="title"
                                                    placeholder="Description..."
                                                    onChange={(e) => {
                                                        setDescription(e.target.value)
                                                    }}
                                                ></textarea>
                                            </div>
                                        </li>
                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Category*</div>
                                                <select name='category' id='category' value={category} onChange={(e)=>setCategory(e.target.value)}>
                                                    <option value=''>Select</option>
                                                    {
                                                        categorylist?.map((items, index)=>
                                                        <>
                                                          <option value={items?._id} key={index+1}>{items?.name}</option>
                                                          {
                                                              items?.children?.map((sitems, sIndex)=>
                                                              <option value={sitems?._id} key={sIndex+1}>{sitems?.name}</option>
                                                              )
                                                          }
                                                        </>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </li>
                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Featured Images</div>
                                                {editId ?
                                                    <div className="d-flex align-items-center">
                                                        <div className="data-value">
                                                            <img
                                                                src={BASE_IMAGE_URL + prevBanner}
                                                                alt="img"
                                                                className="img-dmns"
                                                            />
                                                        </div>
                                                        <input
                                                            className="ml-3"
                                                            type="file"
                                                            id="front"
                                                            name='front'
                                                            onChange={(e) => setFeatureImages(e.target.files[0])}
                                                        />
                                                    </div>
                                                    : 
                                                    <input
                                                        type="file"
                                                        className="data-value ip-field"
                                                        placeholder="Choose..."
                                                        name='front'
                                                        onChange={(e) => { setFeatureImages(e.target.files[0]) }}
                                                        required
                                                    />
                                                }

                                            </div>
                                        </li>
                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Mockup's Images</div>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    {
                                                        isDragActive ?
                                                            <p>Drop the files here ...</p> 
                                                        :
                                                            <p>Drag 'n' drop some files here, or click to select files</p>
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <div className="my-3 text-center">
                                        <div className="d-flex justify-content-center">
                                            <button
                                                className="btn btn-md btn-primary"
                                                onClick={(e) => { editId ? handleEditProduct(e): handleAddProduct(e) }}
                                            >
                                               {editId ? 'Edit Package' : 'Add Package'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default FreebiePackages;