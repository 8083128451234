import React, { useEffect, useState } from "react";
import { BASE_IMAGE_URL } from '../Utils/ApiServices';
import { userDetails } from '../Action/GetAction';
import { postUpdateProfileDetails, verifyAdminEmail } from '../Action/PostAction';
import {adminUpdatePassword} from '../Action/PutAction';
import { useToasts } from 'react-toast-notifications';
import { toastComp } from '../Sections/Toast';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { Modal } from "react-responsive-modal";
import 'react-overlay-loader/styles.css';
import "react-responsive-modal/styles.css";
import { setCookies } from "../Utils/Cookies";
const AccountProfile = (props) => {
    const { addToast } = useToasts();
    const [loginAdminDetails, fetchAuth] = useState(false);
    const [profilePic, setProfilePic] = useState('');
    const [updateEmailId, setNewEmailId] = useState(false)
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dob, setDOB] = useState('');
    const [email, setEmailId] = useState('');
    const [loader, setLoader] = useState(true);
    const [open, setOpen] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const getAdminDetails = async () => {
        await userDetails('user/profile').then(async response => {
            if (response.success) {
                setLoader(false);
                await fetchAuth(response.data.email)
                await setProfilePic(response.data.profilePicture)
                await setFirstName(response.data.firstName);
                await setLastName(response.data.lastName);
                await setDOB(response.data.dob);
                await setEmailId(response.data.email);
            }
        })
    }
    const onCloseModal = (props) => {
        setOpen(false);
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
    }
    const updateEmailIdGenerateVerificationLink = async (newEmailId) => {
        setLoader(true);
        const data = { email: newEmailId }
        await verifyAdminEmail(data).then(async (response) => {
            if (response.success) {
                setLoader(false);
                setNewEmailId(false)
                setEmailId(loginAdminDetails);
                toastComp(addToast, 'success', 'Please check your new email for a verification link!');
            } else {
                setLoader(false);
                toastComp(addToast, 'error', response.message);
            }
        })
    }
    const handleEditProfile = async (e) => {
        e.preventDefault();
        setLoader(true);
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (pattern.test(email) == true) {
            if (email === loginAdminDetails) {
                var formData = new FormData();
                formData.append('firstName', firstName);
                formData.append('lastName', lastName);
                formData.append('dob', dob);
                await postUpdateProfileDetails(formData).then(async (response) => {
                    if (response.success) {
                        setProfilePic(response.user.profilePicture);
                        await getAdminDetails();
                        toastComp(addToast, 'success', 'Successfully updated account deatils!');
                    } else {
                        setLoader(false);
                        toastComp(addToast, 'error', 'Failed!');
                    }
                })
            } else {
                setLoader(false);
                setNewEmailId(email);
            }
        } else {
            setLoader(false);
            toastComp(addToast, 'error', 'Please fill correct emailId!');
        }
    }
    const updateProfilePic = async (formData) => {
        setLoader(true);
        await postUpdateProfileDetails(formData).then(async (response) => {
            if (response.success) {
                setProfilePic(response.user.profilePicture);
                await getAdminDetails();
                toastComp(addToast, 'success', 'Successfully updated profile picture!');
            } else {
                setLoader(false);
                toastComp(addToast, 'error', 'Failed!');
            }
        })
    }
    const handleChangePassword = async (e) => {
        setLoader(true);
        if (oldPassword != '' && newPassword != '' && confirmPassword != '') {
            if (oldPassword != confirmPassword) {
                if (newPassword == confirmPassword) {
                    const data = {
                            "email": loginAdminDetails.user.email,
                            "oldPassword": oldPassword,
                            "newPassword": confirmPassword
                    };
                    const params={};
                    await adminUpdatePassword(data, params).then(async (response) => {
                    if(response.status)
                    {
                        onCloseModal();
                        setLoader(false);
                        toastComp(addToast, 'success', response.message);
                    }else{
                        setLoader(false);
                        toastComp(addToast, 'error', response.message);
                    }
                    })
                } else {
                    setLoader(false);
                    toastComp(addToast, 'error', 'Your new password & confrim password is not match!');
                }
            } else {
                setLoader(false);
                toastComp(addToast, 'error', 'Your old password & confirm password is match!');
            }
        } else {
            setLoader(false);
            toastComp(addToast, 'error', 'Please fill the all details!');
        }
    }
    useEffect(async () => {
        await getAdminDetails();
    }, [])
    return (
        <>
            <div className="nk-content">
                <div className="container-fluid pt-3 px-5">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between g-3">
                                <div className="nk-block-head-content">
                                    <h3 className="nk-block-title page-title">Admin Account Details</h3>
                                </div>
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                    <div className="toggle-expand-content" data-content="pageMenu">
                                        <ul className="nk-block-tools g-3">
                                            <li><a className="btn btn-white btn-outline-light" onClick={(e) => { setOpen(true) }}><em className="icon ni ni-edit"></em><span>Change Password</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nk-block">
                            <div className="row gy-5">
                                <div className="col-lg-7">
                                    <div className="card card-bordered">
                                        {
                                            loader ?
                                                <LoadingOverlay className='loaderDiv'>
                                                    <Loader loading={loader} />
                                                </LoadingOverlay>
                                                : null
                                        }
                                        <ul className="data-list is-compact">
                                            <li className="data-item">
                                                <div className="data-col" style={{ textAlign: 'center', justifyContent: 'center' }}>
                                                    <div className='profileDiv' style={{ border: 'solid whitesmoke 1px', width: '100px', height: '100px', borderRadius: '100%' }}>
                                                        {
                                                            profilePic ?
                                                                <div style={{ width: '100px', height: '100px', borderRadius: '100%', backgroundColor: 'whitesmoke' }}>
                                                                    <img src={BASE_IMAGE_URL + profilePic} style={{ borderRadius: '100%', width: '100%', height: '100%' }} />
                                                                </div>
                                                                :
                                                                <div style={{ width: '100px', height: '100px', borderRadius: '100%', backgroundColor: 'whitesmoke' }}><span style={{ fontSize: '56px', fontWeight: '600', backgroundColor: 'transparent', fontFamily: 'Nunito, sans-serif' }}>{loginAdminDetails?.user?.firstName[0] + loginAdminDetails?.user?.lastName[0]}</span></div>
                                                        }
                                                        <div className="dash-set-camera" style={{ transform: 'translate(70px,-30px)', backgroundColor: 'white', border: 'solid 1px #81dbcd', width: '25px', height: '25px', borderRadius: '100%' }}>
                                                            <label htmlFor='profilepic1' style={{ width: '25px', height: '25px', borderRadius: '100%' }}>
                                                                <em className="icon ni ni-pen" style={{ cursor: 'pointer', fontSize: '16px', lineHeight: '25px', color: '#81dbcd' }} title='edit profile picture'></em>
                                                                <input
                                                                    id="profilepic1"
                                                                    style={{
                                                                        visibility: 'hidden',
                                                                        backgroundColor: 'transparent',
                                                                        width: '25px',
                                                                        height: '25px',
                                                                        cursor: 'pointer'

                                                                    }}
                                                                    type="file"
                                                                    name="profilePic"
                                                                    onChange={(event) => {
                                                                        var file = event.target.files[0];
                                                                        var formData = new FormData();
                                                                        formData.append('profilepic', file, file.name);
                                                                        updateProfilePic(formData)
                                                                    }}
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">First Name</div>
                                                    <input
                                                        type="text"
                                                        className="data-value ip-field"
                                                        value={firstName}
                                                        name="firstName"
                                                        onChange={(e) => setFirstName(e.target.value)}
                                                        placeholder="First Name"
                                                    />
                                                </div>
                                            </li>
                                            <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">Last Name</div>
                                                    <input
                                                        type="text"
                                                        className="data-value ip-field"
                                                        value={lastName}
                                                        name="lasttName"
                                                        placeholder="Last Name"
                                                        onChange={(e) => setLastName(e.target.value)}
                                                    />
                                                </div>
                                            </li>
                                            <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">D.O.B</div>
                                                    <input
                                                        type="date"
                                                        className="data-value ip-field"
                                                        value={dob}
                                                        name="dob"
                                                        placeholder="D.O.B"
                                                        onChange={(e) => setDOB(e.target.value)}
                                                    />
                                                </div>
                                            </li>
                                            <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">Email Id</div>
                                                    <input
                                                        type="email"
                                                        className="data-value ip-field"
                                                        value={email}
                                                        name="email"
                                                        placeholder="Email Id"
                                                        onChange={(e) => setEmailId(e.target.value)}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-3">
                            <div className="d-flex justify-content-start">
                                <button
                                    className="btn btn-md btn-primary"
                                    onClick={(e) => handleEditProfile(e)}
                                >
                                    Save
                                </button>
                                {
                                    updateEmailId ?
                                        <button
                                            className="btn btn-md btn-primary"
                                            style={{ marginLeft: '20px' }}
                                            onClick={(e) => updateEmailIdGenerateVerificationLink(updateEmailId)}
                                        >
                                            Send Verification Link To Update New EmailId
                                        </button>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} closeOnOverlayClick={false}>
                <div className='container-fluid' style={{ width: '100%' }}>
                    <div className='row justify-content-center'>
                        <div className='col-md-12 col-12'>
                            <h3 style={{ textAlign: 'left' }}>Change Password</h3>
                        </div>
                        <div className='col-md-12 col-12' style={{ marginTop: "15px" }}>
                            <div className='row justify-content-center'>
                                <div className="col-lg-12 col-12">
                                    <div className="card card-bordered">
                                        <ul className="data-list is-compact">
                                            <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">Old Password</div>
                                                    <input
                                                        type="password"
                                                        className="data-value ip-field"
                                                        value={oldPassword}
                                                        name="oldpassword"
                                                        placeholder="Old Password"
                                                        onChange={(e) => setOldPassword(e.target.value)}
                                                    />
                                                </div>
                                            </li>
                                            <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">New Password</div>
                                                    <input
                                                        type="password"
                                                        className="data-value ip-field"
                                                        value={newPassword}
                                                        name="newpassword"
                                                        placeholder="New Password"
                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                    />
                                                </div>
                                            </li>
                                            <li className="data-item">
                                                <div className="data-col">
                                                    <div className="data-label">Confirm Password</div>
                                                    <input
                                                        type="password"
                                                        className="data-value ip-field"
                                                        value={confirmPassword}
                                                        name="confrimPassword"
                                                        placeholder="Confirm Password"
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <div className="my-3 text-center">
                                        <div className="d-flex justify-content-center">
                                            <button
                                                className="btn btn-md btn-primary"
                                                onClick={(e) => handleChangePassword(e)}
                                            >
                                                Change Password
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default AccountProfile;