import React from "react";
import { ToastProvider } from 'react-toast-notifications';
import AdminDashboard from './AdminPanel/Pages/AdminDashboard';

function App() {
  return (
    <ToastProvider autoDismiss autoDismissTimeout={3000} placement="top-center">
      <AdminDashboard />
    </ToastProvider>
  );
}

export default App;
